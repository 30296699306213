export default [
  {
    path: '/User/List',
    name: '/User/List',
    component: () => import('@/views/User/List.vue'),
    meta: {
      title: 'Users',
      pageTitle: 'User Management',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
      needRegister: true,
      action: '/User/List'
    },
  },
  {
    path: '/User/Edit/:id',
    name: '/User/Detail',
    component: () => import('@/views/User/Edit.vue'),
    meta: {
      title: 'User Edit',
      pageTitle: 'User Management',
      breadcrumb: [
        {
          text: 'User Edit',
          active: true,
        },
      ],
      needRegister: true,
      action: '/User/Detail'
    },
  },
  {
    path: '/User/Create',
    name: '/User/Create',
    component: () => import('@/views/User/Create.vue'),
    meta: {
      title: 'New User',
      pageTitle: 'User Management',
      breadcrumb: [
        {
          text: 'New User',
          active: true,
        },
      ],
      needRegister: true,
      action: '/User/Create'
    },
  },
  {
    path: '/Profile',
    name: '/Profile',
    component: () => import('@/views/Profile/Edit.vue'),
    meta: {
      title: 'Profile',
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Profile',
          active: true,
        },
      ],
      needRegister: true,
    },
  },
]