export default [
  {
    path: '/Company/List',
    name: '/Company/List',
    component: () => import('@/views/Company/List.vue'),
    meta: {
      title: 'Companies',
      pageTitle: 'Company Management',
      breadcrumb: [
        {
          text: 'Companies',
          active: true,
        },

      ],
      needRegister: true,
      action: '/Company/List'
    },
  },
  {
    path: '/Company/Display/:id',
    name: '/Company/Display',
    component: () => import('@/views/Company/Display/Index.vue'),
    meta: {
      title: 'Company Display',
      pageTitle: 'Company Management',
      breadcrumb: [
        {
          text: 'Company Display',
          active: true,
        },

      ],
      needRegister: true,
      action: '/Company/Detail'
    },
  },
  {
    path: '/Company/Create',
    name: '/Company/Create',
    component: () => import('@/views/Company/Create.vue'),
    meta: {
      title: 'New Company',
      pageTitle: 'Company Management',
      breadcrumb: [
        {
          text: 'New Company',
          active: true,
        },

      ],
      needRegister: true,
      action: '/Company/Create'
    },
  },
  {
    path: '/Company/Edit/:id',
    name: '/Company/Edit',
    component: () => import('@/views/Company/Edit.vue'),
    meta: {
      title: 'EditCompany',
      pageTitle: 'Company Management',
      breadcrumb: [
        {
          text:'Companies',
          to:{
            name:'/Company/List'
          }
        },
        {
          text: 'EditCompany',
          active: true,
        },
      ],
      needRegister: true,
      action: '/Company/Edit'
    },
  },
  {
    path: '/Company/Transaction',
    name: '/Company/Transaction',
    component: () => import('@/views/Company/Transaction.vue'),
    meta: {
      title: 'Company Transaction',
      pageTitle: 'Company Management',
      breadcrumb: [
        {
          text: 'Company Transaction',
          active: true
        }
      ],
      needRegister: true,
      action: '/Company/Transaction'
    }
  },
  {
    path: '/Company/:id/Create/User',
    name: '/Company/CreateUser',
    component: () => import('@/views/Company/User/Create.vue'),
    meta: {
      title: 'New User',
      pageTitle: 'Company Management',
      breadcrumb: [
        {
          text: 'New User',
          active: true
        }
      ],
      needRegister: true,
      action: '/Company/CreateUser'
    }
  }
]