// import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'

import axiosIns from '@/libs/axios'

import { replaceSQLVariable } from '@/views/Report/components/js/replaceSQLVariable'
import { setReportGroup } from '@/views/Report/components/js/setReportGroup'
import { SET_NOTIFICATION } from './module-service'

moment().locale()

export const GET_ALL_REPORTS = 'getAllReports'
export const GET_MY_REPORTS = 'getMyReports'
export const GET_REPORT_GROUPS = 'getReportGroups'
export const RUN_SQL = 'runSQL'
export const GET_REPORT_FILTERS = 'getReportFilters'
export const NEW_REPORT_GROUP = 'newReportGroup'
export const GET_REPORT_WITH_DATA = 'getReportWithData'

export const SET_REPORTS = 'setReports'
export const SET_REPORT_FILTERS = 'setReportFilters'
export const SET_REPORT_GROUPS = 'setReportGroups'

const state = {}

const getters = {}

const actions = {
  [GET_ALL_REPORTS]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('Report/GetAllReport')
        .then(response => {
          if (response.data.status) {
            //commit(SET_NOTIFICATION,response.data)
            commit(SET_REPORTS, response.data.data)
            resolve(response.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_MY_REPORTS]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('Report/GetAllUserReport')
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          commit(SET_REPORTS, response.data.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_REPORT_GROUPS]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('Report/GetAllReportGroups')
        .then(response => {
          if (response.data.status) {
            commit(SET_REPORT_GROUPS, response.data)
            resolve(response.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [RUN_SQL]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Report/GetReport?sql=${payload.sql}&database__id=${payload.database_id}`)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          if (response.data.status) {
            resolve(response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_REPORT_FILTERS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(`Report/GetReportFilters?report_m_id=${payload}`)
        .then(response => {
          if (response.data.status) {
            commit(SET_REPORT_FILTERS, response.data)
            resolve(response.data.tempFilters)
          } else {
            commit(SET_NOTIFICATION, response.data)
            reject({ status: response.data.status, message: response.data.message })
          }
        })
        .catch(err => {
          commit(SET_NOTIFICATION)
          reject()
        })
    })
  },
  [NEW_REPORT_GROUP]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post('Report/SaveReportGroup', payload)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_REPORT_WITH_DATA]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(`Report/GetByIdFilterReport?report_m_id=${payload.report_m_id}`, payload.filters)
        .then(response => {
          if (response.data.status) {
            resolve(response.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
            reject({ status: response.data.status, message: response.data.message })
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  }
}

const mutations = {
  [SET_REPORTS](state, response) {
    response.forEach(item => {
      item.create_date_timestamp = moment(item.create_date).format('X')
      item.create_date_convert = new Date(item.create_date).toLocaleDateString()
      item.update_date_timestamp = moment(item.update_date).format('X')
      item.update_date_convert = new Date(item.update_date).toLocaleDateString()
    })
  },
  [SET_REPORT_GROUPS](state, response) {
    response.set_report_groups = setReportGroup(response.data)
  },
  [SET_REPORT_FILTERS](state, payload) {
    let tempFilter = []
    if (payload.filter_json?.filters) {
      let temp = payload.filter_json.filters
      temp.forEach(item => {
        tempFilter.push({
          text: `{${item.SQLVariable}}`,
          value: replaceSQLVariable(item)
        })
      })
    }
    payload.tempFilters = tempFilter
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
