export default {
  // Endpoints
  loginEndpoint: '/Login/CreateToken',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/Login/RefreshToken',
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'bilgeCodeAccessToken',
  storageRefreshTokenKeyName: 'bilgeCodeRefreshToken',
}
