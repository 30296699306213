import axiosIns from '@/libs/axios'
import { SET_NOTIFICATION } from './module-service'

//#region ACTIONS
export const ERP_GET_COMPANY_ADDRESS_LIST = 'ERPGetCompanyAddressList'
export const ERP_GET_COMPANY_ADDRESS_LIST_BY_COMPANY_ID = 'ERPGetCompanyAddressListByCompanyID'
export const ERP_GET_COUNTRY_LIST = 'ERPGetCountryList'
export const ERP_GET_CITY_LIST = 'ERPGetCityList'
export const ERP_GET_TOWN_LIST = 'ERPGetTownList'
//#endregion

//#region MUTATIONS
export const SET_ERP_ADDRESS_LIST = 'setERPAddressList'
//#endregion

const state = {
  invoiceAddressList: [],
  shippingAddressList: []
}

const getters = {
  invoiceAddressList: state => state.invoiceAddressList,
  shippingAddressList: state => state.shippingAddressList
}

const actions = {
  [ERP_GET_COMPANY_ADDRESS_LIST]({ commit }) {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(`Erp/GetAllCompanyAddressErp`)
        .then(res => {
          if (res.data.status) {
            commit(SET_ERP_ADDRESS_LIST, res.data.data)
            resolve(res.data.data)
          } else {
            commit(SET_NOTIFICATION, { title: 'Adres Getirme', message: 'Firmanıza Kayıtlı Adres Bulunamadı!' })
            commit(SET_ERP_ADDRESS_LIST, [])
            reject(false)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
          reject(false)
        })
    })
  },
  [ERP_GET_COMPANY_ADDRESS_LIST_BY_COMPANY_ID]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(`Erp/GetByIdAllCompanyAddressErp?company_id=${payload}`)
        .then(res => {
          if (res.data.status) {
            commit(SET_ERP_ADDRESS_LIST, res.data.data)
            resolve(res.data.data)
          } else {
            commit(SET_NOTIFICATION, { title: 'Adres Getirme', message: 'Firmaya Kayıtlı Adres Bulunamadı!' })
            commit(SET_ERP_ADDRESS_LIST, [])
            reject(false)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
          reject(false)
        })
    })
  },
  [ERP_GET_COUNTRY_LIST]({ commit }) {},
  [ERP_GET_CITY_LIST]({ commit }) {},
  [ERP_GET_TOWN_LIST]({ commit }) {}
}

const mutations = {
  [SET_ERP_ADDRESS_LIST](state, payload) {
    state.invoiceAddressList = payload?.filter(x => x.ADDRESS_TYPE_ID === 121) ?? []
    state.shippingAddressList = payload?.filter(x => x.ADDRESS_TYPE_ID !== 121) ?? []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
