import axiosIns from '@/libs/axios'

import moment from 'moment'
import { SET_NOTIFICATION } from './module-service'
moment().locale()

export const GET_ALL_DASHBOARD = 'getAllDashboard'
export const GET_MY_DASHBOARD = 'getMyDashboard'
export const GET_DASHBOARD = 'getDashboard'

export const SET_ALL_DASHBOARD = 'setAllDashboard'
export const SET_MY_DASHBOARD = 'setMyDashboard'

const state = {}

const getters = {}

const actions = {
  [GET_ALL_DASHBOARD]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('Dashboard/GetAllDashboard')
        .then(response => {
          if (response.data.status) {
            //commit(SET_NOTIFICATION,response.data)
            commit(SET_ALL_DASHBOARD, response.data.data)
            resolve(response.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_MY_DASHBOARD]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('Dashboard/GetAllUserDashboard')
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          commit(SET_MY_DASHBOARD, response.data.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_DASHBOARD]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post('Dashboard/GetUserDashboard?dashboard_m_id=' + payload.id)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  }
}

const mutations = {
  [SET_ALL_DASHBOARD](state, response) {
    response.forEach(item => {
      item.create_date_timestamp = moment(item.create_date).format('X')
      item.create_date_convert = new Date(item.create_date).toLocaleDateString()
      item.update_date_timestamp = moment(item.update_date).format('X')
      item.update_date_convert = new Date(item.update_date).toLocaleDateString()
    })
  },
  [SET_MY_DASHBOARD](state, response) {
    response.forEach(item => {
      item.create_date_timestamp = moment(item.create_date).format('X')
      item.create_date_convert = new Date(item.create_date).toLocaleDateString()
      item.update_date_timestamp = moment(item.update_date).format('X')
      item.update_date_convert = new Date(item.update_date).toLocaleDateString()
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
