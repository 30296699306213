import axiosIns from '@/libs/axios'

//Actions
export const GET_LAST_TRANSACTIONS = 'getLastTransactions'
export const GET_LAST_ORDERS = 'getLastOrders'

//Mutations

const state = {}

const getters = {}

const actions = {
  [GET_LAST_TRANSACTIONS]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Dashboard/GetTransaction?count=${payload}`)
        .then(response => {
          if (response.data.status) {
            resolve(response.data.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_LAST_ORDERS]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Dashboard/GetOrder?count=${payload}`)
        .then(response => {
          if (response.data.status) {
            resolve(response.data.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
