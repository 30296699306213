import axiosIns from '@/libs/axios'
import ability from '@/libs/acl/ability'
import i18n from '@/libs/i18n'

export const GET_ALL_METHODS = 'getAllMethods'
export const CHANGE_LOCALE = 'changeLocale'

export const SET_DASHBOARDS = 'setDashboards'
export const SET_PERMS = 'setPerms'
export const SET_MENU_ITEMS = 'setMenuItems'
export const SET_ALL_METHODS = 'setAllMethods'
export const SET_NOTIFICATION = 'setNotification'
export const UPDATE_LOCALE = 'updateLocale'
export const SET_PERMS_READY = 'setPermsReady'

const state = {
  notification: {},
  dashboards: {},
  mobileDashboards: {},
  menuItems: [],
  menuItemsMobile: [],
  perms: [],
  locale: 'tr-TR',
  permsReady: false,
  defaultPerms: [
    {
      method_id: 21
    },
    {
      method_id: 51
    },
    {
      method_id: 93
    },
    {
      method_id: 94
    },
    {
      method_id: 95
    },
    {
      method_id: 96
    },
    {
      method_id: 97
    },
    {
      method_id: 98
    },
    {
      method_id: 99
    },
    {
      method_id: 100
    },
    {
      method_id: 113
    },
    {
      method_id: 128
    },
    {
      method_id: 129
    },
    {
      method_id: 41
    },
    {
      method_id: 48
    },
    {
      method_id: 40
    },
    {
      method_id: 43
    },
    {
      method_id: 44
    },
    {
      method_id: 45
    },
    {
      method_id: 49
    },
    {
      method_id: 89
    },
    {
      method_id: 112
    },
    {
      method_id: 42
    },
    {
      method_id: 131
    },
    {
      method_id: 114
    },
    {
      method_id: 115
    },
    {
      method_id: 82
    },
    {
      method_id: 83
    },
    {
      method_id: 84
    },
    {
      method_id: 130
    },
    {
      method_id: 132
    },
    {
      method_id: 37
    },
    {
      method_id: 38
    },
    {
      method_id: 29
    },
    {
      method_id: 67
    },
    {
      method_id: 68
    },
    {
      method_id: 69
    },
    {
      method_id: 30
    },
    {
      method_id: 34
    },
    {
      method_id: 76
    },
    {
      method_id: 81
    },
    {
      method_id: 105
    },
    {
      method_id: 104
    },
    {
      method_id: 85
    },
    {
      method_id: 86
    },
    {
      method_id: 87
    },
    {
      method_id: 88
    },
    {
      method_id: 101
    },
    {
      method_id: 102
    },
    {
      method_id: 103
    },
    {
      method_id: 124
    },
    {
      method_id: 90
    },
    {
      method_id: 91
    },
    {
      method_id: 92
    },
    {
      method_id: 78
    },
    {
      method_id: 79
    }
  ]
}

const getters = {
  getMenuItems: state => {
    if (state.dashboards?.children?.length) {
      var items = [state.dashboards, ...state.menuItems]
      return items
    } else {
      return state.menuItems
    }
  },
  getMenuItemsMobile: state => {
    if (state.dashboards?.children?.length) {
      var items = [state.mobileDashboards, ...state.menuItemsMobile]
      return items
    } else {
      return state.menuItemsMobile
    }
  },
  getUserDashboards: state => state.dashboards,
  getUserDashboardsMobile: state => state.mobileDashboards,
  getUserPerms: state => state.perms,
  getLocale: state => state.locale,
  getNotification: state => state.notification,
  permsReady: state => state.permsReady,
  defaultPerms: state => state.defaultPerms
}

const actions = {
  [GET_ALL_METHODS]({ commit, state }) {
    return new Promise(resolve => {
      axiosIns
        .post('Method/GetAllMethods')
        .then(response => {
          if (response.data.status) {
            commit(SET_ALL_METHODS, response.data.data)
            resolve(response.data.data.modules)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [CHANGE_LOCALE]({ commit }, payload) {
    commit(UPDATE_LOCALE, payload)
  }
}

const mutations = {
  [SET_NOTIFICATION](state, payload = {}) {
    let notification = {
      show: payload?.show ?? true,
      type: payload?.type ?? 2, //1 => Notification - 2 => Swal
      title: payload?.title ?? i18n?.t('notifications.connection_problem_title'),
      text: payload?.message ?? i18n?.t('notifications.connection_problem_text'),
      html: payload?.html ?? undefined,
      icon: payload?.icon ?? 'warning', // type = 1 ise variant kullan
      variant: payload?.variant ?? undefined
    }

    if ('status' in payload && payload?.type === undefined) {
      if (payload.status) {
        notification.type = 1
        notification.variant = 'success'
        notification.icon = 'CheckCircleIcon'
      } else {
        notification.icon = 'error'
      }
      notification.title = payload.title
    }

    state.notification = notification
  },
  [SET_DASHBOARDS](state, payload) {
    var dashboardsArray = {
      action: 'manage',
      resource: 'My Dashboards',
      header: 'My Dashboards',
      icon: 'fal fa-home-lg-alt',
      tag: payload.length,
      children: []
    }
    var mobileDashboardsArray = {
      action: 'manage',
      resource: 'My Dashboards',
      title: 'My Dashboards',
      icon: 'fal fa-home-lg-alt',
      tag: payload.length,
      children: []
    }
    payload.forEach(item => {
      dashboardsArray.children.push({
        action: 'manage',
        resource: 'My Dashboards',
        title: item.dashboard_master.dashboard_title,
        route: { name: '/Dashboard/Display', params: { id: item.dashboard_m_id } },
        icon: 'fal fa-circle',
        paramCheck: true
      })
      mobileDashboardsArray.children.push({
        action: 'manage',
        resource: 'My Dashboards',
        title: item.dashboard_master.dashboard_title,
        route: { name: '/Dashboard/Display', params: { id: item.dashboard_m_id } },
        paramCheck: true
      })
    })
    state.dashboards = dashboardsArray
    state.mobileDashboards = mobileDashboardsArray
  },
  [SET_PERMS](state, payload) {
    state.perms = []
    //YETKİLER
    if (state.dashboards?.children?.length) {
      state.perms.push({ action: 'manage', subject: 'My Dashboards' })
    }
    payload.forEach(item => {
      state.perms.push({ action: 'manage', subject: item })
    })
    ability.update([...state.perms])
    state.permsReady = true
  },
  [SET_MENU_ITEMS](state, payload) {
    let menuItemParents = payload.filter(x => x.method.type == 1).sort((x, y) => x.method.order < y.method.order)
    let menuItems = []
    let menuItemsMobile = []
    menuItemParents.forEach(item => {
      let find = menuItems.findIndex(x => x.header === item.method.module.name)
      if (find === -1) {
        menuItems.push({
          action: 'manage',
          resource: item.method.module.name,
          header: item.method.module.name,
          icon: item.method.module.icon,
          children: [
            {
              action: 'manage',
              resource: item.method.name,
              title: item.method.name,
              route: item.method.hash,
              icon: item.method.icon
            }
          ]
        })

        menuItemsMobile.push({
          action: 'manage',
          resource: item.method.module.name,
          title: item.method.module.name,
          icon: item.method.module.icon,
          children: [
            {
              action: 'manage',
              resource: item.method.name,
              title: item.method.name,
              route: item.method.hash
            }
          ]
        })
      } else {
        menuItems[find].children.push({
          action: 'manage',
          resource: item.method.name,
          title: item.method.name,
          route: item.method.hash,
          icon: item.method.icon
        })

        menuItemsMobile[find].children.push({
          action: 'manage',
          resource: item.method.name,
          title: item.method.name,
          route: item.method.hash
        })
      }
    })
    state.menuItems = menuItems
    state.menuItemsMobile = menuItemsMobile
  },
  [SET_ALL_METHODS](state, payload) {
    let modules = []
    payload.forEach(item => {
      let index = modules.findIndex(x => x.module_name == item.module_name)
      if (index == -1) {
        modules.push({
          module_name: item.module_name,
          pages: [],
          methods: []
        })
      }
      index = modules.findIndex(x => x.module_name == item.module_name)
      if (item.type != 2) {
        modules[index].pages.push(item)
      } else {
        modules[index].methods.push(item)
      }
    })
    payload.modules = modules
  },
  [UPDATE_LOCALE](state, payload) {
    state.locale = payload
  },
  [SET_PERMS_READY](state, payload) {
    state.permsReady = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
