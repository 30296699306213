import axiosIns from '@/libs/axios'
import i18n from '@/libs/i18n'
import { SET_NOTIFICATION } from './module-service'
import { SET_ALL_PRODUCT_FOR_FAST_EDIT, SET_DEFAULT_SHOP } from './shop-service'

//Actions
export const GET_PRODUCT_FOR_EDIT = 'getProductForEdit'
export const GET_PRODUCTS = 'getProducts'
export const CREATE_PRODUCT = 'createProduct'
export const UPDATE_PRODUCT = 'updateProduct'
export const GET_UNITS = 'getUnits'
export const GET_BRANDS = 'getBrands'
export const GET_PRODUCT_GROUPS = 'getProductGroups'
export const SET_TEMP_PRODUCT_DATA = 'setTempProductData'
export const GET_ALL_PRODUCT_FOR_FAST_EDIT = 'getAllProductForFastEdit'
export const FAST_PRODUCT_EDIT = 'fastProductEdit'

//Mutations
export const SET_DEFAULT_PRODUCT = 'setDefaultProduct'
export const SET_TEMP_PRODUCT = 'setTempProduct'
export const SET_PRODUCT = 'setProduct'
export const SET_TEMP_IMAGES = 'setTempImages'
export const SET_PRODUCTS_IMAGES = 'setProductsImages'
export const SET_UNITS = 'setUnits'
export const SET_BRANDS = 'setBrands'
export const SET_PRODUCT_GROUPS = 'setProductGroups'
export const SET_PRODUCT_STATE = 'setProductState'
export const ADD_PRODUCT_IMAGE = 'addProductImage'
export const DELETE_PRODUCT_IMAGE = 'deleteProductImage'
export const SET_PRODUCT_PREVIEW_IMAGE = 'setProductPreviewImage'
export const SET_PRODUCT_NEW_VALUES = 'setProductNewValues'

const defaultProduct = {
  product_id: undefined,
  product_name: '',
  product_description: '',
  product_code: '',
  product_group_id: undefined,
  product_group_code: '',
  product_group_name: '',
  unit_code: '',
  vat_rate: 0,
  origin: '',
  orginal_code: '',
  old_product_code: '',
  brand: '',
  compatible_brands: '',
  sales_unit: '',
  is_passive: false,
  unit_id: undefined,
  Expr1: '',
  unit_name: '',
  iso_unit_code: '',
  brand_id: undefined,
  brand_name: '',
  origin_id: undefined,
  previewImage: undefined
}

const state = {
  product: defaultProduct,
  tempProduct: defaultProduct,
  units: {
    ready: false,
    items: []
  },
  brands: {
    ready: false,
    items: []
  },
  groups: {
    ready: false,
    items: []
  },
  images: {
    ready: false,
    items: []
  },
  tempImages: [],
  previewImage: {
    order: 1,
    base_64: undefined
  }
}

const getters = {
  product: state => state.product,
  productBrands: state => state.brands.items,
  productBrandsReady: state => state.brands.ready,
  productGroups: state => state.groups.items,
  productGroupsReady: state => state.groups.ready,
  productsImages: state => state.images.items,
  productsImagesReady: state => state.images.ready,
  productUnits: state => state.units.items,
  productUnitsReady: state => state.units.ready,
  procutPreviewImage: state => state.previewImage
}

const actions = {
  [GET_PRODUCT_FOR_EDIT]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Product/GetByIdProductEdit?product_id=${payload}`)
        .then(response => {
          commit(SET_PRODUCT, response.data.data[0])
          axiosIns
            .post(`Product/GetProductImage?product_id=${payload}`)
            .then(response_i => {
              if (response.data.status) {
                commit(SET_PRODUCTS_IMAGES, response_i.data.data[0])
              } else {
                commit(SET_NOTIFICATION, response.data)
                commit(SET_PRODUCTS_IMAGES, [])
              }
              resolve()
            })
            .catch(() => {
              commit(SET_PRODUCTS_IMAGES, [])
              commit(SET_NOTIFICATION)
              resolve()
            })
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_PRODUCTS]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('Product/GetAllProducts')
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          resolve(response.data.data)
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [CREATE_PRODUCT]({ commit, state, rootGetters }) {
    return new Promise((resolve, reject) => {
      let product_image = []
      state.images.items.forEach(photo => {
        product_image.push({
          product_id: state.product.product_id,
          order: photo.order,
          image: {
            base_64: photo.base_64
          }
        })
      })
      let body = {
        product_id: state.product.product_id,
        product_name: state.product.product_name,
        product_description: state.product.product_description,
        product_code: state.product.product_code,
        product_group_id: state.product.product_group_id,
        vat_rate: state.product.vat_rate * 1,
        orginal_code: state.product.orginal_code,
        old_product_code: state.product.old_product_code,
        compatible_brands: state.product.compatible_brands,
        is_passive: state.product.is_passive,
        unit_id: state.product.unit_id,
        unit_code: state.units.items.find(x => x.id === state.product.unit_id)?.label,
        iso_unit_code: state.product.iso_unit_code,
        brand_id: state.product.brand_id,
        origin_id: state.product.origin_id,
        origin: rootGetters.countries.find(x => x.id === state.product.origin_id)?.label,
        product_image
      }
      axiosIns
        .post('Product/SaveProduct', body)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          if (response.data.status) {
            resolve(response.data.data.product_id)
          } else {
            reject()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [UPDATE_PRODUCT]({ commit, state, rootGetters }) {
    return new Promise((resolve, reject) => {
      let product_image = []
      state.images.items.forEach(photo => {
        product_image.push({
          product_id: state.product.product_id,
          order: photo.order,
          image: {
            base_64: photo.base_64
          }
        })
      })
      let body = {
        product_id: state.product.product_id,
        product_name: state.product.product_name,
        product_description: state.product.product_description,
        product_code: state.product.product_code,
        product_group_id: state.product.product_group_id,
        vat_rate: state.product.vat_rate * 1,
        orginal_code: state.product.orginal_code,
        old_product_code: state.product.old_product_code,
        compatible_brands: state.product.compatible_brands,
        is_passive: state.product.is_passive,
        unit_id: state.product.unit_id,
        unit_code: state.units.items.find(x => x.id === state.product.unit_id)?.label,
        iso_unit_code: state.product.iso_unit_code,
        brand_id: state.product.brand_id,
        origin_id: state.product.origin_id,
        origin: rootGetters.countries.find(x => x.id === state.product.origin_id)?.label,
        product_image
      }
      axiosIns
        .post('Product/UpdateProduct', body)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          if (response.data.status) {
            resolve(response.data.data.product_id)
          } else {
            reject()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_UNITS]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('General/GetAllUnit')
        .then(response => {
          if (response.data.status) {
            commit(SET_UNITS, response.data.data)
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_BRANDS]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('Product/GetAllProductBrand')
        .then(response => {
          if (response.data.status) {
            commit(SET_BRANDS, response.data.data)
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_PRODUCT_GROUPS]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('Product/GetAllProductGroup')
        .then(response => {
          if (response.data.status) {
            commit(SET_PRODUCT_GROUPS, response.data.data)
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [SET_TEMP_PRODUCT_DATA]({ commit }) {
    return new Promise(resolve => {
      commit(SET_TEMP_PRODUCT)
      commit(SET_TEMP_IMAGES)
      commit(SET_NOTIFICATION, {
        title: i18n.t('product.set_temp_title'),
        status: true,
        message: i18n.t('product.set_temp_message')
      })
      resolve()
    })
  },
  [FAST_PRODUCT_EDIT]({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      let product = rootGetters.rawAllProducts.find(x => x.product_id === payload)
      let body = {
        product_id: product.product_id,
        barcode: product.barcode,
        unit_price_tra: product.unit_price_tra_str * 1,
        currency_id: product.currency_id,
        product_name: product.product_name,
        attribute_code: product.attribute_code,
        attribute_name: product.attribute_name,
        is_passive: product.temp_is_passive
      }
      axiosIns
        .post('PriceList/UpdateMainPriceListByProductId', body)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          if (response.data.status) {
            commit(SET_PRODUCT_NEW_VALUES, product)
            resolve()
          } else {
            reject()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_ALL_PRODUCT_FOR_FAST_EDIT]({ commit }) {
    return new Promise((resolve, reject) => {
      commit(SET_DEFAULT_SHOP)
      axiosIns
        .post(`Product/GetAllProductsP`)
        .then(response => {
          if (response.data.status) {
            commit(SET_ALL_PRODUCT_FOR_FAST_EDIT, response.data.data)
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
            reject()
          }
        })
        .catch(err => {
          commit(SET_NOTIFICATION)
        })
    })
  }
}

const mutations = {
  [SET_DEFAULT_PRODUCT](state) {
    state.product = defaultProduct
    state.tempProduct = defaultProduct

    state.units = {
      ready: false,
      items: []
    }
    state.brands = {
      ready: false,
      items: []
    }
    state.groups = {
      ready: false,
      items: []
    }
    state.images = {
      ready: false,
      items: []
    }

    state.tempImages = []
    state.previewImage = {
      order: 1,
      base_64: undefined
    }
  },
  [SET_TEMP_PRODUCT](state) {
    state.product = { ...state.tempProduct }
  },
  [SET_PRODUCT](state, payload) {
    state.product = { ...payload }
    state.tempProduct = { ...payload }
  },
  [SET_TEMP_IMAGES](state) {
    state.images.items = [...state.tempImages]
  },
  [SET_PRODUCTS_IMAGES](state, payload) {
    if (payload.images !== null && payload.images !== undefined) {
      let images = JSON.parse(payload.images)
      if (Array.isArray(images)) {
        state.images.items = images
      } else {
        state.images.items = []
      }
    } else {
      state.images.items = []
    }
    if (state.images.items.length === 0) {
      state.previewImage = {
        order: 1,
        base_64: undefined
      }
    } else {
      state.previewImage = {
        order: state.images.items[0].order,
        base_64: `data:image/jpeg;base64,${state.images.items[0].base_64}`
      }
    }
    state.tempImages = [...state.images.items]
    state.images.ready = true
  },
  [SET_UNITS](state, payload) {
    state.units.items = []
    payload.forEach(item => {
      state.units.items.push({
        id: item.unit_id,
        label: item.unit_code
      })
    })
    state.units.ready = true
  },
  [SET_BRANDS](state, payload) {
    state.brands.items = []
    payload.forEach(item => {
      state.brands.items.push({
        id: item.brand_id,
        label: item.brand_name
      })
    })
    state.brands.ready = true
  },
  [SET_PRODUCT_GROUPS](state, payload) {
    state.groups.items = []
    payload.forEach(item => {
      let temp = state.groups.items.findIndex(x => x.id === item.parent_group_id)
      if (temp === -1) {
        state.groups.items.push({
          id: item.parent_group_id,
          label: item.parent_product_group_name,
          code: item.parent_product_group_code,
          name: item.parent_product_group_name,
          children: [
            {
              id: item.product_group_id,
              label: item.product_group_name,
              code: item.product_group_code,
              name: item.product_group_name
            }
          ]
        })
      } else {
        state.groups.items[temp].children.push({
          id: item.product_group_id,
          label: item.product_group_name,
          code: item.product_group_code,
          name: item.product_group_name
        })
      }
    })
    state.groups.ready = true
  },
  [SET_PRODUCT_STATE](state, payload) {
    state.product.is_passive = payload
  },
  [ADD_PRODUCT_IMAGE](state, payload) {
    let order = state.images.items.length + 1
    state.images.items.push({
      order,
      base_64: payload.split('base64,')[1]
    })
    state.previewImage = {
      order,
      base_64: payload
    }
  },
  [DELETE_PRODUCT_IMAGE](state, payload) {
    let temp = state.images.items.findIndex(x => x.order === payload)
    state.images.items.splice(temp, 1)
    if (state.previewImage.order === payload) {
      if (state.images.items.length > 0) {
        state.previewImage = {
          order: state.images.items[0].order,
          base_64: `data:image/jpeg;base64,${state.images.items[0].base_64}`
        }
      } else {
        state.previewImage = {
          order: 1,
          base_64: undefined
        }
      }
    }
  },
  [SET_PRODUCT_PREVIEW_IMAGE](state, payload) {
    let temp = state.images.items.find(x => x.order === payload)
    if (temp) {
      state.previewImage = {
        order: temp.order,
        base_64: `data:image/jpeg;base64,${temp.base_64}`
      }
    } else {
      state.previewImage = {
        order: 1,
        base_64: undefined
      }
    }
  },
  [SET_PRODUCT_NEW_VALUES](state, product) {
    product.is_passive = product.temp_is_passive
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
