exports.setReportChild = (group, parentId, child) => {
  group.forEach(item => {
    if (item.id === parentId) {
      if (!('children' in item)) {
        item.children = []
      }
      item.children.push(child)
      return group
    } if ('children' in item) {
      const temp = this.setReportChild(
        item.children,
        parentId,
        child,
      )
      if (temp !== false) {
        item.children = temp
      }
    }
  })
  return group
}

exports.setReportGroup = reportGroups => {
  let response = []
  const tempArr = []
  reportGroups.forEach(item => {
    tempArr.push(item)
  })
  tempArr.forEach(item => {
    const child = {
      id: item.report_group_id,
      label: item.group_name,
    }
    if (item.parent_group_id == null) {
      response.push(child)
    } else {
      const check = this.setReportChild(response, item.parent_group_id, child)
      if (check !== false) {
        response = check
      }
    }
  })
  return response
}
