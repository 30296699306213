import store from '@/store'

function convertTL(price, code, currency_list) {
  let temp = code.toUpperCase()
  let tempPrice = price
  if (temp !== 'TRY' && currency_list) {
    let ask = currency_list?.find(x => x.code === code)?.ask
    tempPrice = tempPrice * ask ?? 1
  }
  return tempPrice
}

function priceVat(price, rate) {
  return price * (rate / 100)
}

function priceWithVat(price, rate) {
  return price * (1 + rate / 100)
}

function priceTooltip(price, rate, code, currency_list, lang) {
  let LIST = price
  let LIST_VAT = priceVat(price, rate)
  let LIST_PWV = priceWithVat(price, rate)

  let TRY = convertTL(LIST, code, currency_list)
  let TRY_VAT = convertTL(LIST_VAT, code, currency_list)
  let TRY_PWV = convertTL(LIST_PWV, code, currency_list)

  let LIST_SHOW = priceFormat(LIST, lang, code)
  let LIST_VAT_SHOW = priceFormat(LIST_VAT, lang, code)
  let LIST_PWV_SHOW = priceFormat(LIST_PWV, lang, code)

  let TRY_SHOW = priceFormat(TRY, lang, 'TRY')
  let TRY_VAT_SHOW = priceFormat(TRY_VAT, lang, 'TRY')
  let TRY_PWV_SHOW = priceFormat(TRY_PWV, lang, 'TRY')

  return {
    LIST, //Liste Fiyatı
    LIST_SHOW, //Liste Fiyatı
    LIST_VAT, //Liste KDV Fiyatı
    LIST_VAT_SHOW, //Liste KDV Fiyatı
    LIST_PWV, //Liste KDV Dahil Fiyatı
    LIST_PWV_SHOW, //Liste KDV Dahil Fiyatı

    TRY, //TRY Fiyatı
    TRY_SHOW, //TRY Fiyatı
    TRY_VAT, //TRY KDV Fiyatı
    TRY_VAT_SHOW, //TRY KDV Fiyatı
    TRY_PWV, //TRY KDV Dahil Fiyatı
    TRY_PWV_SHOW //TRY KDV Dahil Fiyatı
  }
}

function priceFormat(price, lang, code, showCode = true, digit = 2) {
  let priceShow = convertNumbertoString(price, digit, digit)

  if (showCode) {
    let codeShow = '₺'
    switch (code) {
      case 'EUR':
        codeShow = '€'
        break
      case 'USD':
        codeShow = '$'
        break
      case 'GBP':
        codeShow = '£'
        break
    }
    switch (lang) {
      case 'en-US':
        return `${codeShow} ${priceShow}`
      default:
        return `${priceShow} ${codeShow}`
    }
  } else {
    return priceShow
  }
}

function convertNumbertoString(number, digit_min = 2, digit_max = 2) {
  return new Intl.NumberFormat(store.getters.getLocale, {
    minimumFractionDigits: digit_min,
    maximumFractionDigits: digit_max
  }).format(number)
}

export { convertTL, priceVat, priceWithVat, priceTooltip, priceFormat, convertNumbertoString }
