export default [
  {
    path: "/Product/List",
    name: "/Product/List",
    component: () => import("@/views/Product/List.vue"),
    meta: {
      title: "Products",
      pageTitle: "Product Management",
      breadcrumb: [
        {
          text: "Products",
          active: true,
        },
      ],
      needRegister: true,
      action: "/Product/List",
    },
  },
  {
    path: "/Product/Create",
    name: "/Product/Create",
    component: () => import("@/views/Product/Create.vue"),
    meta: {
      title: "New Product",
      pageTitle: "Product Management",
      breadcrumb: [
        {
          text: "New Product",
          active: true,
        },
      ],
      needRegister: true,
      action: "/Product/Create",
    },
  },
  {
    path: "/Product/Edit/:id",
    name: "/Product/Edit",
    component: () => import("@/views/Product/Edit.vue"),
    meta: {
      title: "EditProduct",
      pageTitle: "Product Management",
      breadcrumb: [
        {
          text: "Products",
          to: { name: "/Product/List" },
        },
        {
          text: "EditProduct",
          active: true,
        },
      ],
      needRegister: true,
      action: '/Product/Edit'
    },
  },
  {
    path: "/Product/Detail/:id",
    name: "/Shop/Product",
    component: () => import("@/views/Order/Product/Index.vue"),
    meta: {
      contentClass: "ecommerce-application",
      title: "Product",
      pageTitle: "Product Management",
      breadcrumb: [
        {
          text: "Shop",
          to: { name: "/Shop/Shop" },
        },
        {
          text: "Product",
          active: true,
        },
      ],
      needRegister: true,
      action: '/Shop/Product'
    },
  },
  {
    path: "/Product/FastEdit",
    name: "/Product/FastEdit",
    component: () => import("@/views/Product/FastEdit/Index.vue"),
    meta: {
      contentRenderer: "sidebar-left-detached",
      contentClass: "ecommerce-application",
      title: "Fast Edit",
      pageTitle: "Product Management",
      breadcrumb: [
        {
          text: "Fast Edit",
          active: true,
        },
      ],
      needRegister: true,
      action: '/Product/FastEdit'
    },
  },
];
