import { convertTL, priceFormat, priceVat, priceWithVat } from '@/assets/js/functions/currency-functions'
import axiosIns from '@/libs/axios'
import router from '@/router'

import { SET_NOTIFICATION } from './module-service'
import { SET_CART_BUTTON_STATE } from './shop-service'

//moment().locale()

//Actions
export const GET_USER_CART = 'getUserCart'
export const ADD_CART_EVENT = 'addCartEvent'
export const UPDATE_CART_EVENT = 'updateCartEvent'
export const REMOVE_CART_EVENT = 'removeCartEvent'
export const ORDER_COMPLETE = 'orderComplete'
export const CLEAR_CART_EVENT = 'clearCartEvent'

//Mutations
export const SET_CART = 'setCart'
export const ADD_CART = 'addCart'
export const REMOVE_CART = 'removeCart'
export const SET_SHIPPING = 'setShipping'
export const SET_PAYMENT_TYPE = 'setPaymentType'
export const SET_SELECTED_SUB_COMPANY = 'setSelectedSubCompany'

//Kullanım Dışı
export const TOGGLE_WISHLIST = 'toggleWishlist'
export const MOVE_TO_CART = 'moveToCart'

const state = {
  totalAmount: 0,
  selectedShipping: undefined,
  selectedSubCompany: undefined,
  cart: [],
  cartReady: false,
  wishlist: [],
  paymentType: 1
}

const getters = {
  cart: state => state.cart,
  cartReady: state => state.cartReady,
  wishlist: state => state.wishlist,
  selectedShipping: state => state.selectedShipping,
  selectedSubCompany: state => state.selectedSubCompany,
  paymentType: state => state.paymentType,
  cartDetail: (state, _getters, _rootState, rootGetters) => {
    let TOTAL_TRY = 0
    let TOTAL_TRY_VAT = 0
    let TOTAL_TRY_D_VAT = 0
    let TOTAL_TRY_PWV = 0
    let TOTAL_TRY_D_PWV = 0

    let TOTAL_USD = 0
    let TOTAL_USD_VAT = 0
    let TOTAL_USD_D_VAT = 0
    let TOTAL_USD_PWV = 0
    let TOTAL_USD_D_PWV = 0

    let USD = 0
    let USD_VAT = 0
    let USD_D_VAT = 0
    let USD_PWV = 0
    let USD_D_PWV = 0

    let USD_TRY = 0
    let USD_TRY_VAT = 0
    let USD_TRY_D_VAT = 0
    let USD_TRY_PWV = 0
    let USD_TRY_D_PWV = 0

    let EUR = 0
    let EUR_VAT = 0
    let EUR_D_VAT = 0
    let EUR_PWV = 0
    let EUR_D_PWV = 0

    let EUR_TRY = 0
    let EUR_TRY_VAT = 0
    let EUR_TRY_D_VAT = 0
    let EUR_TRY_PWV = 0
    let EUR_TRY_D_PWV = 0

    let TRY = 0
    let TRY_VAT = 0
    let TRY_D_VAT = 0
    let TRY_PWV = 0
    let TRY_D_PWV = 0

    let DISCOUNT = 0
    let DISCOUNT_TRY = 0

    let VAT_RATE = 0

    state.cart.forEach(product => {
      let rate = product.vat_rate
      let type = product.currency_code

      let price = product.unit_price_tra * product.quantity

      let discount = 1

      let p_vat = priceVat(price, rate)
      let d_p_vat = priceVat(state.paymentType === 1 ? price - discount : price, rate)

      let pwv = priceWithVat(price, rate)
      let d_pwv = priceWithVat(state.paymentType === 1 ? price - discount : price, rate)

      TOTAL_USD += price
      TOTAL_USD_VAT += p_vat
      TOTAL_USD_D_VAT += d_p_vat
      TOTAL_USD_PWV += pwv
      TOTAL_USD_D_PWV += d_pwv

      DISCOUNT += discount

      switch (type.toUpperCase()) {
        case 'USD':
          USD += price
          USD_VAT += p_vat
          USD_D_VAT += d_p_vat
          USD_PWV += pwv
          USD_D_PWV += d_pwv
          break
        case 'EUR':
          EUR += price
          EUR_VAT += p_vat
          EUR_D_VAT += d_p_vat
          EUR_PWV += pwv
          EUR_D_PWV += d_pwv
          break
        case 'TRY':
          TRY += price
          TRY_VAT += p_vat
          TRY_D_VAT += d_p_vat
          TRY_PWV += pwv
          TRY_D_PWV += d_pwv
          break
        default:
          break
      }
    })

    if (DISCOUNT > 0) {
      DISCOUNT_TRY = convertTL(DISCOUNT, 'USD', rootGetters.currency)
    }

    if (TOTAL_USD > 0) {
      TOTAL_TRY = convertTL(TOTAL_USD, 'USD', rootGetters.currency)
      TOTAL_TRY_VAT = convertTL(TOTAL_USD_VAT, 'USD', rootGetters.currency)
      TOTAL_TRY_D_VAT = convertTL(TOTAL_USD_D_VAT, 'USD', rootGetters.currency)
      TOTAL_TRY_PWV = convertTL(TOTAL_USD_PWV, 'USD', rootGetters.currency)
      TOTAL_TRY_D_PWV = convertTL(TOTAL_USD_D_PWV, 'USD', rootGetters.currency)

      VAT_RATE = Math.round(((TOTAL_USD_PWV - TOTAL_USD) / TOTAL_USD) * 100)
    }

    if (USD > 0) {
      USD_TRY = convertTL(USD, 'USD', rootGetters.currency)
      USD_TRY_VAT = convertTL(USD_VAT, 'USD', rootGetters.currency)
      USD_TRY_D_VAT = convertTL(USD_D_VAT, 'USD', rootGetters.currency)
      USD_TRY_PWV = convertTL(USD_PWV, 'USD', rootGetters.currency)
      USD_TRY_D_PWV = convertTL(USD_D_PWV, 'USD', rootGetters.currency)
    }

    if (EUR > 0) {
      EUR_TRY = convertTL(EUR, 'EUR', rootGetters.currency)
      EUR_TRY_VAT = convertTL(EUR_VAT, 'EUR', rootGetters.currency)
      EUR_TRY_D_VAT = convertTL(EUR_D_VAT, 'EUR', rootGetters.currency)
      EUR_TRY_PWV = convertTL(EUR_PWV, 'EUR', rootGetters.currency)
      EUR_TRY_D_PWV = convertTL(EUR_D_PWV, 'EUR', rootGetters.currency)
    }

    let TOTAL_TRY_SHOW = priceFormat(TOTAL_TRY, rootGetters.getLocale, 'TRY')
    let TOTAL_TRY_VAT_SHOW = priceFormat(TOTAL_TRY_VAT, rootGetters.getLocale, 'TRY')
    let TOTAL_TRY_D_VAT_SHOW = priceFormat(TOTAL_TRY_D_VAT, rootGetters.getLocale, 'TRY')
    let TOTAL_TRY_PWV_SHOW = priceFormat(TOTAL_TRY_PWV, rootGetters.getLocale, 'TRY')
    let TOTAL_TRY_D_PWV_SHOW = priceFormat(TOTAL_TRY_D_PWV, rootGetters.getLocale, 'TRY')

    let TOTAL_USD_SHOW = priceFormat(TOTAL_USD, rootGetters.getLocale, 'USD')
    let TOTAL_USD_VAT_SHOW = priceFormat(TOTAL_USD_VAT, rootGetters.getLocale, 'USD')
    let TOTAL_USD_D_VAT_SHOW = priceFormat(TOTAL_USD_D_VAT, rootGetters.getLocale, 'USD')
    let TOTAL_USD_PWV_SHOW = priceFormat(TOTAL_USD_PWV, rootGetters.getLocale, 'USD')
    let TOTAL_USD_D_PWV_SHOW = priceFormat(TOTAL_USD_D_PWV, rootGetters.getLocale, 'USD')

    let USD_SHOW = priceFormat(USD, rootGetters.getLocale, 'USD')
    let USD_VAT_SHOW = priceFormat(USD_VAT, rootGetters.getLocale, 'USD')
    let USD_D_VAT_SHOW = priceFormat(USD_D_VAT, rootGetters.getLocale, 'USD')
    let USD_PWV_SHOW = priceFormat(USD_PWV, rootGetters.getLocale, 'USD')
    let USD_D_PWV_SHOW = priceFormat(USD_D_PWV, rootGetters.getLocale, 'USD')

    let USD_TRY_SHOW = priceFormat(USD_TRY, rootGetters.getLocale, 'TRY')
    let USD_TRY_VAT_SHOW = priceFormat(USD_TRY_VAT, rootGetters.getLocale, 'TRY')
    let USD_TRY_D_VAT_SHOW = priceFormat(USD_TRY_D_VAT, rootGetters.getLocale, 'TRY')
    let USD_TRY_PWV_SHOW = priceFormat(USD_TRY_PWV, rootGetters.getLocale, 'TRY')
    let USD_TRY_D_PWV_SHOW = priceFormat(USD_TRY_D_PWV, rootGetters.getLocale, 'TRY')

    let EUR_SHOW = priceFormat(EUR, rootGetters.getLocale, 'EUR')
    let EUR_VAT_SHOW = priceFormat(EUR_VAT, rootGetters.getLocale, 'EUR')
    let EUR_D_VAT_SHOW = priceFormat(EUR_D_VAT, rootGetters.getLocale, 'EUR')
    let EUR_PWV_SHOW = priceFormat(EUR_PWV, rootGetters.getLocale, 'EUR')
    let EUR_D_PWV_SHOW = priceFormat(EUR_D_PWV, rootGetters.getLocale, 'EUR')

    let EUR_TRY_SHOW = priceFormat(EUR_TRY, rootGetters.getLocale, 'TRY')
    let EUR_TRY_VAT_SHOW = priceFormat(EUR_TRY_VAT, rootGetters.getLocale, 'TRY')
    let EUR_TRY_D_VAT_SHOW = priceFormat(EUR_TRY_D_VAT, rootGetters.getLocale, 'TRY')
    let EUR_TRY_PWV_SHOW = priceFormat(EUR_TRY_PWV, rootGetters.getLocale, 'TRY')
    let EUR_TRY_D_PWV_SHOW = priceFormat(EUR_TRY_D_PWV, rootGetters.getLocale, 'TRY')

    let TRY_SHOW = priceFormat(TRY, rootGetters.getLocale, 'TRY')
    let TRY_VAT_SHOW = priceFormat(TRY_VAT, rootGetters.getLocale, 'TRY')
    let TRY_D_VAT_SHOW = priceFormat(TRY_D_VAT, rootGetters.getLocale, 'TRY')
    let TRY_PWV_SHOW = priceFormat(TRY_PWV, rootGetters.getLocale, 'TRY')
    let TRY_D_PWV_SHOW = priceFormat(TRY_D_PWV, rootGetters.getLocale, 'TRY')

    let DISCOUNT_SHOW = priceFormat(DISCOUNT, rootGetters.getLocale, 'USD')
    let DISCOUNT_TRY_SHOW = priceFormat(DISCOUNT_TRY, rootGetters.getLocale, 'TRY')

    return {
      TOTAL_TRY,
      TOTAL_TRY_SHOW,
      TOTAL_TRY_VAT,
      TOTAL_TRY_VAT_SHOW,
      TOTAL_TRY_D_VAT,
      TOTAL_TRY_D_VAT_SHOW,
      TOTAL_TRY_PWV,
      TOTAL_TRY_PWV_SHOW,
      TOTAL_TRY_D_PWV,
      TOTAL_TRY_D_PWV_SHOW,

      TOTAL_USD,
      TOTAL_USD_SHOW,
      TOTAL_USD_VAT,
      TOTAL_USD_VAT_SHOW,
      TOTAL_USD_D_VAT,
      TOTAL_USD_D_VAT_SHOW,
      TOTAL_USD_PWV,
      TOTAL_USD_PWV_SHOW,
      TOTAL_USD_D_PWV,
      TOTAL_USD_D_PWV_SHOW,

      USD,
      USD_SHOW,
      USD_VAT,
      USD_VAT_SHOW,
      USD_D_VAT,
      USD_D_VAT_SHOW,
      USD_PWV,
      USD_PWV_SHOW,
      USD_D_PWV,
      USD_D_PWV_SHOW,

      USD_TRY,
      USD_TRY_SHOW,
      USD_TRY_VAT,
      USD_TRY_VAT_SHOW,
      USD_TRY_D_VAT,
      USD_TRY_D_VAT_SHOW,
      USD_TRY_PWV,
      USD_TRY_PWV_SHOW,
      USD_TRY_D_PWV,
      USD_TRY_D_PWV_SHOW,

      EUR,
      EUR_SHOW,
      EUR_VAT,
      EUR_VAT_SHOW,
      EUR_D_VAT,
      EUR_D_VAT_SHOW,
      EUR_PWV,
      EUR_PWV_SHOW,
      EUR_D_PWV,
      EUR_D_PWV_SHOW,

      EUR_TRY,
      EUR_TRY_SHOW,
      EUR_TRY_VAT,
      EUR_TRY_VAT_SHOW,
      EUR_TRY_D_VAT,
      EUR_TRY_D_VAT_SHOW,
      EUR_TRY_PWV,
      EUR_TRY_PWV_SHOW,
      EUR_TRY_D_PWV,
      EUR_TRY_D_PWV_SHOW,

      TRY,
      TRY_SHOW,
      TRY_VAT,
      TRY_VAT_SHOW,
      TRY_D_VAT,
      TRY_D_VAT_SHOW,
      TRY_PWV,
      TRY_PWV_SHOW,
      TRY_D_PWV,
      TRY_D_PWV_SHOW,

      DISCOUNT,
      DISCOUNT_SHOW,
      DISCOUNT_TRY,
      DISCOUNT_TRY_SHOW,

      VAT_RATE
    }
  }
}

const actions = {
  [GET_USER_CART]({ commit }) {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(`Order/GetUserCart`)
        .then(response => {
          if (response.data.status) {
            commit(SET_CART, response.data.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
          resolve()
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },

  [ADD_CART_EVENT]({ commit }, payload) {
    return new Promise(resolve => {
      commit(SET_CART_BUTTON_STATE, { product_id: payload.product_id, state: true })
      axiosIns
        .post(`Order/AddToCart`, payload)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          if (response.data.status) {
            commit(ADD_CART, response.data.data[0])
          }
          commit(SET_CART_BUTTON_STATE, { product_id: payload.product_id, state: false })
          resolve()
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
          commit(SET_CART_BUTTON_STATE, { product_id: payload.product_id, state: false })
          resolve()
        })
    })
  },

  [UPDATE_CART_EVENT]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Order/UpdateToCart`, payload)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          if (response.data.status) {
            resolve()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },

  [REMOVE_CART_EVENT]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Order/DeleteToCart`, payload)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          if (response.data.status) {
            commit(REMOVE_CART, payload.product_id)
            resolve()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },

  [CLEAR_CART_EVENT]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post(`Order/DeleteAllCart`)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          if (response.data.status) {
            commit(SET_CART, [])
            resolve()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },

  [ORDER_COMPLETE]({ commit, state, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      let body = {
        sale_type: state.paymentType,
        company_address_id: state.selectedShipping,
        sub_company_id: state.selectedSubCompany
      }
      switch (state.paymentType) {
        case 1:
          let ins = { ...rootGetters.installmentOptions.find(x => x.count === rootGetters.installment) }
          let camps = {}
          if (ins.campaigns.length > 0) {
            camps = ins.campaigns[0]
          }
          let currentUser = { ...rootGetters.currentUser }
          let payfull_sale = {
            cc_name: rootGetters.paymentData.cardName,
            cc_number: rootGetters.paymentData.cardNumberReal.split(' ').join(''),
            cc_month: rootGetters.paymentData.cardMonth.toString(),
            cc_year: rootGetters.paymentData.cardYear.toString(),
            cc_cvc: rootGetters.paymentData.cardCvv,
            installments: rootGetters.installment,
            campaign_id: 'campaign_id' in camps ? camps.campaign_id : '',
            extra_installment_count: 'extra_installments' in camps ? camps.extra_installments : '',
            bank_id: rootGetters.paymentData.bank,
            gateway: rootGetters.paymentData.gateway,
            language: 'tr',
            currency: 'TRY',
            use3d: '1',
            client_ip: rootGetters.ipAddress,
            customer_firstname: currentUser.name,
            customer_lastname: currentUser.surname,
            customer_email: currentUser.email,
            customer_phone: currentUser.gsm_number,
            payment_title: 'Sipariş Ödeme',
            passive_data: '',
            description: rootGetters.paymentData.description,
            user_id: currentUser.user_id,
            return_url: `${window.location.origin}${router
              .resolve({
                name: '/Shop/OrderThanks',
                params: { id: '1' }
              })
              .href.replace('1', '')}`,
            error_url: `${window.location.origin}${router
              .resolve({
                name: 'CheckoutError',
                params: { id: '1' }
              })
              .href.replace('1', '')}`
          }
          body.payfull_sale = payfull_sale
          break
        case 2:
        case 3:
          break
      }
      axiosIns
        .post(`Order/OrderComplete`, body)
        .then(response => {
          if (response.data.status) {
            if (response.data.data?.status === false || response.data.data?.status === 0) {
              reject(response.data.data)
            } else {
              resolve(response.data.data)
            }
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  }
}

const mutations = {
  [SET_CART](state, payload) {
    payload?.forEach(item => {
      if (item.images) {
        item.images = JSON.parse(item.images)
      } else {
        item.images = []
      }
    })
    state.cart = payload
    state.cartReady = true
  },
  [ADD_CART](state, payload) {
    payload.cartButtonState = false
    if (payload.images) {
      if (typeof payload.images === 'string') {
        payload.images = JSON.parse(payload.images)
      }
    } else {
      payload.images = []
    }
    state.cart.push({
      ...payload,
      totalCount: 1
    })
  },
  [REMOVE_CART](state, payload) {
    let checkIndex = state.cart.findIndex(x => x.product_id === payload)
    state.cart.splice(checkIndex, 1)
  },
  [TOGGLE_WISHLIST](state, payload) {
    let checkIndex = state.wishlist.findIndex(x => x.product_id === payload)
    if (checkIndex === -1) {
      state.wishlist.push(payload)
    } else {
      state.wishlist.splice(checkIndex, 1)
    }
    localStorage.bilgeCodeWishlist = JSON.stringify(state.wishlist)
  },
  [MOVE_TO_CART](state, payload) {
    let checkIndex = state.wishlist.findIndex(x => x.product_id === payload.product_id)
    state.wishlist.splice(checkIndex, 1)
    localStorage.bilgeCodeWishlist = JSON.stringify(state.wishlist)

    state.cart.push({
      ...payload,
      totalCount: 1
    })
    localStorage.bilgeCodeCart = JSON.stringify(state.cart)
  },
  [SET_SHIPPING](state, payload) {
    state.selectedShipping = payload
  },
  [SET_PAYMENT_TYPE](state, payload) {
    state.paymentType = payload
  },
  [SET_SELECTED_SUB_COMPANY](state, payload) {
    state.selectedSubCompany = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
