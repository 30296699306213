import axiosIns from '@/libs/axios'
import { convertTL, priceFormat, priceTooltip, priceVat, priceWithVat } from '@/assets/js/functions/currency-functions'
import ability from '@/libs/acl/ability'
import { SET_NOTIFICATION } from './module-service'

//Actions
export const GET_ALL_CURRENCY = 'getAllCurrency'
export const GET_ALL_CURRENCY_TYPES = 'getAllCurrencyTypes'

//Mutations
export const SET_CURRENCIES = 'setCurrencies'

const state = {
  currency: undefined,
  currencyReady: false,
  lastCurrency: undefined
}

const getters = {
  currency: state => state.currency,
  currencyReady: state => state.currencyReady,
  lastCurrency: state => state.lastCurrency
}

const actions = {
  [GET_ALL_CURRENCY]({ commit }) {
    return new Promise(resolve => {
      if (ability.can('manage', '/Order/GetUserCart')) {
        axiosIns
          .get('Currency/GetRates')
          .then(response => {
            if (response.data.status) {
              commit(SET_CURRENCIES, response.data.data)
              resolve()
            } else {
              commit(SET_NOTIFICATION, response.data)
            }
          })
          .catch(() => {
            commit(SET_NOTIFICATION)
          })
      }
    })
  },
  [GET_ALL_CURRENCY_TYPES]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post(`General/GetAllCurrency`)
        .then((response, reject) => {
          if (response.data.status) {
            resolve(response.data.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
            reject()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  }
}

const mutations = {
  [SET_CURRENCIES](state, payload) {
    state.lastCurrency = { ...state.currency }
    state.currency = payload
    if (!state.currencyReady && state.lastCurrency) {
      state.currencyReady = true
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
