import { convertNumbertoString } from '@/assets/js/functions/currency-functions'
import { calculateRulePrice, priceListChangeType } from '@/assets/js/functions/priceList-functions'
import axiosIns from '@/libs/axios'
import i18n from '@/libs/i18n'
import { SET_NOTIFICATION } from './module-service'
import { GET_PRODUCT_GROUPS } from './product-service'

//Actions
export const GET_COMPANY_PRICE_LIST = 'getCompanyPrices'
export const GET_COMPANY_CURRENT_PRICE_LIST = 'getCompanyCurrentPriceList'
export const GET_BY_ID_PRICE_LIST = 'getByIdPriceList'
export const GET_ALL_PRICE_LISTS = 'getAllPriceLists'
export const SET_COMPANY_PRICE_LIST = 'setCompanyPriceList'
export const CHANGE_PRODUCT_PRICE = 'changeProductPrice'
export const CHANGE_PRODUCT_PRICES = 'chanceProductPrices'
export const UPDATE_PRICE_LIST_RULE_PRICE = 'updatePriceListRulePrice'

//Mutations
export const SET_DEFAULT_PRICE_LIST = 'setDefaultPriceList'
export const MUTATE_PRICE_LIST = 'mutatePriceList'
export const SET_CURRENT_PRICE_LIST = 'setCurrentPriceList'
export const SET_PRICE_LIST_ID = 'setPriceListId'
export const SET_PRICE_LISTS = 'setPriceLists'
export const PRICE_LIST_READY_TOGGLE = 'priceListReadyToggle'
export const SET_PRICE_LIST_CATEGORY = 'setPriceListCategory'
export const UPDATE_PRODUCT_PRICES = 'updateProductPrices'
export const SET_PRICE_LIST_RULE_PRICE = 'setPriceListRulePrice'
export const SET_PRODUCT_PRICES_NEW_RULE_VALUES = 'setProductPricesNewRuleValues'

const state = {
  price_list: undefined,
  price_list_products: [],
  price_lists: [],
  price_list_ready: true,
  price_list_selected_category: [-1],
  price_list_filters: {
    percentagle: 0,
    amount: 0,
    type: true,
    search: ''
  }
}

const getters = {
  companyPriceList: state => state.price_list,
  companyPriceListProducts: state =>
    state.price_list_products
      .filter(x => x.show)
      .filter(y => {
        let search = state.price_list_filters.search.toLocaleLowerCase('tr-TR')
        if (search !== '') {
          let check_group = y.p_cat.toLocaleLowerCase('tr-TR').includes(search)
          let check_code = y.p_code.toLocaleLowerCase('tr-TR').includes(search)
          let check_name = y.p_name.toLocaleLowerCase('tr-TR').includes(search)
          let check_brand = y.p_brand.toLocaleLowerCase('tr-TR').includes(search)
          return check_group || check_code || check_name || check_brand
        } else {
          return true
        }
      }),
  companyPriceLists: state => state.price_lists,
  companyPriceListReady: state => state.price_list_ready,
  companyPriceListProductCategories: (state, rootGetters) => [
    {
      id: -1,
      label: i18n.t('company_crud.3.all'),
      children: rootGetters.productGroups
    }
  ],
  companyPriceListSelectedCategory: state => state.price_list_selected_category,
  companyPriceListFilters: state => state.price_list_filters
}

const actions = {
  [GET_COMPANY_PRICE_LIST]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(`Company/GetCompanyPriceList?company_id=${payload}`)
        .then(response => {
          if (response.data.status) {
            if (response.data?.data?.length) {
              commit(MUTATE_PRICE_LIST, response.data.data[0])
              resolve(response.data.data[0])
            } else {
              reject()
            }
          } else {
            commit(SET_NOTIFICATION, response.data)
            reject()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_COMPANY_CURRENT_PRICE_LIST]({ commit, dispatch }, payload) {
    return new Promise(resolve => {
      commit(PRICE_LIST_READY_TOGGLE)
      dispatch(GET_COMPANY_PRICE_LIST, payload)
        .then(response => {
          if (response.products.length > 0) {
            commit(SET_CURRENT_PRICE_LIST, response)
          }
        })
        .then(() => {
          dispatch(GET_PRODUCT_GROUPS).then(() => {
            resolve()
          })
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
        .finally(() => {
          commit(PRICE_LIST_READY_TOGGLE)
        })
    })
  },
  [GET_ALL_PRICE_LISTS]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post(`PriceList/GetAllPriceList`)
        .then(response => {
          if (response.data.status) {
            commit(SET_PRICE_LISTS, response.data.data)
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_BY_ID_PRICE_LIST]({ commit }, payload) {
    return new Promise(resolve => {
      commit(PRICE_LIST_READY_TOGGLE)
      axiosIns
        .post(`PriceList/GetByIdPriceList?price_list_m_id=${payload}`)
        .then(response => {
          if (response.data.status) {
            commit(SET_CURRENT_PRICE_LIST, response.data.data)
            commit(PRICE_LIST_READY_TOGGLE)
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [SET_COMPANY_PRICE_LIST]({ commit, dispatch, state }, payload) {
    return new Promise(resolve => {
      commit(PRICE_LIST_READY_TOGGLE)
      axiosIns
        .post(`Company/SetCompanyPriceList?price_list_m_id=${payload.price_list_m_id}&company_id=${payload.company_id}`)
        .then(response => {
          if (response.data.status) {
            if (response.data?.data?.length) {
              commit(MUTATE_PRICE_LIST, response.data.data[0])
              commit(SET_CURRENT_PRICE_LIST, response.data.data[0])
              dispatch(GET_PRODUCT_GROUPS).then(() => {
                resolve()
              })
            }
            commit(PRICE_LIST_READY_TOGGLE)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [CHANGE_PRODUCT_PRICE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(`PriceList/UpdatePriceListDetail`, payload)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          if (response.data.status) {
            resolve()
          } else {
            reject()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [CHANGE_PRODUCT_PRICES]({ commit, dispatch, getters }, payload) {
    return new Promise(resolve => {
      commit(UPDATE_PRODUCT_PRICES)
      let body = getters.companyPriceListProducts.reduce((temp, product) => {
        temp.push({
          price_list_d_id: product.price_list_d_id,
          rule_amount: product.c_amount * 1,
          rule_rate: product.c_percentagle * 1,
          rule_type: product.c_type ? 1 : -1,
          unit_price_tra: product.p_price.toFixed(2),
          currency_id: product.currency_id
        })
        return temp
      }, [])
      axiosIns
        .post(`PriceList/UpdatePriceListDetailList`, body)
        .then(response => {
          if (response.data.status) {
            commit(SET_PRODUCT_PRICES_NEW_RULE_VALUES, getters.companyPriceListProducts)
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [UPDATE_PRICE_LIST_RULE_PRICE]({ commit, state }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`PriceList/UpdatePriceListRulePrice?price_list_m_id=${state.price_list.m_id}&rule_price=${payload}`)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          commit(SET_PRICE_LIST_RULE_PRICE, payload)
          resolve()
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  }
}

const mutations = {
  [SET_DEFAULT_PRICE_LIST](state) {
    state.price_list_selected_category = [-1]
    state.price_list_filters = {
      percentagle: 0,
      amount: 0,
      type: true,
      search: ''
    }
    state.price_list = undefined
    state.price_list_products = []
    state.price_lists = []
    state.price_list_ready = true
  },
  [MUTATE_PRICE_LIST](state, payload) {
    payload.products = JSON.parse(payload.products)
  },
  [SET_PRICE_LIST_ID](state, payload) {
    state.price_list.price_list_m_id = payload
  },
  [SET_PRICE_LISTS](state, payload) {
    payload.forEach(item => {
      state.price_lists.push({
        id: item.price_list_m_id,
        label: item.price_list_name
      })
    })
  },
  [PRICE_LIST_READY_TOGGLE](state, payload) {
    state.price_list_ready = !state.price_list_ready
  },
  [SET_CURRENT_PRICE_LIST](state, payload) {
    state.price_list_products = []
    state.price_list = {
      m_id: payload.price_list_m_id,
      group_name: payload.price_list_group_name,
      rule_price: payload.rule_price
    }
    payload.products.forEach(item => {
      let product = {
        p_id: item.product_id,
        price_list_d_id: item.price_list_d_id,
        product_group_id: item.product_group_id,
        parent_group_id: item.parent_group_id,
        p_cat: item.product_group_name,
        p_code: item.product_code,
        p_name: item.product_name,
        p_brand: item.brand_name,
        currency_id: item.currency_id,
        product_images: item.product_images,
        p_cur: item.currency_code,
        p_price: item.unit_price_tra,
        c_percentagle: item.rule_rate,
        c_percentagle_str: convertNumbertoString(item.rule_rate, 0, 2),
        c_percentagle_show: '',
        c_amount: item.rule_amount,
        c_amount_str: convertNumbertoString(item.rule_amount, 0, 2),
        c_amount_show: '',
        c_type: item.rule_type > -1,
        c_type_show: 0,
        new_amount_show: 0,
        current_price: item.unit_price_tra,
        show: true,
        process_type: false
      }
      product.new_amount = convertNumbertoString(calculateRulePrice(product), 0, 2)
      //priceListChangeType(product);
      state.price_list_products.push(product)
    })
  },
  [SET_PRICE_LIST_CATEGORY](state, payload) {
    state.price_list_selected_category = payload
    state.price_list_products.forEach(item => {
      if (payload.includes(-1) || payload.length === 0) {
        item.show = true
      } else {
        if (payload.some(x => x === item.parent_group_id || x === item.product_group_id)) {
          item.show = true
        } else {
          item.show = false
        }
      }
    })
  },
  [UPDATE_PRODUCT_PRICES](state, payload) {
    state.price_list_products
      .filter(x => x.show)
      .forEach(item => {
        let multiplier = state.price_list_filters.type ? 1 : -1
        let add = 0
        let amount = 0

        item.c_type = state.price_list_filters.type

        if (state.price_list_filters.percentagle * 1 > 0) {
          item.c_percentagle = state.price_list_filters.percentagle * 1 <= 100 ? state.price_list_filters.percentagle * 1 : 100
          item.c_amount = 0

          add = ((item.p_price * item.c_percentagle) / 100) * multiplier
        } else {
          item.c_percentagle = 0
          item.c_amount = state.price_list_filters.amount * 1 <= item.p_price ? state.price_list_filters.amount * 1 : item.p_price

          add = item.c_amount * multiplier
        }

        amount = item.p_price + add
        item.new_amount = amount > 0 ? amount : 0
      })
  },
  [SET_PRICE_LIST_RULE_PRICE](state, payload) {
    state.price_list.rule_price = payload
  },
  [SET_PRODUCT_PRICES_NEW_RULE_VALUES](state, payload) {
    payload.forEach(item => {
      item.c_percentagle = state.price_list_filters.percentagle
      item.c_percentagle_str = state.price_list_filters.percentagle
      item.c_amount = state.price_list_filters.amount
      item.c_amount_str = state.price_list_filters.amount
      item.new_amount = calculateRulePrice(item)
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
