import moment from 'moment'
import axiosIns from '@/libs/axios'

import { SET_NOTIFICATION } from './module-service'

moment().locale()

export const GET_ALL_USERS = 'getAllUsers'
export const GET_USER = 'getUser'
export const CREATE_USER = 'createUser'
export const UPDATE_USER = 'updateUser'
export const GET_ACCOUNT = 'getAccount'
export const UPDATE_ACCOUNT = 'updateAccount'
export const CHANGE_PASSWORD = 'changePassword'
export const GET_IMAGE_BASE_64 = 'getUserPhotoBase64'

export const SET_ACCOUNT = 'setAccount'

const state = {
  user: {
    name: '',
    surname: '',
    sys_admin: false,
    image_base_64: null
  }
}

const getters = {
  currentUser: state => state.user,
  isAdmin: state => state.user?.sys_admin ?? false,
  isMasterCompany: state => state.user?.company?.master_company ?? false
}

const actions = {
  [GET_ALL_USERS]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('User/GetAllUsers')
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_USER]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`User/GetByIdUser?user_id=${payload}`)
        .then(response => {
          if (response.data.status) {
            //commit(SET_NOTIFICATION,response.data)
            resolve(response.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_IMAGE_BASE_64]({}, payload) {
    return new Promise(resolve => {
      axiosIns
        .get(`Image/GetImageBase64?image_id=${payload}`)
        .then(response => {
          if (response.data) {
            resolve(response.data)
          } else {
            commit(SET_NOTIFICATION)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [CREATE_USER]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post('User/SaveUser', payload)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          resolve(response.data.data.user_id)
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [UPDATE_USER]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post('User/UpdateUser', payload)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_ACCOUNT]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('User/GetMyData')
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          commit(SET_ACCOUNT, response.data.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [UPDATE_ACCOUNT]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post('User/UpdateMyData', payload)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          commit(SET_ACCOUNT, response.data.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [CHANGE_PASSWORD]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post('User/ChangeMyPassword', payload)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          resolve(response.data)
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  }
}

const mutations = {
  [SET_ACCOUNT](state, user_data) {
    if (user_data) {
      state.user = user_data
    } else {
      state.user = {
        name: '',
        surname: '',
        image_base_64: null,
        sys_admin: false
      }
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
