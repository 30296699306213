import axiosIns from '@/libs/axios'
import router from '@/router'
import { SET_NOTIFICATION } from './module-service'
import { GET_ALL_REPORTS, GET_MY_REPORTS } from './report-service'

//Actions
export const GET_DASHBOARD_FOR_UPDATE = 'getDashboardForUpdate'
export const GET_USEABLE_REPORTS = 'getUseableReports'
export const SAVE_DASHBOARD = 'saveDashboard'
export const UPDATE_DASHBOARD = 'updateDashboard'

//Mutations
export const SET_DEFAULT_DASHBOARD = 'setDefaultDashboard'
export const SET_DASHBOARD = 'setDashboard'
export const SET_USEABLE_REPORTS = 'setUseableReports'
export const DELETE_REPORT = 'deleteReport'
export const UPDATE_COLUMN = 'updateColumn'
export const TOGGLE_DASHBOARD_STATUS = 'toggleDashboardStatus'
export const SET_DASHBOARD_CRUD_TITLE = 'setDashboardCrudTitle'
export const SET_DASHBOARD_CRUD_DESCRIPTION = 'setDashboardCrudDescription'

const state = {
  is_passive: false,
  dashboard_m_id: null,
  dashboard_title: '',
  description: '',
  reports: [],
  useableReports: []
}

const getters = {
  useableReports: state => state.useableReports,
  dashboardCrudIsPassive: state => state.is_passive,
  dashboardCrudReports: state => state.reports,
  dashboardCrudTitle: state => state.dashboard_title,
  dashboardCrudDescription: state => state.description
}

const actions = {
  [GET_DASHBOARD_FOR_UPDATE]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Dashboard/GetByIdDashboard?dashboard_m_id=${payload}`)
        .then(response => {
          if (response.data.status) {
            commit(SET_DASHBOARD, response.data.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_USEABLE_REPORTS]({ commit, dispatch, getters }) {
    return new Promise(resolve => {
      if (getters.isAdmin) {
        dispatch(GET_ALL_REPORTS).then(response => {
          commit(SET_USEABLE_REPORTS, response.data)
          resolve()
        })
      } else {
        dispatch(GET_MY_REPORTS).then(response => {
          commit(SET_USEABLE_REPORTS, response.data)
          resolve()
        })
      }
    })
  },
  [SAVE_DASHBOARD]({ commit, state }) {
    return new Promise(resolve => {
      let dashboard_detail = []
      state.reports.forEach((item, index) => {
        dashboard_detail.push({
          report_m_id: item.id,
          order: index,
          column: item.column
        })
      })
      let dashboard = {
        is_passive: state.is_passive,
        dashboard_title: state.dashboard_title,
        description: state.description,
        dashboard_detail
      }
      if (state.dashboard_m_id) {
        dashboard = { dashboard_m_id: state.dashboard_m_id, ...dashboard }
        axiosIns
          .post('Dashboard/UpdateDashboard', dashboard)
          .then(response => {
            commit(SET_NOTIFICATION, response.data)
            if (response.data.status) {
              router.push({ name: '/Dashboard/Display', params: { id: state.dashboard_m_id } })
            }
          })
          .catch(() => {
            commit(SET_NOTIFICATION)
          })
      } else {
        axiosIns
          .post('Dashboard/SaveDashboard', dashboard)
          .then(response => {
            commit(SET_NOTIFICATION, response.data)
            if (response.data.status) {
              router.push({ name: '/Dashboard/Display', params: { id: response.data.data.dashboard_m_id } })
              resolve(response.data)
            }
          })
          .catch(() => {
            commit(SET_NOTIFICATION)
          })
      }
    })
  }
}

const mutations = {
  [SET_DEFAULT_DASHBOARD](state) {
    state.is_passive = false
    state.dashboard_m_id = null
    state.dashboard_title = ''
    state.description = ''
    state.reports = []
    state.useableReports = []
  },
  [SET_DASHBOARD](state, payload) {
    state.is_passive = payload.is_passive
    state.dashboard_m_id = payload.dashboard_m_id
    state.dashboard_title = payload.dashboard_title
    state.description = payload.description
    state.reports = []
    payload.dashboard_detail.forEach(item => {
      state.reports.push({
        id: item.report_m_id,
        column: item.column,
        uid: Math.random()
          .toString(16)
          .slice(2)
      })
    })
  },
  [SET_USEABLE_REPORTS](state, payload) {
    state.useableReports = []
    payload.forEach(item => {
      state.useableReports.push({
        id: item.report_m_id,
        name: item.report_name,
        desc: item.description,
        type: item.report_type_id
      })
    })
  },
  [DELETE_REPORT](state, payload) {
    state.reports.splice(payload, 1)
  },
  [UPDATE_COLUMN](state, payload) {
    state.reports[payload.index].column = payload.value
  },
  [TOGGLE_DASHBOARD_STATUS](state) {
    state.is_passive = !state.is_passive
  },
  [SET_DASHBOARD_CRUD_TITLE](state, payload) {
    state.dashboard_title = payload
  },
  [SET_DASHBOARD_CRUD_DESCRIPTION](state, payload) {
    state.description = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
