import axiosIns from '@/libs/axios'
import moment from 'moment'
import _ from 'lodash'
import { SET_NOTIFICATION } from './module-service'
import { SET_AMOUNT } from './payment-service'

//Actions
export const GET_ALL_PAYMENT_LINKS = 'getAllPaymentLinks'
export const GET_PAYMENT_LINK = 'getPaymentLink'
export const GENERATE_PAYMENT_LINK = 'generatePaymentLink'
export const CONTROL_PAYMENT_LINK = 'controlPaymentLink'
export const SEND_PAYMENT_LINK = 'sendPaymentLink'

//Mutations
export const DEFAULT_PAYMENT_LINK = 'defaultPaymentLink'
export const MUTATE_ALL_PAYMENT_LINKS = 'mutateAllPaymentLinks'

const default_payment_link = {
  payment_link_m_id: undefined,
  title: '',
  hash: undefined,
  create_date: moment().toISOString(),
  expired_date: moment()
    .add(1, 'days')
    .toISOString(),
  company_id: undefined,
  create_user_id: undefined,
  limit: 1,
  amount: undefined,
  description: '',
  is_passive: false
}

const state = {
  paymentLink: default_payment_link,
  paymentLinks: undefined
}

const getters = {
  paymentLink: state => state.paymentLink,
  paymentLinks: state => state.paymentLinks
}

const actions = {
  [GET_ALL_PAYMENT_LINKS]({ commit }) {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(`Payment/GetPaymentLinks`)
        .then(response => {
          if (response.data.status) {
            commit(MUTATE_ALL_PAYMENT_LINKS, response.data.data)
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
            reject()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_PAYMENT_LINK]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(``)
        .then(response => {
          if (response.data.status) {
            resolve(response.data.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GENERATE_PAYMENT_LINK]({ commit, state, rootGetters }) {
    return new Promise((resolve, reject) => {
      let body = {
        ...state.paymentLink,
        create_user_id: rootGetters.currentUser.user_id,
        company_id: rootGetters.currentUser.company.company_id
      }
      axiosIns
        .post(`Payment/CreatePaymentLink`, body)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          if (response.data.status) {
            resolve()
          } else {
            reject()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [CONTROL_PAYMENT_LINK]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(`Payment/LinkControle?hash=${payload}`)
        .then(response => {
          if (response.data.status) {
            if (response.data.data.amount > 0) {
              commit(SET_AMOUNT, response.data.data.amount)
            }
            resolve(response.data.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
            reject(response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [SEND_PAYMENT_LINK]({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      let body = {
        ...payload,
        send_user_id: rootGetters.currentUser.user_id
      }
      axiosIns
        .post(`Payment/PaymentLinkSend`, body)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          if (response.data.status) {
            resolve()
          } else {
            reject()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  }
}

const mutations = {
  [DEFAULT_PAYMENT_LINK](state) {
    state.paymentLink = default_payment_link
  },
  [MUTATE_ALL_PAYMENT_LINKS](state, payload) {
    if (_.isArray(payload)) {
      payload.forEach(item => {
        item.company = JSON.parse(item.company)[0]
        item.user = JSON.parse(item.user)[0]
      })
    } else {
      payload = undefined
    }
    state.paymentLinks = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
