export const SET_BREADCRUMB = "setBreadcrumb";
export const PUSH_BREADCRUMB = "pushBreadcrumb";
export const POP_BREADCRUMB = "popBreadcrumb";

const state = {
  breadcrumbs: [],
};

const getters = {
  breadcrumbs: (state) => state.breadcrumbs,
};

const actions = {};

const mutations = {
  [SET_BREADCRUMB](state, payload) {
    state.breadcrumbs = [];
    if (payload?.breadcrumb?.length) {
      state.breadcrumbs = [...payload.breadcrumb];
    }
  },
  [PUSH_BREADCRUMB](state, payload) {
    //state.breadcrumbs[state.breadcrumbs.length - 1].active = false
    state.breadcrumbs.push({
      text: payload,
      active: true,
      notTranslate: true,
    });
  },
  [POP_BREADCRUMB](state) {
    state.breadcrumbs.pop();
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
