import axiosIns from '@/libs/axios'
import { SET_NOTIFICATION } from './module-service'

//Actions
export const GET_ORDER_DETAIL = 'getOrderDetail'
export const GET_ORDERS = 'getOrders'
export const GET_SPECIAL_CODES = 'getSpecialCodes'
export const GET_DOC_TRA_CODES = 'getDocTraCodes'
export const GET_WHOUSE_CODES = 'getWhouseCodes'
export const CONFIRM_ORDER = 'confirmOrder'
export const CANCEL_ORDER = 'cancelOrder'

//mutations
export const SET_DEFAULT_ORDER = 'setDefaultOrder'
export const SET_ORDER = 'setOrder'
export const SET_ORDERS = 'setOrders'

const state = {
  orderDetail: {
    details: {},
    invoiceAddress: {
      address_line1: '',
      address_line2: '',
      address_line3: '',
      post_code: '',
      town: {
        town_name: ''
      },
      city: {
        city_name: ''
      },
      country: {
        country_name: ''
      },
      phone: '',
      e_mail: ''
    },
    giverAddress: {},
    customerAddress: {},
    lastTransaction: {}
  },
  orderUpdate: {
    //YYYY-MM-DD
    doc_tra_code: undefined,
    w_house_code: undefined,
    due_day: 0,
    due_date: undefined,
    description: '',
    PurchaseSales: '',
    SourceApp: '',
    SourceApp2: '',
    UserCode: ''
  }
}

const getters = {
  orderDetail: state => state.orderDetail,
  orderUpdateDetail: state => state.orderUpdate
}

const actions = {
  [GET_ORDERS]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Order/GetOrders?startDate=${payload.startDate}&endDate=${payload.endDate}`)
        .then(response => {
          if (response.data.status) {
            commit(SET_ORDERS, response.data.data)
            resolve(response.data.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },

  [GET_ORDER_DETAIL]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(`Order/GetOrder?order_m_id=${payload}`)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          if (response.data.status) {
            if (response.data.data) {
              commit(SET_ORDER, response.data.data)
              resolve()
            } else {
              reject()
            }
          } else {
            reject()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_DOC_TRA_CODES]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post(`Order/GetDocTraCode`)
        .then(response => {
          if (response.data.status) {
            resolve(response.data.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_WHOUSE_CODES]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post(`Order/GetWhouseCode`)
        .then(response => {
          if (response.data.status) {
            resolve(response.data.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [CONFIRM_ORDER]({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      let body = {
        order_m_id: payload,
        DocTraCode: state.orderUpdate.doc_tra_code,
        DueDay: state.orderUpdate.due_day,
        DueDate: state.orderUpdate.due_date,
        OrderNote: state.orderUpdate.description,
        PurchaseSales: state.orderUpdate.PurchaseSales,
        SourceApp: state.orderUpdate.SourceApp,
        SourceApp2: state.orderUpdate.SourceApp2,
        UserCode: state.orderUpdate.UserCode,
        WhouseCode: state.orderUpdate.w_house_code
      }
      axiosIns
        .post(`Erp/SaveOrderErp`, body)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          if (response.data.status) {
            resolve()
          } else {
            reject()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [CANCEL_ORDER]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(`Order/OrderCancel?order_m_id=${payload}`)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          if (response.data.status) {
            resolve()
          } else {
            reject()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  }
}

const mutations = {
  [SET_DEFAULT_ORDER](state, payload) {
    state.orderDetail = {
      details: {},
      invoiceAddress: {
        address_line1: '',
        address_line2: '',
        address_line3: '',
        post_code: '',
        town: {
          town_name: ''
        },
        city: {
          city_name: ''
        },
        country: {
          country_name: ''
        },
        phone: '',
        e_mail: ''
      },
      giverAddress: {},
      customerAddress: {},
      lastTransaction: {}
    }

    state.orderUpdate = {
      doc_tra_code: undefined,
      w_house_code: undefined,
      due_day: 0,
      due_date: undefined,
      description: '',
      PurchaseSales: '',
      SourceApp: '',
      SourceApp2: '',
      UserCode: ''
    }
  },
  [SET_ORDER](state, payload) {
    state.orderDetail.details = payload
    state.orderDetail.currencyList = JSON.parse(payload.currency_json)

    if (payload.transaction_master !== null && payload.payment_type === 1) {
      let temp = payload.transaction_master.transaction_detail.length
      if (temp > 0) {
        state.orderDetail.lastTransaction = payload.transaction_master.transaction_detail[temp - 1]
      }
    }

    if (payload?.taker_company?.company_address?.length > 0) {
      state.orderDetail.invoiceAddress = payload.taker_company.company_address[0]
    }
    state.orderDetail.giverAddress = payload.giver_company.company_address[0]
    state.orderDetail.customerAddress = payload.company_address
    state.orderDetail.ready = true

    state.orderDetail.shippingAddress = JSON.parse(payload.shipping_address_json ?? '[{}]')[0]
  },
  [SET_ORDERS](state, payload) {
    payload.forEach(item => {
      item.company = JSON.parse(item.company_json)[0]
      item.shipping_address = JSON.parse(item.shipping_address_json)[0]
      item.user = JSON.parse(item.user_json)[0]
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
