export default [
  {
    path: '/Shop',
    name: '/Shop/Shop',
    component: () => import('@/views/Order/Shop/Index.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      title: 'Shop',
      pageTitle: 'Order Management',
      breadcrumb: [
        {
          text: 'Shop',
          active: true
        }
      ],
      needRegister: true,
      action: '/Shop/Shop'
    }
  },
  {
    path: '/Wishlist',
    name: '/Shop/Wishlist',
    redirect: to => {
      return { name: 'coming-soon' }
    }
    /*component: () => import('@/views/Order/Wishlist/Index.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      title: 'Wishlist',
      pageTitle: 'Order Management',
      breadcrumb: [
        {
          text: 'Wishlist',
          active: true,
        }
      ],
      needRegister: true,
      //action: '/Shop/Wishlist'
    },*/
  },
  {
    path: '/Checkout',
    name: '/Shop/Checkout',
    component: () => import('@/views/Order/Checkout/Index.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      title: 'Checkout',
      pageTitle: 'Order Management',
      breadcrumb: [
        {
          text: 'Checkout',
          active: true
        }
      ],
      needRegister: true
    }
  },
  {
    path: '/Checkout/Error/:id',
    name: 'CheckoutError',
    component: () => import('@/views/Order/Checkout/Index.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      title: 'Checkout',
      pageTitle: 'Order Management',
      breadcrumb: [
        {
          text: 'Checkout',
          active: true
        }
      ],
      needRegister: true
    }
  },
  {
    path: '/Order/List',
    name: '/Shop/Orders',
    component: () => import('@/views/Order/List/Index.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      title: 'Orders',
      pageTitle: 'Order Management',
      breadcrumb: [
        {
          text: 'Orders',
          active: true
        }
      ],
      needRegister: true,
      action: '/Shop/Orders'
    }
  },
  {
    path: '/Order/Detail/:id',
    name: '/Shop/OrderDetail',
    component: () => import('@/views/Order/Detail/Index.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      title: 'OrderDetail',
      pageTitle: 'Order Management',
      breadcrumb: [
        {
          text: 'Orders',
          to: { name: '/Shop/Orders' }
        },
        {
          text: 'OrderDetail',
          active: true
        }
      ],
      needRegister: true,
      action: '/Shop/OrderDetails'
    }
  },
  {
    path: '/Order/Edit/:id',
    name: '/Order/Edit',
    component: () => import('@/views/Order/Edit/Index.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      title: 'Order Edit',
      pageTitle: 'Order Management',
      breadcrumb: [
        {
          text: 'Order Edit',
          active: true
        }
      ],
      needRegister: true,
      action: '/Order/Edit'
    }
  },
  {
    path: '/Order/Thanks/:id',
    name: '/Shop/OrderThanks',
    component: () => import('@/views/Order/Checkout/OrderThanks.vue'),
    meta: {
      title: 'Order Thanks',
      layout: 'full',
      needRegister: true
    }
  }
]
