//import moment from 'moment'
import axiosIns from '@/libs/axios'
import router from '@/router'
//import i18n from '@/libs/i18n'

import { SET_NOTIFICATION } from './module-service'

//moment().locale()

//Actions
export const GET_BANKS = 'getBanks'
export const GET_INSTALLMENTS = 'getInstallments'
export const PAYMENT_SALE = 'paymentSale'
export const PAYMENT_LINK_SALE = 'paymentLinkSale'
export const GET_TRANSACTIONS = 'getTransactions'
export const GET_INSTALLMENTS_FOR_LINK_PAY = 'getInstallmentsForLinkPay'
export const CHANGE_CARD_NUMBER = 'changeCardNumber'
export const CHANGE_CARD_NUMBER_FOR_LINK_PAY = 'changeCardNumberForLinkPay'
export const GET_TRANSACTION_DETAIL = 'getTransactionDetail'
export const GET_TRANSACTION_DETAIL_FOR_LINK_PAY = 'getTransactionDetailForLinkPay'
export const CONFIRM_PAYMENT = 'confirmPayment'
export const CANCEL_PAYMENT = 'cancelPayment'
export const RETURN_PAYMENT = 'returnPayment'

//Mutations
export const SET_DEFAULT_PAYMENT_DATA = 'setDefaultPaymentData'
export const SET_CARD_NAME = 'setCardName'
export const SET_CARD_NUMBER = 'setCardNumber'
export const SET_CARD_MONTH = 'setCardMonth'
export const SET_CARD_YEAR = 'setCardYear'
export const SET_CARD_CVV = 'setCardCvv'
export const SET_BANKS = 'setBanks'
export const SET_INSTALLMENT_OPTIONS = 'setInstallmentOptions'
export const MASK_CARD_NUMBER = 'maskCardNumber'
export const UNMASK_CARD_NUMBER = 'unmaskCardNumber'
export const SET_AMOUNT = 'setAmount'
export const SET_INSTALLMENT = 'setInstallment'
export const SET_TRANSACTION_DETAIL = 'setTransactionDetail'

const state = {
  paymentData: {
    cardName: '',
    cardNumber: '',
    cardNumberReal: '',
    cardMonth: '',
    cardYear: '',
    cardCvv: '',
    description: '',
    amount: 0,
    bank: '',
    gateway: ''
  },
  banks: [],
  installment: null,
  installmentOptions: []
}

const getters = {
  paymentData: state => state.paymentData,
  banks: state => state.banks,
  installment: state => state.installment,
  installmentOptions: state => state.installmentOptions
}

const actions = {
  [GET_BANKS]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('Payment/GetBanks')
        .then(response => {
          if (response.data.status) {
            commit(SET_BANKS, response.data.data)
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },

  [GET_INSTALLMENTS]({ state, commit }) {
    return new Promise(resolve => {
      axiosIns
        .post(`Payment/GetCreditCardGetInstallments?CardNumber=${state.paymentData.cardNumberReal.split(' ').join('')}`)
        .then(response => {
          if (response.data.status) {
            commit(SET_INSTALLMENT_OPTIONS, response.data.data)
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },

  [GET_INSTALLMENTS_FOR_LINK_PAY]({ state, commit }) {
    return new Promise(resolve => {
      axiosIns
        .post(`Payment/GetLinkCreditCardGetInstallments?CardNumber=${state.paymentData.cardNumberReal.split(' ').join('')}`)
        .then(response => {
          if (response.data.status) {
            commit(SET_INSTALLMENT_OPTIONS, response.data.data)
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },

  [GET_TRANSACTIONS]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Payment/GetTransactions?startDate=${payload.startDate}&endDate=${payload.endDate}`)
        .then(response => {
          if (response.data.status) {
            resolve(response.data.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },

  [CHANGE_CARD_NUMBER]({ state, commit, dispatch }, payload) {
    return new Promise(resolve => {
      let checkDelete = payload.length < state.paymentData.cardNumber.length
      let value = payload.replace(/\D/g, '')
      let maxLength = 19
      // american express, 15 digits
      if (/^3[47]\d{0,13}$/.test(value)) {
        commit(SET_CARD_NUMBER, value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 '))

        maxLength = 17
      } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
        // diner's club, 14 digits
        commit(SET_CARD_NUMBER, value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 '))

        maxLength = 16
      } else if (/^\d{0,16}$/.test(value)) {
        // regular cc number, 16 digits
        commit(
          SET_CARD_NUMBER,
          value
            .replace(/(\d{4})/, '$1 ')
            .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
            .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ')
        )

        maxLength = 19
      }
      // eslint-disable-next-line eqeqeq
      if (checkDelete) {
        let lastChar = state.paymentData.cardNumber.substring(state.paymentData.cardNumber.length, state.paymentData.cardNumber.length - 1)
        // eslint-disable-next-line eqeqeq
        if (lastChar == ' ') {
          commit(SET_CARD_NUMBER, state.paymentData.cardNumber.substring(0, state.paymentData.cardNumber.length - 1))
        }
      }
      if (state.paymentData.cardNumberReal.length === maxLength) {
        dispatch(GET_INSTALLMENTS)
      } else {
        commit(SET_INSTALLMENT_OPTIONS, { bank: '', gateway: '', installments: [] })
      }
      resolve(maxLength)
    })
  },

  [CHANGE_CARD_NUMBER_FOR_LINK_PAY]({ state, commit, dispatch }, payload) {
    return new Promise(resolve => {
      let checkDelete = payload.length < state.paymentData.cardNumber.length
      let value = payload.replace(/\D/g, '')
      let maxLength = 19
      // american express, 15 digits
      if (/^3[47]\d{0,13}$/.test(value)) {
        commit(SET_CARD_NUMBER, value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 '))

        maxLength = 17
      } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
        // diner's club, 14 digits
        commit(SET_CARD_NUMBER, value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 '))

        maxLength = 16
      } else if (/^\d{0,16}$/.test(value)) {
        // regular cc number, 16 digits
        commit(
          SET_CARD_NUMBER,
          value
            .replace(/(\d{4})/, '$1 ')
            .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
            .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ')
        )

        maxLength = 19
      }
      // eslint-disable-next-line eqeqeq
      if (checkDelete) {
        let lastChar = state.paymentData.cardNumber.substring(state.paymentData.cardNumber.length, state.paymentData.cardNumber.length - 1)
        // eslint-disable-next-line eqeqeq
        if (lastChar == ' ') {
          commit(SET_CARD_NUMBER, state.paymentData.cardNumber.substring(0, state.paymentData.cardNumber.length - 1))
        }
      }
      if (state.paymentData.cardNumberReal.length === maxLength) {
        dispatch(GET_INSTALLMENTS_FOR_LINK_PAY)
      } else {
        commit(SET_INSTALLMENT_OPTIONS, { bank: '', gateway: '', installments: [] })
      }
      resolve(maxLength)
    })
  },

  [PAYMENT_SALE]({ state, commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      let ins = { ...state.installmentOptions.find(x => x.count === state.installment) }
      let camps = {}
      if (ins.campaigns.length > 0) {
        camps = ins.campaigns[0]
      }
      let currentUser = { ...rootGetters.currentUser }
      let body = {
        total: state.paymentData.amount,
        cc_name: state.paymentData.cardName,
        cc_number: state.paymentData.cardNumberReal.split(' ').join(''),
        cc_month: state.paymentData.cardMonth.toString(),
        cc_year: state.paymentData.cardYear.toString(),
        cc_cvc: state.paymentData.cardCvv,
        installments: state.installment,
        campaign_id: 'campaign_id' in camps ? camps.campaign_id : '',
        extra_installment_count: 'extra_installments' in camps ? camps.extra_installments : '',
        bank_id: state.paymentData.bank,
        gateway: state.paymentData.gateway,
        language: 'tr',
        currency: 'TRY',
        use3d: '1',
        client_ip: rootGetters.ipAddress,
        customer_firstname: currentUser.name,
        customer_lastname: currentUser.surname,
        customer_email: currentUser.email,
        customer_phone: currentUser.gsm_number,
        payment_title: 'Cari Ödeme',
        passive_data: '',
        description: state.paymentData.description,
        user_id: currentUser.user_id,
        return_url: `${window.location.origin}${router
          .resolve({
            name: '/Payment/Thanks',
            params: { id: '1' }
          })
          .href.replace('1', '')}`
      }
      axiosIns
        .post(`Payment/Sale`, body)
        .then(response => {
          if (response.data.status) {
            if (response.data.data?.status === false || response.data.data?.status === 0) {
              reject(response.data.data)
            } else {
              resolve(response.data.data)
            }
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },

  [PAYMENT_LINK_SALE]({ state, commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      let ins = { ...state.installmentOptions.find(x => x.count === state.installment) }
      let camps = {}
      if (ins.campaigns.length > 0) {
        camps = ins.campaigns[0]
      }
      let body = {
        hash: payload,
        total: state.paymentData.amount,
        cc_name: state.paymentData.cardName,
        cc_number: state.paymentData.cardNumberReal.split(' ').join(''),
        cc_month: state.paymentData.cardMonth.toString(),
        cc_year: state.paymentData.cardYear.toString(),
        cc_cvc: state.paymentData.cardCvv,
        installments: state.installment,
        campaign_id: 'campaign_id' in camps ? camps.campaign_id : '',
        extra_installment_count: 'extra_installments' in camps ? camps.extra_installments : '',
        bank_id: state.paymentData.bank,
        gateway: state.paymentData.gateway,
        language: 'tr',
        currency: 'TRY',
        use3d: '1',
        client_ip: rootGetters.ipAddress,
        payment_title: 'Cari Ödeme',
        passive_data: '',
        description: state.paymentData.description,
        return_url: `${window.location.origin}${router
          .resolve({
            name: 'PayWithToken/Detail',
            params: { id: '1', hash: payload }
          })
          .href.replaceAll('1', '')}`
      }
      axiosIns
        .post(`Payment/LinkSale`, body)
        .then(response => {
          if (response.data.status) {
            if (response.data.data?.status === false || response.data.data?.status === 0) {
              reject(response.data.data)
            } else {
              resolve(response.data.data)
            }
          } else {
            reject({ ErrorMSG: response.data.message })
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },

  [GET_TRANSACTION_DETAIL]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Payment/GetTransaction?transaction_id=${payload}`)
        .then(response => {
          if (response.data.status) {
            commit(SET_TRANSACTION_DETAIL, response.data.data[0])
            resolve(response.data.data[0])
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },

  [GET_TRANSACTION_DETAIL_FOR_LINK_PAY]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Payment/GetPaymentLinkTransaction?transaction_id=${payload.transaction_id}&token=${payload.hash}`)
        .then(response => {
          if (response.data.status) {
            commit(SET_TRANSACTION_DETAIL, response.data.data[0])
            resolve(response.data.data[0])
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },

  [CONFIRM_PAYMENT]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(`Erp/SavePaymentErp`, payload)
        .then(response => {
          if (response.data.status) {
            commit(SET_NOTIFICATION, {
              show: true,
              type: 2,
              title: response.data.title,
              message: response.data.message,
              icon: 'success'
            })
            resolve()
          } else {
            commit(SET_NOTIFICATION, {
              show: true,
              type: 2,
              title: response.data.title,
              message: response.data.data.ErrorMSG,
              icon: 'warning'
            })
            reject()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },

  [CANCEL_PAYMENT]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(`Payment/Cancel?transaction_id=${payload}`)
        .then(response => {
          if (response.data.status && response.data?.data?.status) {
            commit(SET_NOTIFICATION, {
              show: true,
              type: 2,
              title: response.data.title,
              message: response.data.message,
              icon: 'success'
            })
            resolve(response.data.data.transaction_id)
          } else {
            commit(SET_NOTIFICATION, {
              show: true,
              type: 2,
              title: response.data.title,
              message: response.data.data.ErrorMSG,
              icon: 'warning'
            })
            reject()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },

  [RETURN_PAYMENT]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(`Payment/Return?transaction_id=${payload}`)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          if (response.data.status && response.data?.data?.status) {
            commit(SET_NOTIFICATION, {
              show: true,
              type: 2,
              title: response.data.title,
              message: response.data.message,
              icon: 'success'
            })
            resolve(response.data.data.transaction_id)
          } else {
            commit(SET_NOTIFICATION, {
              show: true,
              type: 2,
              title: response.data.title,
              message: response.data.data.ErrorMSG,
              icon: 'warning'
            })
            reject()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  }
}

const mutations = {
  [SET_DEFAULT_PAYMENT_DATA](state) {
    state.paymentData = {
      cardName: '',
      cardNumber: '',
      cardNumberReal: '',
      cardMonth: '',
      cardYear: '',
      cardCvv: '',
      description: '',
      amount: 0,
      bank: '',
      gateway: ''
    }
    state.installment = null
    state.installmentOptions = []
  },
  [SET_CARD_NAME](state, payload) {
    state.paymentData.cardName = payload
  },
  [SET_CARD_NUMBER](state, payload) {
    state.paymentData.cardNumber = payload
    state.paymentData.cardNumberReal = payload
  },
  [SET_CARD_MONTH](state, payload) {
    state.paymentData.cardMonth = payload.cardMonth
  },
  [SET_CARD_YEAR](state, payload) {
    state.paymentData.cardYear = payload.cardYear
    if (this.paymentData.cardMonth < payload.minCardMonth) {
      this.paymentData.cardMonth = ''
    }
  },
  [SET_CARD_CVV](state, payload) {
    state.paymentData.cardCvv = payload
  },
  [SET_BANKS](state, payload) {
    payload.forEach(item => {
      if (state.banks.findIndex(x => x.bank === item.bank) === -1) {
        state.banks.push(item)
      }
    })
  },
  [MASK_CARD_NUMBER](state) {
    state.paymentData.cardNumberReal = state.paymentData.cardNumber
    let arr = state.paymentData.cardNumber.split('')
    arr.forEach((element, index) => {
      if (index > 4 && index < 14 && element.trim() !== '') {
        arr[index] = '*'
      }
    })
    state.paymentData.cardNumber = arr.join('')
  },
  [UNMASK_CARD_NUMBER](state) {
    state.paymentData.cardNumber = state.paymentData.cardNumberReal
  },
  [SET_INSTALLMENT_OPTIONS](state, payload) {
    if (payload) {
      state.paymentData.bank = payload.bank
      state.paymentData.gateway = payload.gateway
      state.installmentOptions = payload.installments

      if (state.installmentOptions.length === 0) {
        state.installmentOptions.push({
          count: '1',
          commission: '0%',
          minAmount: null,
          campaigns: []
        })
      }
    } else {
      state.paymentData.bank = ''
      state.paymentData.gateway = ''
      state.installmentOptions = [
        {
          count: '1',
          commission: '0%',
          minAmount: null,
          campaigns: []
        }
      ]
    }
  },
  [SET_AMOUNT](state, payload) {
    state.paymentData.amount = payload
    state.installment = null
  },
  [SET_INSTALLMENT](state, payload) {
    state.installment = payload
  },
  [SET_TRANSACTION_DETAIL](state, payload) {
    payload.company_json = JSON.parse(payload.company)[0]
    payload.master_company_json = JSON.parse(payload.master_company)[0]
    payload.currencyList = JSON.parse(payload.currency_json)
    if (payload.order_json) {
      payload.orderDetail = JSON.parse(payload.order_json)[0]
    }
    payload.payment_detail = [
      {
        cc_holder: payload.credit_card_holder,
        cc_no: payload.credit_card_no,
        ins: payload.installment_count,
        e_ins: payload.extra_installment_count,
        com: payload.commission,
        total: payload.total_amount
      }
    ]
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
