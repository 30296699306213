import Vue from 'vue'

// axios
import axios from 'axios'
import store from '@/store'
import { PURGE_AUTH } from '@/store/services/auth-service'
import authKeys from '@/auth/authKeys'
import i18n from './i18n'
import { SET_NOTIFICATION } from '@/store/services/module-service'

const axiosIns = axios.create({
  baseURL: `https://${process.env.VUE_APP_API_HOST}/`,
  // headers: {'Authorization': 'Bearer'}
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  timeout: 60000
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

axiosIns.interceptors.response.use(
  response => response,
  error => {
    console.log('HATA KODU', error.response.status, error.response)
    switch (error.response.status) {
      case 401: // 401 hatası aldığında otomatik logout yaptır
        let auth = authKeys.find(x => {
          let hash = x.hash.replaceAll('/', '').toLowerCase()
          let url = error.response.config.url.replaceAll('/', '').toLowerCase()
          return hash === url
        })
        store.commit(SET_NOTIFICATION, {
          show: true,
          type: 2,
          title: 'Yetkisiz Erişim',
          html: 'Aşağıdaki servise yetkiniz bulunmamaktadır.<br/><br/>' + i18n?.t(auth.method_name),
          icon: 'error'
        })
        break
      case 204:
        console.log('TOKENIN SÜRESİ BİTTİ!')
        store.commit(PURGE_AUTH)
        break
      default:
        break
    }
  }
)

export default axiosIns
