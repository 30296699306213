export default [
  {
    path: '/Payment/Pay',
    name: '/Payment/Pay',
    component: () => import('@/views/Payment/Pay/Index.vue'),
    meta: {
      title: 'Pay',
      pageTitle: 'Payment Management',
      breadcrumb: [
        {
          text: 'Pay',
          active: true,
        },

      ],
      needRegister: true,
      action: '/Payment/Pay'
    },
  },
  {
    path: '/Payment/List',
    name: '/Payment/List',
    component: () => import('@/views/Payment/List/Index.vue'),
    meta: {
      title: 'Payments',
      pageTitle: 'Payment Management',
      breadcrumb: [
        {
          text: 'Payments',
          active: true,
        },

      ],
      needRegister: true,
      action: '/Payment/List'
    },
  },
  {
    path: '/Payment/Transaction/Detail/:id',
    name: '/Payment/TransactionDetail',
    component: () => import('@/views/Payment/TransactionDetail/Index.vue'),
    meta: {
      title: 'TransactionDetail',
      pageTitle: 'Payment Management',
      breadcrumb: [
        {
          text: "Payments",
          to: { name: "/Payment/List" },
        },
        {
          text: 'TransactionDetail',
          active: true,
        },

      ],
      needRegister: true,
      action: '/Payment/TransactionDetail'
    },
  },
  {
    path: '/Payment/Links',
    name: '/Payment/PaymentLinks',
    component: () => import('@/views/Payment/PaymentLinks/Index.vue'),
    meta: {
      title: 'PaymentLinks',
      pageTitle: 'Payment Management',
      breadcrumb: [
        {
          text: 'PaymentLinks',
          active: true,
        },

      ],
      needRegister: true,
      action: '/Payment/PaymentLinks'
    },
  },
  {
    path: '/PayWithToken/:hash',
    name: 'PayWithToken',
    component: () => import('@/views/Payment/PayWithToken/Index.vue'),
    meta: {
      title: 'PayWithToken',
      layout: 'full',
      needRegister: false,
    },
  },
  {
    path: '/PayWithToken/Detail/:hash/:id',
    name: 'PayWithToken/Detail',
    component: () => import('@/views/Payment/PayWithToken/Detail.vue'),
    meta: {
      title: 'PayWithTokenDetail',
      layout: 'full',
      needRegister: false,
    },
  },
  {
    path: '/Payment/Thanks/:id',
    name: '/Payment/Thanks',
    component: () => import('@/views/Payment/Pay/PaymentThanks.vue'),
    meta: {
      title: 'Payment Thanks',
      layout: 'full',
      needRegister: true
    }
  }
]