import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import auth from './routes/auth'
import report from './routes/report'
import dashboard from './routes/dashboard'
import user from './routes/user'
import payment from './routes/payment'
import company from './routes/company'
import order from './routes/order'
import product from './routes/product'

import authKeys from '@/auth/authKeys'

import { canNavigate } from '@/libs/acl/routeProtection'
import { REFRESH_TOKEN } from '@/store/services/auth-service'
import { SET_PERMS_READY } from '@/store/services/module-service'

const checkAuth = (to, from, next) => {
  let auth = store.getters.isAuthenticated
  if (auth) {
    return next({ name: 'home' })
  } else {
    return next({ name: 'login' })
  }
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'root',
      beforeEnter: checkAuth,
      needRegister: false
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home/Index.vue'),
      meta: {
        title: 'Home',
        pageTitle: 'Home',
        needRegister: true
        //action: 'Home'
      }
    },
    {
      path: '/PageNotFound',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        title: 'Page Not Found',
        layout: 'full',
        needRegister: false
      }
    },
    {
      path: '*',
      redirect: to => {
        return { name: 'error-404' }
      }
    },
    {
      path: '/NotAuthorized/:id?',
      name: 'misc-not-authorized',
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {
        title: 'Not Authorized',
        layout: 'full',
        resource: 'Auth',
        needRegister: true
      }
    },
    {
      path: '/ComingSoon',
      name: 'coming-soon',
      component: () => import('@/views/error/ComingSoon.vue'),
      meta: {
        title: 'Coming Soon',
        layout: 'full',
        needRegister: false
      }
    },
    ...auth,
    ...report,
    ...dashboard,
    ...user,
    ...payment,
    ...company,
    ...order,
    ...product
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.needRegister) {
    if (store.getters.isAuthenticated && store.getters.currentUser.name !== '') {
      if (!canNavigate(to) && to.meta?.action) {
        let authID = authKeys.find(x => x.hash === to.name)?.method_id
        // If logged in => not authorized
        return next({ name: 'misc-not-authorized', params: { id: authID } })
      } else {
        return next()
      }
    } else {
      store.dispatch(REFRESH_TOKEN).then(() => {
        if (store.getters.isAuthenticated) {
          if (!canNavigate(to) && to.meta?.action) {
            let authID = authKeys.find(x => x.hash === to.name)?.method_id
            // If logged in => not authorized
            return next({ name: 'misc-not-authorized', params: { id: authID } })
          } else {
            return next()
          }
        } else {
          return next({ name: 'login' })
        }
      })
    }
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(to => {
  if (!to.meta.needRegister && store.getters.systemSettings !== undefined) {
    store.commit(SET_PERMS_READY, true)
  }
  // Remove initial loading
  /*const appLoading = document.getElementById('loading-bg')
  if (appLoading)
  {
    appLoading.style.display = 'none'
  }*/
})

export default router
