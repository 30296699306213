import { TOGGLE_DATE } from "./report-crud"
import { GET_FILTER } from "./report-filters"

//Actions
export const GET_PROCESS_FILTER = 'getProcessFilter'
export const CHANGE_FORMAT = 'changeFormat'

//Mutations
export const DEFAULT_FILTER = 'defaultFilter'
export const DEFAULT_SELECT_VARS = 'defaultSelectVars'
export const DEFAULT_DATE_VARS = 'defaultDateVars'
export const SET_FILTER = 'setFilter'
export const SET_VALUE = 'setValue'
export const SET_NAME = 'setName'
export const SET_TYPE = 'setType'
export const SET_OPTION = 'setOption'
export const SET_FORMAT = 'setFormat'
export const TOGGLE_SQL_IS_RUN = 'toggleSqlIsRun'
export const SET_SELECT_OPTION = 'setSelectOption'
export const ADD_SELECT_OPTION = 'addSelectOption'
export const DELETE_SELECT_OPTION = 'deleteSelectOption'

const state = {
  name: '',
  value: null,
  type: null,
  option: null,
  colSize: 6,
  SQLVariable: '',
  SQLReplaceType: 'pure',
  SQLIsRun: false,
  SQLDataBase: null,
  SQLQuery: 'SELECT id,label FROM conditions...',
  //SQLQuery: "SELECT user_id,CONCAT(name,' ',surname) FROM gnl_user",
  selectOptions: [{ id: '',label: '' }],
  dateFormat: null
}

const getters = {
  processFilter: state => state,
  selectOptions: state => state.selectOptions
}

const actions = {
  [GET_PROCESS_FILTER]({ commit,dispatch },payload)
  {
    return new Promise(async (resolve) =>
    {
      const filter = await (dispatch(GET_FILTER,payload))
      commit(SET_FILTER,filter)
      resolve(filter)
    })
  },
  [CHANGE_FORMAT]({ commit },payload)
  {
    return new Promise(resolve =>
    {
      commit(TOGGLE_DATE,false)
      resolve()
    }).then(commit(SET_FORMAT,payload))
      .then(commit(TOGGLE_DATE,true))
  },
}

const mutations = {
  [DEFAULT_FILTER](state)
  {
    state.name = ''
    state.value = null
    state.type = null
    state.option = null
    state.colSize = 6
    state.SQLVariable = ''
    state.SQLReplaceType = 'pure'
    state.SQLIsRun = false
    state.SQLDataBase = null
    state.SQLQuery = 'SELECT id,label FROM conditions...'
    //state.SQLQuery = "SELECT user_id,CONCAT(name,' ',surname) FROM gnl_user"
    state.selectOptions = [{ id: '',label: '' }]
    state.dateFormat = null
  },
  [SET_FILTER](state,payload)
  {
    state.name = payload.name
    state.colSize = payload.colSize
    state.SQLVariable = payload.SQLVariable
    state.SQLReplaceType = payload.SQLReplaceType
    state.SQLIsRun = payload.SQLIsRun
    state.SQLDataBase = payload.SQLDataBase
    state.SQLQuery = payload.SQLQuery
    state.selectOptions = payload.selectOptions
    state.dateFormat = payload.dateFormat
  },
  [SET_VALUE](state,payload)
  {
    state.value = payload
  },
  [SET_TYPE](state,payload)
  {
    state.type = payload
    state.option = null
    state.value = null
  },
  [SET_OPTION](state,payload)
  {
    state.option = payload
    state.value = null
  },
  [SET_FORMAT](state,payload)
  {
    state.dateFormat = payload
    state.value = null
  },
  [TOGGLE_SQL_IS_RUN](state)
  {
    state.SQLIsRun = !state.SQLIsRun
  },
  [SET_SELECT_OPTION](state,payload)
  {
    state.selectOptions = payload
  },
  [ADD_SELECT_OPTION](state)
  {
    state.selectOptions.push({ id: '',label: '' })
  },
  [DELETE_SELECT_OPTION](state,payload)
  {
    let check = state.selectOptions[payload].id
    if (state.option == 'true')
    {
      let tempFind = state.value.findIndex(x => x == check)
      if (tempFind != -1)
      {
        state.value.splice(tempFind,1)
      }
    }
    else
    {
      if (state.value == check)
      {
        state.value = null
      }
    }
    state.selectOptions.splice(payload,1)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}