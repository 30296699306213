import store from '@/store/index'

const checkAuthLogin = (to,from,next) =>
{
  let auth = store.getters.isAuthenticated;
  if (auth)
  {
    return next({ name: 'home' });
  }
  else
  {
    return next();
  }
}

export default [
  {
    path: '/Login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    beforeEnter: checkAuthLogin,
    meta: {
      title: 'Login',
      layout: 'full',
      needRegister: false,
    },
  },
  {
    path: '/ForgotPassword',
    name: 'forgot-password',
    component: () => import('@/views/auth/Forgot.vue'),
    beforeEnter: checkAuthLogin,
    meta: {
      title: 'Forgot',
      layout: 'full',
      needRegister: false,
    },
  },
]