function priceListChangePercentagle(product_ref) {

  if (product_ref.c_percentagle_str === "") {
    product_ref.c_percentagle = 0;
    product_ref.c_percentagle_str = 0
  }

  let temp = typeof product_ref.c_percentagle_str === 'number' ? product_ref.c_percentagle_str : product_ref.c_percentagle_str.replace(',','.') * 1

  product_ref.c_percentagle = temp

  if (product_ref.c_percentagle === 0 && product_ref.c_amount > 0) {
    return;
  }

  if (product_ref.c_percentagle === 0 && product_ref.c_amount === 0) {
    product_ref.new_amount = product_ref.p_price;
    return;
  }

  if (product_ref.c_percentagle > 0) {
    product_ref.c_amount = 0;
    product_ref.c_amount_str = 0;
  }

  if (product_ref.c_percentagle > 100) {
    product_ref.c_percentagle = 100;
    product_ref.c_percentagle_str = 100;
  }

  product_ref.new_amount = calculateRulePrice(product_ref);
}

function priceListChangeAmount(product_ref) {
  if (product_ref.c_amount_str === "") {
    product_ref.c_amount = 0;
    product_ref.c_amount_str = 0;
  }

  let temp = typeof product_ref.c_amount_str === 'number' ? product_ref.c_amount_str : product_ref.c_amount_str.replace(',','.') * 1

  product_ref.c_amount = temp

  if (product_ref.c_amount === 0 && product_ref.c_percentagle > 0) {
    return;
  }

  if (product_ref.c_percentagle === 0 && product_ref.c_amount === 0) {
    product_ref.new_amount = product_ref.p_price;
    return;
  }

  if (product_ref.c_amount > 0) {
    product_ref.c_percentagle = 0;
    product_ref.c_percentagle_str = 0;
  }

  if (product_ref.c_amount > product_ref.p_price) {
    product_ref.c_amount = product_ref.p_price;
    product_ref.c_amount_str = product_ref.p_price;
  }
    
  product_ref.new_amount = calculateRulePrice(product_ref);
}

function priceListChangeType(product_ref) {
  if (product_ref.c_percentagle > 0) {
    priceListChangePercentagle(product_ref);
  } else {
    priceListChangeAmount(product_ref);
  }
}

function calculateRulePrice(product_ref) {
  let multiplier = product_ref.c_type ? 1 : -1;
  let response = 0;
  if (product_ref.c_amount > 0) {
    response = product_ref.p_price + product_ref.c_amount * multiplier;
  } else {
    response =
      product_ref.p_price +
      (product_ref.p_price * product_ref.c_percentagle * multiplier) / 100;
  }
  return response > 0 ? response : 0;
}

export {
  priceListChangePercentagle,
  priceListChangeAmount,
  priceListChangeType,
  calculateRulePrice,
};
