import axiosIns from '@/libs/axios'
import i18n from '@/libs/i18n'
import { GET_ALL_CITIES, GET_ALL_TOWNS } from './address-service'
import { SET_NOTIFICATION } from './module-service'

//Actions
export const GET_ALL_COMPANY = 'getAllCompany'
export const GET_ALL_SUB_COMPANY = 'getAllSubCompany'
export const GET_COMPANY = 'getCompany'
export const GET_COMPANY_GROUPS = 'getCompanyGroups'
export const SAVE_COMPANY = 'saveCompany'
export const UPDATE_COMPANY = 'updateCompany'
export const DELETE_COMPANY = 'deleteCompany'
export const GET_ALL_TAX_OFFICES = 'getAllTaxOffices'
export const GET_ALL_COMPANY_USERS = 'getAllCompanyUsers'
export const GET_COMPANY_TRANSACTION = 'getCompanyTransaction'
export const GET_BALANCE = 'getBalance'
export const GET_COMPANY_TRANSACTION_LIST = 'getCompanyTransactionList'
export const GET_COMPANY_BALANCE = 'getCompanyBalance'
export const GET_COMPANY_ADDRESSES_BY_ID = 'getCompanyAddressesByID'

//Mutations
export const SET_DEFAULT_COMPANY = 'setDefaultCompany'
export const SET_COMPANY = 'setCompany'
export const SET_COMPANY_GROUP = 'setCompanyGroup'
export const SET_COMPANY_STATE = 'setCompanyState'
export const SET_COMPANY_TYPE = 'setCompanyType'
export const SET_TAX_OFFICES = 'setTaxOffices'
export const SET_COMPANY_DISPLAY_TAB = 'setCompanyDisplayTab'
export const MUTATE_TRANSACTION_LIST = 'mutateTransactionList'

const state = {
  company_id: null,
  company: {
    company_code: null,
    company_name: '',
    company_trade_name: '',
    tax_office_id: null,
    tax_number: '',
    identity_number: '',
    is_passive: false,
    image_base_64: null,
    company_group_id: null,
    company_address: [],
    type: 1
  },
  invoice: {
    e_mail: '',
    phone: '',
    fax: '',
    address_line1: '',
    address_line2: '',
    address_line3: '',
    post_code: '',
    country_id: undefined,
    country_ready: false,
    city_id: undefined,
    city_ready: false,
    town_id: undefined,
    town_ready: false
  },
  tax_offices: [],
  tax_office_ready: false,
  groups: [],
  groups_ready: false,
  companyDisplayTab: 1
}

const getters = {
  company: state => state.company,
  taxOffices: state => state.tax_offices,
  taxOfficesReady: state => state.tax_office_ready,
  companyInvoice: state => state.invoice,
  companyGroups: state => state.groups,
  companyGroupsReady: state => state.groups_ready,
  companyDisplayTab: state => state.companyDisplayTab
}

const actions = {
  [GET_ALL_COMPANY]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('Company/GetAllCompany')
        .then(response => {
          if (response.data.status) {
            resolve(response.data.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_ALL_SUB_COMPANY]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Company/GetAllSubCompany?company_id=${payload}`)
        .then(response => {
          if (response.data.status) {
            resolve(response.data.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_COMPANY]({ commit, dispatch }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Company/GetByIdCompany?company_id=${payload.id}`)
        .then(response => {
          if (response.data.status) {
            if (payload.resolve) {
              resolve(response.data.data)
            } else {
              if (response.data.data.company_address.length > 0) {
                let address = response.data.data.company_address[0]
                dispatch(GET_ALL_CITIES, address.country_id).then(() => {
                  dispatch(GET_ALL_TOWNS, address.city_id).then(() => {
                    commit(SET_COMPANY, response.data.data)
                    resolve()
                  })
                })
              } else {
                commit(SET_COMPANY, response.data.data)
                resolve()
              }
            }
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_COMPANY_GROUPS]({ commit }) {
    return new Promise(resolve => {
      if (!state.groups_ready) {
        axiosIns
          .post(`Company/GetAllCompanyGroup`)
          .then(response => {
            if (response.data.status) {
              commit(SET_COMPANY_GROUP, response.data.data)
            } else {
              commit(SET_NOTIFICATION, response.data)
            }
          })
          .catch(() => {
            commit(SET_NOTIFICATION)
          })
      }
      resolve()
    })
  },
  [SAVE_COMPANY]({ commit, state }) {
    return new Promise(resolve => {
      let company_address = [
        {
          address_type_id: 1,
          address_alias: 'Fatura',
          ...state.invoice
        }
      ]
      let body = {
        company_code: state.company.company_code,
        company_name: state.company.company_name,
        company_trade_name: state.company.company_trade_name,
        tax_office_id: state.company.tax_office_id,
        tax_number: state.company.type === 2 ? state.company.tax_number : '',
        identity_number: state.company.type === 1 ? state.company.identity_number : '',
        is_passive: state.company.is_passive,
        company_group_id: state.company.company_group_id,
        image_base_64: state.company.image_base_64,
        company_address
      }
      axiosIns
        .post(`Company/SaveCompany`, body)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          if (response.data.status) {
            resolve(response.data.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [UPDATE_COMPANY]({ commit, state }) {
    return new Promise(resolve => {
      let body = {
        company_id: state.company_id,
        company_code: state.company.company_code,
        company_name: state.company.company_name,
        company_trade_name: state.company.company_trade_name,
        tax_office_id: state.company.tax_office_id,
        tax_number: state.company.type === 2 ? state.company.tax_number : '',
        identity_number: state.company.type === 1 ? state.company.identity_number : '',
        is_passive: state.company.is_passive,
        company_group_id: state.company.company_group_id,
        image_base_64: state.company.image_base_64,
        company_address: [state.invoice]
      }
      axiosIns
        .post(`Company/UpdateCompany`, body)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          if (response.data.status) {
            resolve(response.data.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [DELETE_COMPANY]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Company/DeleteCompany?company_id=${payload}`)
        .then(response => {
          commit(SET_NOTIFICATION, response.data.data)
          resolve()
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_ALL_TAX_OFFICES]({ commit, state }) {
    return new Promise(resolve => {
      if (!state.tax_office_ready) {
        axiosIns
          .post(`Address/GetAllTaxOffice`)
          .then(response => {
            if (response.data.status) {
              commit(SET_TAX_OFFICES, response.data.data)
            } else {
              commit(SET_NOTIFICATION, response.data)
            }
          })
          .catch(() => {
            commit(SET_NOTIFICATION)
          })
      }
      resolve()
    })
  },
  [GET_ALL_COMPANY_USERS]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Company/GetAllCompanyUser?company_id=${payload}`)
        .then(response => {
          if (response.data.status) {
            resolve(response.data.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_COMPANY_TRANSACTION]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(`Company/GetTransaction`)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          if (response.data.status) {
            commit(MUTATE_TRANSACTION_LIST, response.data.data)
            resolve(response.data.data)
          } else {
            reject()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_BALANCE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(`Company/GetBalance`)
        .then(response => {
          if (response.data.status) {
            if (response.data.data) {
              resolve(response.data.data[0])
            } else {
              reject(false)
            }
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_COMPANY_TRANSACTION_LIST]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Company/CompanyGetTransaction?company_id=${payload}`)
        .then(response => {
          if (response.data.status) {
            commit(MUTATE_TRANSACTION_LIST, response.data.data)
            resolve(response.data.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_COMPANY_BALANCE]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Company/CompanyGetBalance?company_id=${payload}`)
        .then(response => {
          if (response.data.status) {
            resolve(response.data.data[0])
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_COMPANY_ADDRESSES_BY_ID]({}, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Address/GetByIdCompanyAllAddress?company_id=${payload.id}`)
        .then(res => {
          if (res.data.status) {
            if (res.data.data) {
              resolve(res.data.data)
            }
          } else {
            commit(SET_NOTIFICATION, res.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  }
}

const mutations = {
  [SET_DEFAULT_COMPANY](state) {
    state.company_id = null
    state.company = {
      company_code: null,
      company_name: '',
      company_trade_name: '',
      tax_office_id: null,
      tax_number: '',
      identity_number: '',
      parent_company_id: null,
      is_passive: false,
      image_base_64: null,
      company_group_id: null,
      company_address: [],
      type: 1
    }
    state.invoice = {
      e_mail: '',
      phone: '',
      fax: '',
      address_line1: '',
      address_line2: '',
      address_line3: '',
      post_code: '',
      country_id: undefined,
      country_ready: false,
      city_id: undefined,
      city_ready: false,
      town_id: undefined,
      town_ready: false
    }
  },
  [SET_COMPANY](state, payload) {
    state.company_id = payload.company_id

    state.company.company_code = payload.company_code
    state.company.company_name = payload.company_name
    state.company.company_trade_name = payload.company_trade_name
    state.company.tax_number = payload.tax_number
    state.company.tax_office_id = payload.tax_office_id
    state.company.identity_number = payload.identity_number
    state.company.is_passive = payload.is_passive
    state.company.image_base_64 = payload.image_base_64
    state.company.company_group_id = payload.company_group_id
    state.company.company_address = payload.company_address

    if (payload.company_address.length > 0) {
      state.invoice = {
        address_type_id: payload.company_address[0].address_type_id,
        address_alias: payload.company_address[0].address_alias,
        company_address_id: payload.company_address[0].company_address_id,
        e_mail: payload.company_address[0].e_mail,
        phone: payload.company_address[0].phone,
        fax: payload.company_address[0].fax,
        address_line1: payload.company_address[0].address_line1,
        address_line2: payload.company_address[0].address_line2,
        address_line3: payload.company_address[0].address_line3,
        post_code: payload.company_address[0].post_code,
        country_id: payload.company_address[0].country_id,
        country_ready: true,
        city_id: payload.company_address[0].city_id,
        city_ready: true,
        town_id: payload.company_address[0].town_id,
        town_ready: true
      }
    }

    if (payload.tax_number !== '') {
      state.company.type = 2
    }
  },
  [SET_COMPANY_GROUP](state, payload) {
    payload.forEach(item => {
      state.groups.push({
        id: item.company_group_id,
        label: item.company_group_name
      })
    })
    state.groups_ready = true
  },
  [SET_COMPANY_STATE](state, payload) {
    state.company.is_passive = payload
  },
  [SET_COMPANY_TYPE](state, payload) {
    state.company.type = payload
  },
  [SET_TAX_OFFICES](state, payload) {
    payload.forEach(item => {
      state.tax_offices.push({ id: item.tax_office_id, label: item.tax_office_name })
    })
    state.tax_office_ready = true
  },
  [SET_COMPANY_DISPLAY_TAB](state, payload) {
    state.companyDisplayTab = payload
  },
  [MUTATE_TRANSACTION_LIST](state, payload) {
    for (let i = 0; i < payload.length; i++) {
      if (i === 0) {
        payload[i].RUNNING_TOTAL = payload[i].AMT
      } else {
        payload[i].RUNNING_TOTAL = payload[i - 1].RUNNING_TOTAL + payload[i].AMT
      }

      if (payload[i].RUNNING_TOTAL > 0) {
        payload[i].STATE = 0
      } else if (payload[i].RUNNING_TOTAL < 0) {
        payload[i].STATE = 1
      } else {
        payload[i].STATE = 2
      }
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
