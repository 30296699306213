export default [
  {
    path: "/Dashboard/List",
    name: "/Dashboard/List",
    component: () => import("@/views/Dashboard/List.vue"),
    meta: {
      title: "Dashboards",
      pageTitle: "Dashboard Management",
      breadcrumb: [
        {
          text: "Dashboards",
          active: true,
        },
      ],
      needRegister: true,
      action: "/Dashboard/List",
    },
  },
  {
    path: "/Dashboard/Create",
    name: "/Dashboard/Create",
    component: () => import("@/views/Dashboard/Create.vue"),
    meta: {
      title: "New Dashboard",
      pageTitle: "Dashboard Management",
      breadcrumb: [
        {
          text: "New Dashboard",
          active: true,
        },
      ],
      needRegister: true,
      action: "/Dashboard/Create",
    },
  },
  {
    path: "/Dashboard/Edit/:id",
    name: "/Dashboard/Edit",
    component: () => import("@/views/Dashboard/Edit.vue"),
    meta: {
      title: "Dashboard Edit",
      pageTitle: "Dashboard Management",
      breadcrumb: [
        {
          text: "Dashboard Edit",
          active: true,
        },
      ],
      needRegister: true,
      action: "/Dashboard/Edit",
    },
  },
  {
    path: "/Dashboard/Display/:id",
    name: "/Dashboard/Display",
    component: () => import("@/views/Dashboard/Display.vue"),
    meta: {
      title: "Dashboard Display",
      pageTitle: "Dashboard Management",
      breadcrumb: [
        {
          text: "Dashboards",
          to: { name: "/Dashboard/List" },
        },
        {
          text: "Display",
          active: true,
        },
      ],
      needRegister: true,
      action: "/Dashboard/Display",
    },
  },
];
