import axiosIns from '@/libs/axios'
import moment from 'moment'
import { GET_ALL_DATABASE, GET_ALL_TABLE_NAMES } from './database-service'
import { SET_NOTIFICATION } from './module-service'
import { SET_FILTERS } from './report-filters'
import { GET_REPORT_WITH_DATA, RUN_SQL } from './report-service'
moment().locale()

//Varsayılan Rapor Tip Objeleri
import default_table from '@/views/Report/components/DefaultTypeSettings/datatable.json'
import default_chart from '@/views/Report/components/DefaultTypeSettings/chart.json'
import default_pivot from '@/views/Report/components/DefaultTypeSettings/pivot.json'
import default_calendar from '@/views/Report/components/DefaultTypeSettings/calendar.json'
import { replaceSQLVariable } from '@/views/Report/components/js/replaceSQLVariable'

//Actions
export const GET_REPORT = 'getReport'
export const GET_REPORT_DATA = 'getReportData'
export const GET_DATABASES = 'getDatabases'
export const GET_TABLE_NAMES = 'getTableNames'
export const RUN_QUERY = 'runQuery'
export const GET_REPORT_TYPES = 'getReportTypes'
export const SAVE_REPORT = 'saveReport'
export const UPDATE_REPORT = 'updateReport'
export const GET_REPORT_OBJECT = 'getReportObject'

//Mutations
export const DEFAULT_REPORT = 'defaultReport'
export const SET_REPORT = 'setReport'
export const SET_REPORT_DATA = 'setReportData'
export const UPDATE_REPORT_NAME = 'updateReportName'
export const UPDATE_DATABASE_ID = 'updateDatabaseId'
export const SET_DATABASES = 'setDatabases'
export const SET_TABLE_NAMES = 'setTableNames'
export const UPDATE_REPORT_GROUP_ID = 'updateReportGroupId'
export const DATABASES_READY = 'databasesReady'
export const TOGGLE_DATE = 'toggleDate'
export const CHANGE_POSITION = 'changePosition'
export const ADD_QUERY = 'addQuery'
export const SET_REPORT_TYPE = 'setReportType'
export const SET_REPORT_TYPES = 'setReportTypes'
export const SET_REPORT_TYPE_OBJECT = 'setReportTypeObject'

const state = {
  databasesReady: false,
  databases: [],
  table_names: [],
  report_name: '',
  database_id: null,
  report_group_id: null,
  description: '',
  report_type_id: null,
  sql_query: '',
  first_position: null,
  last_position: null,
  settings_json: {},
  date_show: true,
  report_data: [],
  sql_headers: [],
  sql_response_settings: [],
  attach_variables: [
    { id: 1, vars: [] },
    { id: 3, vars: [] },
    { id: 4, vars: [] },
    { id: 5, vars: [] }
  ],
  report_types: [],
  report_type_objects: [
    { id: 1, object: JSON.stringify(default_table, null, 2) },
    { id: 3, object: JSON.stringify(default_chart, null, 2) },
    { id: 4, object: JSON.stringify(default_pivot, null, 2) },
    { id: 5, object: JSON.stringify(default_calendar, null, 2) }
  ]
}

const getters = {
  databases: state => state.databases,
  tableNames: state => state.table_names.find(x => x.id == state.database_id),
  dateShow: state => state.date_show,
  reportData: state => state.report_data,
  query: state => state.sql_query,
  attachVariables: state => state.attach_variables.find(x => x.id == state.report_type_id)?.vars,
  sqlResponseSettings: state => state.sql_response_settings,
  reportTypes: state => state.report_types,
  reportTypeObject: state => state.report_type_objects.find(x => x.id == state.report_type_id)?.object,
  sqlHeaders: state => state.sql_headers,
  reportType: state => state.report_type_id,
  reportGroup: state => state.report_group_id
}

const actions = {
  [GET_REPORT]({ commit, dispatch }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Report/GetByIdReport?report_m_id=${payload}`)
        .then(response => {
          if (response.data.status) {
            dispatch(GET_REPORT_DATA, {
              report_m_id: payload,
              filters: [...response.data.filter_json.filters]
            }).then(() => {
              commit(SET_REPORT, response.data)
              commit(SET_FILTERS, [...response.data.filter_json.filters])
              resolve()
            })
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_REPORT_DATA]({ commit, dispatch }, payload) {
    return new Promise(resolve => {
      let temp = []
      payload.filters.forEach(item => {
        temp.push({
          text: `{${item.SQLVariable}}`,
          value: replaceSQLVariable(item)
        })
      })
      let obj = {
        report_m_id: payload.report_m_id,
        filters: temp
      }
      dispatch(GET_REPORT_WITH_DATA, obj)
        .then(response => {
          commit(SET_REPORT_DATA, response.data)
          resolve()
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_DATABASES]({ commit, dispatch }) {
    return new Promise(resolve => {
      dispatch(GET_ALL_DATABASE).then(response => {
        response.forEach((database, index) => {
          dispatch(GET_TABLE_NAMES, database.database_id).then(() => {
            commit(SET_DATABASES, { id: database.database_id, label: database.database_name })
          })
          if (index == response.length - 1) {
            commit(DATABASES_READY)
            resolve()
          }
        })
      })
    })
  },
  [GET_TABLE_NAMES]({ commit, dispatch }, payload) {
    return new Promise(resolve => {
      dispatch(GET_ALL_TABLE_NAMES, payload).then(response => {
        commit(SET_TABLE_NAMES, { id: payload, data: response })
        resolve()
      })
    })
  },
  [RUN_QUERY]({ state, commit, dispatch }, payload) {
    return new Promise(resolve => {
      dispatch(RUN_SQL, {
        sql: payload,
        database_id: state.database_id
      }).then(response => {
        if (response.data.status) {
          commit(SET_REPORT_DATA, response.data)
        }
        resolve()
      })
    })
  },
  [GET_REPORT_TYPES]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('Report/GetAllReportTypes')
        .then(response => {
          if (response.data.status) {
            commit(SET_REPORT_TYPES, response.data.data)
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [SAVE_REPORT]({ commit, dispatch }) {
    return new Promise(resolve => {
      dispatch(GET_REPORT_OBJECT).then(report => {
        axiosIns
          .post('Report/SaveReport', report)
          .then(response => {
            commit(SET_NOTIFICATION, response.data)
            resolve(response.data.data)
          })
          .catch(() => {
            commit(SET_NOTIFICATION)
          })
      })
    })
  },
  [UPDATE_REPORT]({ commit, dispatch }, payload) {
    return new Promise(resolve => {
      dispatch(GET_REPORT_OBJECT).then(report => {
        report = {
          report_m_id: payload,
          ...report
        }
        axiosIns
          .post('Report/UpdateReport', report)
          .then(response => {
            commit(SET_NOTIFICATION, response.data)
            resolve(response.data.data)
          })
          .catch(() => {
            commit(SET_NOTIFICATION)
          })
      })
    })
  },
  [GET_REPORT_OBJECT]({ state, rootState, getters }) {
    return new Promise(resolve => {
      let filter_json = {
        filters: [...rootState.reportFilters],
        attach_variables: [...getters.attachVariables]
      }
      filter_json = JSON.stringify(filter_json)
      let report = {
        report_name: state.report_name,
        database_id: state.database_id,
        report_group_id: state.report_group_id,
        description: state.description,
        filter_json,
        sql_query: state.sql_query,
        settings_json: getters.reportTypeObject,
        report_type_id: state.report_type_id
      }
      resolve(report)
    })
  }
}

const mutations = {
  [DEFAULT_REPORT](state) {
    state.databases = []
    state.table_names = []
    state.report_name = ''
    state.database_id = null
    state.report_group_id = null
    state.description = ''
    state.report_type_id = null
    state.sql_query = 'SELECT ...'
    state.first_position = null
    state.last_position = null
    state.settings_json = {}
    state.report_data = []
    state.sql_headers = []
    state.sql_response_settings = []
    state.attach_variables = [
      { id: 1, vars: [] },
      { id: 3, vars: [] },
      { id: 4, vars: [] },
      { id: 5, vars: [] }
    ]
    state.report_types = []
    state.report_type_object = [
      { id: 1, object: JSON.stringify(default_table, null, 2) },
      { id: 3, object: JSON.stringify(default_chart, null, 2) },
      { id: 4, object: JSON.stringify(default_pivot, null, 2) },
      { id: 5, object: JSON.stringify(default_calendar, null, 2) }
    ]
  },
  [SET_REPORT](state, payload) {
    state.report_name = payload.report_name
    state.database_id = payload.database_id
    state.report_group_id = payload.report_group_id
    state.description = payload.description
    state.report_type_id = payload.report_type_id
    state.sql_query = payload.sql_query
    state.report_type_objects.find(x => x.id == state.report_type_id).object = JSON.stringify(payload.settings_json, null, 2)
    state.attach_variables.find(x => x.id == state.report_type_id).vars = payload.filter_json.attach_variables
  },
  [SET_REPORT_DATA](state, payload) {
    state.report_data = payload
    state.sql_headers = []
    state.sql_response_settings = []
    state.attach_variables
      .find(x => x.id == state.report_type_id)
      ?.vars.forEach(item => {
        item.value = null
      })
    if (payload.length > 0) {
      Object.keys(payload[0]).forEach(key => {
        state.sql_headers.push({ id: key, label: key })
        state.sql_response_settings.push({ title: key, data: key })
      })
    }
  },
  [UPDATE_REPORT_GROUP_ID](state, payload) {
    state.report_group_id = payload
  },
  [SET_DATABASES](state, payload) {
    state.databases.push({
      id: payload.id,
      label: payload.label
    })
  },
  [DATABASES_READY](state) {
    state.databasesReady = true
  },
  [SET_TABLE_NAMES](state, payload) {
    state.table_names.push({
      id: payload.id,
      data: payload.data
    })
  },
  [TOGGLE_DATE](state, payload) {
    state.date_show = payload
  },
  [CHANGE_POSITION](state, payload) {
    state.first_position = payload.first
    state.last_position = payload.last
  },
  [ADD_QUERY](state, payload) {
    if (state.first_position) {
      let temp = state.sql_query.split('')
      let diff = state.last_position - state.first_position
      temp.splice(state.first_position, diff, payload)
      state.sql_query = temp.join('')
    } else {
      state.sql_query += payload
    }
  },
  [SET_REPORT_TYPES](state, payload) {
    state.report_types = []
    payload.forEach(item => {
      state.report_types.push({
        id: item.report_type_id,
        label: item.report_type_name
      })
    })
  },
  [SET_REPORT_TYPE](state, payload) {
    state.report_type_id = payload
  },
  [SET_REPORT_TYPE_OBJECT](state, payload) {
    state.report_type_objects.find(x => x.id == state.report_type_id).object = payload
    let obj = state.report_type_objects.find(x => x.id == state.report_type_id).object
    let attachs = state.attach_variables.find(x => x.id == state.report_type_id).vars

    const temp = payload.split('#')
    const checkRegex = new RegExp('^[A-Za-z_]\\w*$')
    let tempAttach = []
    if (temp.length % 2 === 1) {
      // En az bir değişken tanımlanmışsa
      for (let i = 1; i < temp.length; i += 2) {
        // Değişkenleri değişken yazma kuralına uyarlarsa geçici diziye ekliyorum
        if (checkRegex.test(temp[i]) && temp[i].length <= 16) {
          tempAttach.push(temp[i])
        }
      }
      if (tempAttach.length > 0) {
        // Kurala uygun değişkenler oluşmuşsa
        const firstAttach = attachs.length == 0
        tempAttach.forEach(tempItem => {
          if (firstAttach) {
            // İlk kez atama yapılıyorsa kontrole gerek yok
            attachs.push({
              name: tempItem,
              value: null
            })
          } else {
            // ilk değilse kontrol ederek diziye ekliyorum
            const checkIndex = attachs.findIndex(x => x.name === tempItem)
            if (checkIndex === -1) {
              attachs.push({
                name: tempItem,
                value: null
              })
            }
          }
        })
        attachs.forEach((item, index) => {
          const checkIndex = tempAttach.findIndex(x => x === item.name)
          if (checkIndex === -1) {
            attachs.splice(index, 1)
          }
        })
      } else {
        // Eğer hiç bir değişken tanımlanmamışsa sıfırlıyorum
        attachs.splice(0, attachs.length)
      }
    } else {
      attachs.forEach((item, index) => {
        if (!obj.includes(`#${item.name}#`)) {
          attachs.splice(index, 1)
        }
      })
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
