import { DEFAULT_FILTER } from "./process-filter"

//Actions
export const GET_FILTER = 'getFilter'
export const NEW_FILTER = 'newFilter'
export const UPDATE_FILTER = 'updateFilter'

//Mutations
export const ADD_FILTER = 'addFilter'
export const SAVE_FILTER = 'saveFilter'
export const DELETE_FILTER = 'deleteFilter'
export const SET_FILTERS = 'setFilters'

const testFilters = [
  {
    "name": "Deneme",
    "value": "Deneme",
    "type": 0,
    "option": "text",
    "colSize": 6,
    "SQLVariable": "deneme",
    "SQLReplaceType": "pure",
    "SQLIsRun": false,
    "SQLDataBase": null,
    "SQLQuery": "SELECT user_id,CONCAT(name,' ',surname) FROM gnl_user",
    "selectOptions": [
      {
        "id": "",
        "label": ""
      }
    ],
    "dateFormat": null
  },
  {
    "name": "Deneme2",
    "value": "2022-06-23",
    "type": 2,
    "option": "single",
    "colSize": 6,
    "SQLVariable": "deneme2",
    "SQLReplaceType": "pure",
    "SQLIsRun": false,
    "SQLDataBase": null,
    "SQLQuery": "SELECT user_id,CONCAT(name,' ',surname) FROM gnl_user",
    "selectOptions": [
      {
        "id": "",
        "label": ""
      }
    ],
    "dateFormat": "Y-m-d"
  }
]

const state = []

const getters = {
  filters: state => state
}

const actions = {
  [GET_FILTER]({ state },payload)
  {
    return new Promise(resolve =>
    {
      resolve({ ...state[payload] })
    })
  },
  [NEW_FILTER]({ commit,rootState })
  {
    let addFilter = new Promise(resolve =>
    {
      commit(ADD_FILTER,{ ...rootState.processFilter })
      resolve()
    })
    addFilter.then(() =>
    {
      commit(DEFAULT_FILTER)
    })
  },
  [UPDATE_FILTER]({ commit,rootState },payload)
  {
    new Promise(resolve =>
    {
      commit(SAVE_FILTER,{ index: payload,data: { ...rootState.processFilter } })
      resolve()
    }).then(() =>
    {
      commit(DEFAULT_FILTER)
    })
  }
}

const mutations = {
  [ADD_FILTER](state,payload)
  {
    state.push(payload)
  },
  [SAVE_FILTER](state,payload)
  {
    let c = state[payload.index]
    let d = payload.data
    c.name = d.name
    c.value = d.value
    c.type = d.type
    c.option = d.option
    c.colSize = d.colSize
    c.SQLVariable = d.SQLVariable
    c.SQLIsRun = d.SQLIsRun
    c.SQLDataBase = d.SQLDataBase
    c.SQLQuery = d.SQLQuery
    c.selectOptions = d.selectOptions
    c.dateFormat = d.dateFormat
  },
  [DELETE_FILTER](state,payload)
  {
    state.splice(payload,1)
  },
  [SET_FILTERS](state,payload)
  {
    state.splice(0,state.length)
    payload.forEach(item =>
    {
      state.push(item)
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}