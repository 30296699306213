import useJwt from '@/auth/jwt/useJwt'
import axiosIns from '@/libs/axios'
import router from '@/router'
import { SET_DASHBOARDS, SET_MENU_ITEMS, SET_NOTIFICATION, SET_PERMS, SET_PERMS_READY } from './module-service'
import { GET_ALL_CURRENCY } from './currency-service'
import { SET_ACCOUNT } from './user-service'
import i18n from '@/libs/i18n'
import jwtDecode from 'jwt-decode'
import ability from '@/libs/acl/ability'

//Actions
export const LOGIN = 'login'
export const REFRESH_TOKEN = 'refreshToken'
export const LOGOUT = 'logout'
export const GET_IP_ADDRESS = 'getIpAddress'
export const GET_SYSTEM_SETTINGS = 'getSystemSettings'
export const SEND_FORGOT_MAIL = 'sendForgotMail'

//Mutations
export const SET_AUTH = 'setAuth'
export const PURGE_AUTH = 'purgeAuth'
export const SET_BUTTON_STATE = 'setButtonState'
export const SET_IP_ADDRESS = 'setIpAddress'
export const SET_SYSTEM_SETTINGS = 'setSystemSettings'
export const SET_REMEMBER_ME = 'setRememberMe'
export const SET_REMEMBER_ACCOUNT = 'setRememberAccount'

const state = {
  isAuthenticated: !!useJwt.getToken(),
  buttonState: false,
  invterval: null,
  ipAddress: undefined,
  systemSettings: 'bilgeCodeSystemSettings_v4' in localStorage ? JSON.parse(localStorage.getItem('bilgeCodeSystemSettings_v4')) : undefined,
  systemSettingsReady: false,
  rememberMe: 'rememberMe' in localStorage ? JSON.parse(localStorage.getItem('rememberMe')) : false,
  rememberUsername: 'rememberUsername' in localStorage ? localStorage.getItem('rememberUsername') : undefined,
  rememberPassword: 'rememberPassword' in localStorage ? localStorage.getItem('rememberPassword') : undefined
}

const getters = {
  isAuthenticated: state => state.isAuthenticated,
  buttonState: state => state.buttonState,
  ipAddress: state => state.ipAddress,
  systemSettings: state => state.systemSettings,
  systemSettingsReady: state => state.systemSettingsReady,
  rememberMe: state => state.rememberMe,
  rememberUsername: state => state.rememberUsername,
  rememberPassword: state => state.rememberPassword,
  brandTitle: state => {
    if (state?.systemSettings?.company_title) {
      return state.systemSettings.company_title
    } else {
      return ''
    }
  },
  brandLogo: (state, rootGetters) => {
    let skin = rootGetters['appConfig/getThemeSkin']
    switch (skin) {
      case 'dark':
        return rootGetters.brandLogoDark
      default:
        return rootGetters.brandLogoLight
    }
  },
  brandLogoDark: state => {
    if (state?.systemSettings?.dark_image_id) {
      return `https://${process.env.VUE_APP_API_HOST}/Image/GetImage?image_id=${state.systemSettings.dark_image_id}`
    } else {
      return require('@/assets/images/logo/bilge_code_logo_tp.png')
    }
  },
  brandLogoLight: state => {
    if (state?.systemSettings?.light_image_id) {
      return `https://${process.env.VUE_APP_API_HOST}/Image/GetImage?image_id=${state.systemSettings.light_image_id}`
    } else {
      return require('@/assets/images/logo/bilge_code_logo_tp.png')
    }
  },
  sidebarImage: (state, rootGetters) => {
    let skin = rootGetters['appConfig/getThemeSkin']
    switch (skin) {
      case 'dark':
        return rootGetters.sidebarImageDark
      default:
        return rootGetters.sidebarImageLight
    }
  },
  sidebarImageDark: state => {
    if (state?.systemSettings?.sidebar_dark_image_id) {
      return `https://${process.env.VUE_APP_API_HOST}/Image/GetImage?image_id=${state.systemSettings.sidebar_dark_image_id}`
    } else {
      return require('@/assets/images/logo/bilge_code_logo_tp.png')
    }
  },
  sidebarImageLight: state => {
    if (state?.systemSettings?.sidebar_light_image_id) {
      return `https://${process.env.VUE_APP_API_HOST}/Image/GetImage?image_id=${state.systemSettings.sidebar_light_image_id}`
    } else {
      return require('@/assets/images/logo/bilge_code_logo_tp.png')
    }
  },
  loginImage: state => {
    if (state?.systemSettings?.login_image_id) {
      return `https://${process.env.VUE_APP_API_HOST}/Image/GetImage?image_id=${state.systemSettings.login_image_id}`
    } else {
      return undefined
    }
  }
}

const actions = {
  [LOGIN]({ dispatch, commit }, credentials) {
    commit(SET_BUTTON_STATE, true)
    return new Promise(resolve => {
      dispatch(GET_IP_ADDRESS).then(() => {
        useJwt
          .login(credentials)
          .then(response => {
            if (response.data.status) {
              let tempRoles = jwtDecode(response.data.access_token)?.role
              commit(SET_AUTH, response.data)
              commit(SET_ACCOUNT, response.data.data)
              commit(SET_PERMS, tempRoles)
              commit(SET_MENU_ITEMS, response.data.data.user_method)
              if (response.data.data.user_dashboard?.length) {
                commit(SET_DASHBOARDS, response.data.data.user_dashboard)
              }
            } else {
              commit(SET_NOTIFICATION, response.data)
            }
            commit(SET_BUTTON_STATE, false)
            resolve(response.data)
          })
          .catch(() => {
            commit(SET_NOTIFICATION)
            commit(SET_BUTTON_STATE, false)
          })
      })
    })
  },
  [REFRESH_TOKEN]({ dispatch, commit, state }) {
    return new Promise((resolve, reject) => {
      dispatch(GET_IP_ADDRESS).then(() => {
        if (router.currentRoute.name != 'login') {
          if (state.isAuthenticated) {
            useJwt
              .refreshToken()
              .then(response => {
                if (response.data.status) {
                  let tempRoles = jwtDecode(response.data.access_token)?.role
                  commit(SET_AUTH, response.data)
                  commit(SET_ACCOUNT, response.data.data)
                  commit(SET_PERMS, tempRoles)
                  commit(SET_MENU_ITEMS, response.data.data.user_method)
                  if (response.data.data.user_dashboard?.length) {
                    commit(SET_DASHBOARDS, response.data.data.user_dashboard)
                  }
                  resolve()
                } else {
                  commit(SET_NOTIFICATION, response.data)
                  dispatch(LOGOUT)
                  reject()
                }
              })
              .catch(() => {
                dispatch(LOGOUT)
                reject()
              })
          } else {
            dispatch(LOGOUT)
            reject()
          }
        } else {
          resolve()
        }
      })
    })
  },
  [LOGOUT]({ commit }) {
    commit(SET_ACCOUNT)
    commit(PURGE_AUTH)
  },
  [SET_ACCOUNT]({ commit }, payload) {
    return new Promise(resolve => {
      commit(SET_ACCOUNT, payload)
      resolve()
    })
  },
  [GET_IP_ADDRESS]({ commit }) {
    return new Promise((resolve, reject) => {
      axiosIns
        .get('Ip/GetIp')
        .then(response => {
          if (response.data.status) {
            commit(SET_IP_ADDRESS, response.data.data)
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          reject()
        })
    })
  },
  [GET_SYSTEM_SETTINGS]({ commit, state, getters }) {
    return new Promise((resolve, reject) => {
      axiosIns
        .get('General/GetSettings')
        .then(response => {
          if (response.data.status) {
            commit(SET_SYSTEM_SETTINGS, response.data.data)
            document.title = `${getters.brandTitle} - ${i18n.t(router.currentRoute.meta.title)}`
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
            reject()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
          reject()
        })
    })
  },
  [SEND_FORGOT_MAIL]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(`Login/ForgotPassword?email=${payload}`)
        .then(response => {
          if (response.data.status) {
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
          reject()
        })
    })
  }
}

const mutations = {
  [SET_AUTH](state, user_data) {
    state.isAuthenticated = true
    useJwt.setToken(user_data.access_token)
    useJwt.setRefreshToken(user_data.refresh_token)
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false
    ability.update([])
    useJwt.logout()
    router.push({ name: 'login' })
  },
  [SET_BUTTON_STATE](state, response) {
    state.buttonState = response
  },
  [SET_IP_ADDRESS](state, payload) {
    state.ipAddress = payload
  },
  [SET_SYSTEM_SETTINGS](state, payload) {
    state.systemSettings = payload
    localStorage.setItem('bilgeCodeSystemSettings_v4', JSON.stringify(payload))
    state.systemSettingsReady = true
  },
  [SET_REMEMBER_ME](state, payload) {
    state.rememberMe = payload
    localStorage.setItem('rememberMe', JSON.stringify(payload))
  },
  [SET_REMEMBER_ACCOUNT](state, payload) {
    state.rememberUsername = payload.username
    localStorage.setItem('rememberUsername', payload.username)
    state.rememberPassword = payload.password
    localStorage.setItem('rememberPassword', payload.password)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}