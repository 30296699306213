let authKeys = [
  {
    method_id: 21,
    method_name: 'Home',
    hash: '/Home/Home',
    method_icon: 'fal fa-circle',
    type: 4,
    method_order: 0,
    module_id: 2,
    module_name: 'Home',
    module_icon: 'home',
    module_order: 0
  },
  {
    method_id: 113,
    method_name: 'GetAllTaxOffice',
    hash: '/Address/GetAllTaxOffice',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 12,
    module_name: 'Address Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 93,
    method_name: 'GetAllUserCompanyAddress',
    hash: '/Address/GetAllUserCompanyAddress',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 12,
    module_name: 'Address Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 94,
    method_name: 'GetAllCountry',
    hash: '/Address/GetAllCountry',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 12,
    module_name: 'Address Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 95,
    method_name: 'GetAllCountryCities',
    hash: '/Address/GetAllCountryCities',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 12,
    module_name: 'Address Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 96,
    method_name: 'GetAllCityTowns',
    hash: '/Address/GetAllCityTowns',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 12,
    module_name: 'Address Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 97,
    method_name: 'AddCompanyAddress',
    hash: '/Address/AddCompanyAddress',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 12,
    module_name: 'Address Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 98,
    method_name: 'UpdateCompanyAddress',
    hash: '/Address/UpdateCompanyAddress',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 12,
    module_name: 'Address Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 99,
    method_name: 'GetAllAddressType',
    hash: '/Address/GetAllAddressType',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 12,
    module_name: 'Address Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 100,
    method_name: 'GetByIdUserCompanyAddress',
    hash: '/Address/GetByIdUserCompanyAddress',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 12,
    module_name: 'Address Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 116,
    method_name: 'GetAllPriceList',
    hash: '/PriceList/GetAllPriceList',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 13,
    module_name: 'Price List Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 117,
    method_name: 'GetAllPriceListGroup',
    hash: '/PriceList/GetAllPriceListGroup',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 13,
    module_name: 'Price List Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 118,
    method_name: 'GetByIdPriceList',
    hash: '/PriceList/GetByIdPriceList',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 13,
    module_name: 'Price List Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 120,
    method_name: 'UpdatePriceListDetailUnitPriceTra',
    hash: '/PriceList/UpdatePriceListDetailUnitPriceTra',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 13,
    module_name: 'Price List Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 150,
    method_name: 'UpdateMainPriceListByProductId',
    hash: '/PriceList/UpdateMainPriceListByProductId',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 13,
    module_name: 'Price List Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 153,
    method_name: 'UpdatePriceListRulePrice',
    hash: '/PriceList/UpdatePriceListRulePrice',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 13,
    module_name: 'Price List Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 154,
    method_name: 'UpdatePriceListDetailList',
    hash: '/PriceList/UpdatePriceListDetailList',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 13,
    module_name: 'Price List Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 142,
    method_name: 'UpdatePriceListDetail',
    hash: '/PriceList/UpdatePriceListDetail',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 13,
    module_name: 'Price List Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 146,
    method_name: 'UpdatePriceListDetailUnitPriceTra',
    hash: '/PriceList/UpdatePriceListDetailUnitPriceTra',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 13,
    module_name: 'Price List Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 149,
    method_name: 'GetAllCurrency',
    hash: '/General/GetAllCurrency',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 15,
    module_name: 'General Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 125,
    method_name: 'GetAllUnit',
    hash: '/General/GetAllUnit',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 15,
    module_name: 'General Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 66,
    method_name: 'GetAllMethods',
    hash: '/Method/GetAllMethods',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 8,
    module_name: 'Method Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 51,
    method_name: 'GetUserMethods',
    hash: '/Method/GetUserMethods',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 8,
    module_name: 'Method Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 64,
    method_name: 'GetAllUserErpMethods',
    hash: '/Erp/GetAllUserErpMethods',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 9,
    module_name: 'Erp Method Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 65,
    method_name: 'GetByIdErpMethod',
    hash: '/Erp/GetByIdErpMethod',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 9,
    module_name: 'Erp Method Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 139,
    method_name: 'SaveOrderErp',
    hash: '/Erp/SaveOrderErp',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 9,
    module_name: 'Erp Method Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 141,
    method_name: 'SavePaymentErp',
    hash: '/Erp/SavePaymentErp',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 9,
    module_name: 'Erp Method Management',
    module_icon: null,
    module_order: 0
  },
  {
    method_id: 22,
    method_name: 'Dashboard Display',
    hash: '/Dashboard/Display',
    method_icon: 'fal fa-circle',
    type: 3,
    method_order: 0,
    module_id: 3,
    module_name: 'Dashboard Management',
    module_icon: 'fal fa-layer-group',
    module_order: 1
  },
  {
    method_id: 26,
    method_name: 'Dashboards',
    hash: '/Dashboard/List',
    method_icon: 'fal fa-circle',
    type: 1,
    method_order: 0,
    module_id: 3,
    module_name: 'Dashboard Management',
    module_icon: 'fal fa-layer-group',
    module_order: 1
  },
  {
    method_id: 27,
    method_name: 'GetAllDashboard',
    hash: '/Dashboard/GetAllDashboard',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 3,
    module_name: 'Dashboard Management',
    module_icon: 'fal fa-layer-group',
    module_order: 1
  },
  {
    method_id: 75,
    method_name: 'GetByIdDashboard',
    hash: '/Dashboard/GetByIdDashboard',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 3,
    module_name: 'Dashboard Management',
    module_icon: 'fal fa-layer-group',
    module_order: 1
  },
  {
    method_id: 53,
    method_name: 'GetAllUserDashboard',
    hash: '/Dashboard/GetAllUserDashboard',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 3,
    module_name: 'Dashboard Management',
    module_icon: 'fal fa-layer-group',
    module_order: 1
  },
  {
    method_id: 54,
    method_name: 'GetUserDashboard',
    hash: '/Dashboard/GetUserDashboard',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 3,
    module_name: 'Dashboard Management',
    module_icon: 'fal fa-layer-group',
    module_order: 1
  },
  {
    method_id: 70,
    method_name: 'SaveDashboard',
    hash: '/Dashboard/SaveDashboard',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 3,
    module_name: 'Dashboard Management',
    module_icon: 'fal fa-layer-group',
    module_order: 1
  },
  {
    method_id: 71,
    method_name: 'UpdateDashboard',
    hash: '/Dashboard/UpdateDashboard',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 3,
    module_name: 'Dashboard Management',
    module_icon: 'fal fa-layer-group',
    module_order: 1
  },
  {
    method_id: 72,
    method_name: 'Dashboard Edit',
    hash: '/Dashboard/Edit',
    method_icon: 'fal fa-circle',
    type: 3,
    method_order: 0,
    module_id: 3,
    module_name: 'Dashboard Management',
    module_icon: 'fal fa-layer-group',
    module_order: 1
  },
  {
    method_id: 128,
    method_name: 'DashboardGetOrder',
    hash: '/Dashboard/GetOrder',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 3,
    module_name: 'Dashboard Management',
    module_icon: 'fal fa-layer-group',
    module_order: 1
  },
  {
    method_id: 129,
    method_name: 'DashboardGetTransaction',
    hash: '/Dashboard/GetTransaction',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 3,
    module_name: 'Dashboard Management',
    module_icon: 'fal fa-layer-group',
    module_order: 1
  },
  {
    method_id: 23,
    method_name: 'New Dashboard',
    hash: '/Dashboard/Create',
    method_icon: 'fal fa-circle',
    type: 1,
    method_order: 1,
    module_id: 3,
    module_name: 'Dashboard Management',
    module_icon: 'fal fa-layer-group',
    module_order: 1
  },
  {
    method_id: 14,
    method_name: 'GetByIdReport',
    hash: '/Report/GetByIdReport',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 1,
    module_name: 'Report Management',
    module_icon: 'fal fa-analytics',
    module_order: 2
  },
  {
    method_id: 15,
    method_name: 'GetReport',
    hash: '/Report/GetReport',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 1,
    module_name: 'Report Management',
    module_icon: 'fal fa-analytics',
    module_order: 2
  },
  {
    method_id: 16,
    method_name: 'SaveReport',
    hash: '/Report/SaveReport',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 1,
    module_name: 'Report Management',
    module_icon: 'fal fa-analytics',
    module_order: 2
  },
  {
    method_id: 17,
    method_name: 'UpdateReport',
    hash: '/Report/UpdateReport',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 1,
    module_name: 'Report Management',
    module_icon: 'fal fa-analytics',
    module_order: 2
  },
  {
    method_id: 18,
    method_name: 'DeleteReport',
    hash: '/Report/DeleteReport',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 1,
    module_name: 'Report Management',
    module_icon: 'fal fa-analytics',
    module_order: 2
  },
  {
    method_id: 19,
    method_name: 'GetAllUserReport',
    hash: '/Report/GetAllUserReport',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 1,
    module_name: 'Report Management',
    module_icon: 'fal fa-analytics',
    module_order: 2
  },
  {
    method_id: 20,
    method_name: 'GetAllReport',
    hash: '/Report/GetAllReport',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 1,
    module_name: 'Report Management',
    module_icon: 'fal fa-analytics',
    module_order: 2
  },
  {
    method_id: 25,
    method_name: 'Reports',
    hash: '/Report/List',
    method_icon: 'fal fa-circle',
    type: 1,
    method_order: 0,
    module_id: 1,
    module_name: 'Report Management',
    module_icon: 'fal fa-analytics',
    module_order: 2
  },
  {
    method_id: 39,
    method_name: 'Report Display',
    hash: '/Report/Display',
    method_icon: 'fal fa-circle',
    type: 3,
    method_order: 0,
    module_id: 1,
    module_name: 'Report Management',
    module_icon: 'fal fa-analytics',
    module_order: 2
  },
  {
    method_id: 56,
    method_name: 'GetAllReportGroups',
    hash: '/Report/GetAllReportGroups',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 1,
    module_name: 'Report Management',
    module_icon: 'fal fa-analytics',
    module_order: 2
  },
  {
    method_id: 57,
    method_name: 'GetAllReportTypes',
    hash: '/Report/GetAllReportTypes',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 1,
    module_name: 'Report Management',
    module_icon: 'fal fa-analytics',
    module_order: 2
  },
  {
    method_id: 58,
    method_name: 'GetReportFilters',
    hash: '/Report/GetReportFilters',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 1,
    module_name: 'Report Management',
    module_icon: 'fal fa-analytics',
    module_order: 2
  },
  {
    method_id: 59,
    method_name: 'GetByIdFilterReport',
    hash: '/Report/GetByIdFilterReport',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 1,
    module_name: 'Report Management',
    module_icon: 'fal fa-analytics',
    module_order: 2
  },
  {
    method_id: 61,
    method_name: 'SaveReportGroup',
    hash: '/Report/SaveReportGroup',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 1,
    module_name: 'Report Management',
    module_icon: 'fal fa-analytics',
    module_order: 2
  },
  {
    method_id: 74,
    method_name: 'Report Edit',
    hash: '/Report/Edit',
    method_icon: 'fal fa-circle',
    type: 3,
    method_order: 0,
    module_id: 1,
    module_name: 'Report Management',
    module_icon: 'fal fa-analytics',
    module_order: 2
  },
  {
    method_id: 28,
    method_name: 'My Reports',
    hash: '/Report/MyList',
    method_icon: 'fal fa-circle',
    type: 1,
    method_order: 1,
    module_id: 1,
    module_name: 'Report Management',
    module_icon: 'fal fa-analytics',
    module_order: 2
  },
  {
    method_id: 24,
    method_name: 'New Report',
    hash: '/Report/Create',
    method_icon: 'fal fa-circle',
    type: 1,
    method_order: 2,
    module_id: 1,
    module_name: 'Report Management',
    module_icon: 'fal fa-analytics',
    module_order: 2
  },
  {
    method_id: 41,
    method_name: 'Payments',
    hash: '/Payment/List',
    method_icon: 'fal fa-circle',
    type: 1,
    method_order: 0,
    module_id: 5,
    module_name: 'Payment Management',
    module_icon: 'fal fa-credit-card',
    module_order: 3
  },
  {
    method_id: 43,
    method_name: 'GetBanks',
    hash: '/Payment/GetBanks',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 5,
    module_name: 'Payment Management',
    module_icon: 'fal fa-credit-card',
    module_order: 3
  },
  {
    method_id: 44,
    method_name: 'GetCreditCardGetInstallments',
    hash: '/Payment/GetCreditCardGetInstallments',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 5,
    module_name: 'Payment Management',
    module_icon: 'fal fa-credit-card',
    module_order: 3
  },
  {
    method_id: 45,
    method_name: 'Sale',
    hash: '/Payment/Sale',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 5,
    module_name: 'Payment Management',
    module_icon: 'fal fa-credit-card',
    module_order: 3
  },
  {
    method_id: 46,
    method_name: 'Cancel',
    hash: '/Payment/Cancel',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 5,
    module_name: 'Payment Management',
    module_icon: 'fal fa-credit-card',
    module_order: 3
  },
  {
    method_id: 47,
    method_name: 'Return',
    hash: '/Payment/Return',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 5,
    module_name: 'Payment Management',
    module_icon: 'fal fa-credit-card',
    module_order: 3
  },
  {
    method_id: 48,
    method_name: 'Payment Detail',
    hash: '/Payment/TransactionDetail',
    method_icon: 'fal fa-circle',
    type: 3,
    method_order: 0,
    module_id: 5,
    module_name: 'Payment Management',
    module_icon: 'fal fa-credit-card',
    module_order: 3
  },
  {
    method_id: 49,
    method_name: 'GetTransactions',
    hash: '/Payment/GetTransactions',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 5,
    module_name: 'Payment Management',
    module_icon: 'fal fa-credit-card',
    module_order: 3
  },
  {
    method_id: 89,
    method_name: 'GetTransaction',
    hash: '/Payment/GetTransaction',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 5,
    module_name: 'Payment Management',
    module_icon: 'fal fa-credit-card',
    module_order: 3
  },
  {
    method_id: 144,
    method_name: 'CreatePaymentLink',
    hash: '/Payment/CreatePaymentLink',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 5,
    module_name: 'Payment Management',
    module_icon: 'fal fa-credit-card',
    module_order: 3
  },
  {
    method_id: 145,
    method_name: 'PaymentLinkSend',
    hash: '/Payment/PaymentLinkSend',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 5,
    module_name: 'Payment Management',
    module_icon: 'fal fa-credit-card',
    module_order: 3
  },
  {
    method_id: 151,
    method_name: 'GetPaymentLinks',
    hash: '/Payment/GetPaymentLinks',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 5,
    module_name: 'Payment Management',
    module_icon: 'fal fa-credit-card',
    module_order: 3
  },
  {
    method_id: 152,
    method_name: 'GetPaymentLink',
    hash: '/Payment/GetPaymentLink',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 5,
    module_name: 'Payment Management',
    module_icon: 'fal fa-credit-card',
    module_order: 3
  },
  {
    method_id: 40,
    method_name: 'Pay',
    hash: '/Payment/Pay',
    method_icon: 'fal fa-circle',
    type: 1,
    method_order: 1,
    module_id: 5,
    module_name: 'Payment Management',
    module_icon: 'fal fa-credit-card',
    module_order: 3
  },
  {
    method_id: 156,
    method_name: 'PaymentLinks',
    hash: '/Payment/PaymentLinks',
    method_icon: 'fal fa-circle',
    type: 1,
    method_order: 2,
    module_id: 5,
    module_name: 'Payment Management',
    module_icon: 'fal fa-credit-card',
    module_order: 3
  },
  {
    method_id: 159,
    method_name: 'Company New User',
    hash: '/Company/CreateUser',
    method_icon: 'fal fa-circle',
    type: 3,
    method_order: 0,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 160,
    method_name: 'GetByIdCompanyAllAddress',
    hash: '/Address/GetByIdCompanyAllAddress',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 137,
    method_name: 'CompanyGetTransaction',
    hash: '/Company/CompanyGetTransaction',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 138,
    method_name: 'CompanyGetBalance',
    hash: '/Company/CompanyGetBalance',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 130,
    method_name: 'GetCompanyTransaction',
    hash: '/Company/GetTransaction',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 132,
    method_name: 'GetBalance',
    hash: '/Company/GetBalance',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 119,
    method_name: 'SetCompanyPriceList',
    hash: '/Company/SetCompanyPriceList',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 106,
    method_name: 'SaveCompany',
    hash: '/Company/SaveCompany',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 107,
    method_name: 'UpdateCompany',
    hash: '/Company/UpdateCompany',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 108,
    method_name: 'DeleteCompany',
    hash: '/Company/DeleteCompany',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 109,
    method_name: 'GetAllCompanyGroup',
    hash: '/Company/GetAllCompanyGroup',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 111,
    method_name: 'Company Edit',
    hash: '/Company/Edit',
    method_icon: 'fal fa-circle',
    type: 3,
    method_order: 0,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 112,
    method_name: 'Company Detail',
    hash: '/Company/Detail',
    method_icon: 'fal fa-circle',
    type: 3,
    method_order: 0,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 114,
    method_name: 'GetAllCompanyUser',
    hash: '/Company/GetAllCompanyUser',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 115,
    method_name: 'GetCompanyPriceList',
    hash: '/Company/GetCompanyPriceList',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 82,
    method_name: 'GetAllCompany',
    hash: '/Company/GetAllCompany',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 83,
    method_name: 'GetAllSubCompany',
    hash: '/Company/GetAllSubCompany',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 84,
    method_name: 'GetByIdCompany',
    hash: '/Company/GetByIdCompany',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 42,
    method_name: 'Companies',
    hash: '/Company/List',
    method_icon: 'fal fa-circle',
    type: 1,
    method_order: 0,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 110,
    method_name: 'New Company',
    hash: '/Company/Create',
    method_icon: 'fal fa-circle',
    type: 1,
    method_order: 1,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 131,
    method_name: 'Company Transaction',
    hash: '/Company/Transaction',
    method_icon: 'fal fa-circle',
    type: 1,
    method_order: 2,
    module_id: 6,
    module_name: 'Company Management',
    module_icon: 'fal fa-industry-alt',
    module_order: 4
  },
  {
    method_id: 36,
    method_name: 'User Edit',
    hash: '/User/Edit',
    method_icon: 'fal fa-circle',
    type: 3,
    method_order: 0,
    module_id: 4,
    module_name: 'User Management',
    module_icon: 'fal fa-users',
    module_order: 5
  },
  {
    method_id: 37,
    method_name: 'User Detail',
    hash: '/User/Detail',
    method_icon: 'fal fa-circle',
    type: 3,
    method_order: 0,
    module_id: 4,
    module_name: 'User Management',
    module_icon: 'fal fa-users',
    module_order: 5
  },
  {
    method_id: 38,
    method_name: 'User Settings',
    hash: '/User/Settings',
    method_icon: 'fal fa-circle',
    type: 3,
    method_order: 0,
    module_id: 4,
    module_name: 'User Management',
    module_icon: 'fal fa-users',
    module_order: 5
  },
  {
    method_id: 67,
    method_name: 'GetMyData',
    hash: '/User/GetMyData',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 4,
    module_name: 'User Management',
    module_icon: 'fal fa-users',
    module_order: 5
  },
  {
    method_id: 68,
    method_name: 'UpdateMyData',
    hash: '/User/UpdateMyData',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 4,
    module_name: 'User Management',
    module_icon: 'fal fa-users',
    module_order: 5
  },
  {
    method_id: 69,
    method_name: 'ChangeMyPassword',
    hash: '/User/ChangeMyPassword',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 4,
    module_name: 'User Management',
    module_icon: 'fal fa-users',
    module_order: 5
  },
  {
    method_id: 29,
    method_name: 'Users',
    hash: '/User/List',
    method_icon: 'fal fa-circle',
    type: 1,
    method_order: 0,
    module_id: 4,
    module_name: 'User Management',
    module_icon: 'fal fa-users',
    module_order: 5
  },
  {
    method_id: 30,
    method_name: 'GetByIdUser',
    hash: '/User/GetByIdUser',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 4,
    module_name: 'User Management',
    module_icon: 'fal fa-users',
    module_order: 5
  },
  {
    method_id: 31,
    method_name: 'SaveUser',
    hash: '/User/SaveUser',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 4,
    module_name: 'User Management',
    module_icon: 'fal fa-users',
    module_order: 5
  },
  {
    method_id: 32,
    method_name: 'UpdateUser',
    hash: '/User/UpdateUser',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 4,
    module_name: 'User Management',
    module_icon: 'fal fa-users',
    module_order: 5
  },
  {
    method_id: 33,
    method_name: 'DeleteUser',
    hash: '/User/DeleteUser',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 4,
    module_name: 'User Management',
    module_icon: 'fal fa-users',
    module_order: 5
  },
  {
    method_id: 34,
    method_name: 'GetAllUsers',
    hash: '/User/GetAllUsers',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 4,
    module_name: 'User Management',
    module_icon: 'fal fa-users',
    module_order: 5
  },
  {
    method_id: 35,
    method_name: 'New User',
    hash: '/User/Create',
    method_icon: 'fal fa-circle',
    type: 1,
    method_order: 1,
    module_id: 4,
    module_name: 'User Management',
    module_icon: 'fal fa-users',
    module_order: 5
  },
  {
    method_id: 55,
    method_name: 'GetAllDataBase',
    hash: '/DataBase/GetAllDataBase',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 7,
    module_name: 'Database Management',
    module_icon: null,
    module_order: 6
  },
  {
    method_id: 62,
    method_name: 'GetAllDataBaseTables',
    hash: '/DataBase/GetAllDataBaseTables',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 7,
    module_name: 'Database Management',
    module_icon: null,
    module_order: 6
  },
  {
    method_id: 140,
    method_name: 'OrderCancel',
    hash: '/Order/OrderCancel',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 10,
    module_name: 'Order Management',
    module_icon: 'fal fa-shopping-cart',
    module_order: 7
  },
  {
    method_id: 158,
    method_name: 'DeleteAllCart',
    hash: '/Order/DeleteAllCart',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 10,
    module_name: 'Order Management',
    module_icon: 'fal fa-shopping-cart',
    module_order: 7
  },
  {
    method_id: 101,
    method_name: 'OrderComplete',
    hash: '/Order/OrderComplete',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 10,
    module_name: 'Order Management',
    module_icon: 'fal fa-shopping-cart',
    module_order: 7
  },
  {
    method_id: 102,
    method_name: 'GetOrder',
    hash: '/Order/GetOrder',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 10,
    module_name: 'Order Management',
    module_icon: 'fal fa-shopping-cart',
    module_order: 7
  },
  {
    method_id: 103,
    method_name: 'GetOrders',
    hash: '/Order/GetOrders',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 10,
    module_name: 'Order Management',
    module_icon: 'fal fa-shopping-cart',
    module_order: 7
  },
  {
    method_id: 105,
    method_name: 'OrderDetails',
    hash: '/Shop/OrderDetails',
    method_icon: 'fal fa-circle',
    type: 3,
    method_order: 0,
    module_id: 10,
    module_name: 'Order Management',
    module_icon: 'fal fa-shopping-cart',
    module_order: 7
  },
  {
    method_id: 133,
    method_name: 'GetSpecialCode',
    hash: '/Order/GetSpecialCode',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 10,
    module_name: 'Order Management',
    module_icon: 'fal fa-shopping-cart',
    module_order: 7
  },
  {
    method_id: 134,
    method_name: 'GetDocTraCode',
    hash: '/Order/GetDocTraCode',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 10,
    module_name: 'Order Management',
    module_icon: 'fal fa-shopping-cart',
    module_order: 7
  },
  {
    method_id: 135,
    method_name: 'GetWhouseCode',
    hash: '/Order/GetWhouseCode',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 10,
    module_name: 'Order Management',
    module_icon: 'fal fa-shopping-cart',
    module_order: 7
  },
  {
    method_id: 136,
    method_name: 'Order Edit',
    hash: '/Order/Edit',
    method_icon: 'fal fa-circle',
    type: 3,
    method_order: 0,
    module_id: 10,
    module_name: 'Order Management',
    module_icon: 'fal fa-shopping-cart',
    module_order: 7
  },
  {
    method_id: 76,
    method_name: 'Shop',
    hash: '/Shop/Shop',
    method_icon: 'fal fa-circle',
    type: 1,
    method_order: 0,
    module_id: 10,
    module_name: 'Order Management',
    module_icon: 'fal fa-shopping-cart',
    module_order: 7
  },
  {
    method_id: 81,
    method_name: 'Product',
    hash: '/Shop/Product',
    method_icon: 'fal fa-circle',
    type: 3,
    method_order: 0,
    module_id: 10,
    module_name: 'Order Management',
    module_icon: 'fal fa-shopping-cart',
    module_order: 7
  },
  {
    method_id: 85,
    method_name: 'AddToCart',
    hash: '/Order/AddToCart',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 10,
    module_name: 'Order Management',
    module_icon: 'fal fa-shopping-cart',
    module_order: 7
  },
  {
    method_id: 86,
    method_name: 'DeleteToCart',
    hash: '/Order/DeleteToCart',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 10,
    module_name: 'Order Management',
    module_icon: 'fal fa-shopping-cart',
    module_order: 7
  },
  {
    method_id: 87,
    method_name: 'UpdateToCart',
    hash: '/Order/UpdateToCart',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 10,
    module_name: 'Order Management',
    module_icon: 'fal fa-shopping-cart',
    module_order: 7
  },
  {
    method_id: 88,
    method_name: 'GetUserCart',
    hash: '/Order/GetUserCart',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 10,
    module_name: 'Order Management',
    module_icon: 'fal fa-shopping-cart',
    module_order: 7
  },
  {
    method_id: 104,
    method_name: 'Orders',
    hash: '/Shop/Orders',
    method_icon: 'fal fa-circle',
    type: 1,
    method_order: 1,
    module_id: 10,
    module_name: 'Order Management',
    module_icon: 'fal fa-shopping-cart',
    module_order: 7
  },
  {
    method_id: 148,
    method_name: 'GetAllProductsP',
    hash: '/Product/GetAllProductsP',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 11,
    module_name: 'Product Management',
    module_icon: 'fal fa-box-full',
    module_order: 8
  },
  {
    method_id: 155,
    method_name: 'SaveProduct',
    hash: '/Product/SaveProduct',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 11,
    module_name: 'Product Management',
    module_icon: 'fal fa-box-full',
    module_order: 8
  },
  {
    method_id: 123,
    method_name: 'Product Edit',
    hash: '/Product/Edit',
    method_icon: 'fal fa-circle',
    type: 3,
    method_order: 0,
    module_id: 11,
    module_name: 'Product Management',
    module_icon: 'fal fa-box-full',
    module_order: 8
  },
  {
    method_id: 124,
    method_name: 'GetAllProducts',
    hash: '/Product/GetAllProducts',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 11,
    module_name: 'Product Management',
    module_icon: 'fal fa-box-full',
    module_order: 8
  },
  {
    method_id: 161,
    method_name: 'GetAllProductsG',
    hash: '/Product/GetAllProductsG',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 11,
    module_name: 'Product Management',
    module_icon: 'fal fa-box-full',
    module_order: 8
  },
  {
    method_id: 90,
    method_name: 'GetAllProductBrand',
    hash: '/Product/GetAllProductBrand',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 11,
    module_name: 'Product Management',
    module_icon: 'fal fa-box-full',
    module_order: 8
  },
  {
    method_id: 91,
    method_name: 'GetAllProductGroup',
    hash: '/Product/GetAllProductGroup',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 11,
    module_name: 'Product Management',
    module_icon: 'fal fa-box-full',
    module_order: 8
  },
  {
    method_id: 92,
    method_name: 'GetProductImage',
    hash: '/Product/GetProductImage',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 11,
    module_name: 'Product Management',
    module_icon: 'fal fa-box-full',
    module_order: 8
  },
  {
    method_id: 78,
    method_name: 'GetAllProduct',
    hash: '/Product/GetAllProduct',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 11,
    module_name: 'Product Management',
    module_icon: 'fal fa-box-full',
    module_order: 8
  },
  {
    method_id: 79,
    method_name: 'GetByIdProduct',
    hash: '/Product/GetByIdProduct',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 11,
    module_name: 'Product Management',
    module_icon: 'fal fa-box-full',
    module_order: 8
  },
  {
    method_id: 126,
    method_name: 'UpdateProduct',
    hash: '/Product/UpdateProduct',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 11,
    module_name: 'Product Management',
    module_icon: 'fal fa-box-full',
    module_order: 8
  },
  {
    method_id: 127,
    method_name: 'GetByIdProductEdit',
    hash: '/Product/GetByIdProductEdit',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 11,
    module_name: 'Product Management',
    module_icon: 'fal fa-box-full',
    module_order: 8
  },
  {
    method_id: 122,
    method_name: 'Products',
    hash: '/Product/List',
    method_icon: 'fal fa-circle',
    type: 1,
    method_order: 1,
    module_id: 11,
    module_name: 'Product Management',
    module_icon: 'fal fa-box-full',
    module_order: 8
  },
  {
    method_id: 147,
    method_name: 'Fast Edit',
    hash: '/Product/FastEdit',
    method_icon: 'fal fa-circle',
    type: 1,
    method_order: 2,
    module_id: 11,
    module_name: 'Product Management',
    module_icon: 'fal fa-box-full',
    module_order: 8
  },
  {
    method_id: 157,
    method_name: 'New Product',
    hash: '/Product/Create',
    method_icon: 'fal fa-circle',
    type: 1,
    method_order: 2,
    module_id: 11,
    module_name: 'Product Management',
    module_icon: 'fal fa-box-full',
    module_order: 8
  },
  {
    method_id: 164,
    method_name: 'SaveImage',
    hash: '/Image/SaveImage',
    method_icon: 'fal fa-circle',
    type: 2,
    method_order: 0,
    module_id: 16,
    module_name: 'Image Management',
    module_icon: 'fal fa-photo-video',
    module_order: 9
  },
  {
    method_id: 163,
    method_name: 'Images',
    hash: '/Image/Images',
    method_icon: 'fal fa-circle',
    type: 1,
    method_order: 1,
    module_id: 16,
    module_name: 'Image Management',
    module_icon: 'fal fa-photo-video',
    module_order: 9
  }
]

export default authKeys
