import axiosIns from '@/libs/axios'

import { SET_NOTIFICATION } from './module-service'

//Actions
export const GET_ALL_COUNTRIES = 'getAllCountries'
export const GET_ALL_CITIES = 'getAllCities'
export const GET_ALL_TOWNS = 'getAllTowns'
export const GET_ALL_ADDRESS_TYPES = 'getAllAddressTypes'
export const GET_ALL_USER_ADDRESS = 'getAllUserAddress'
export const GET_ADDRESS = 'getAddress'
export const CREATE_ADDRESS = 'createAddress'
export const UPDATE_ADDRESS = 'updateAddress'
export const DELETE_ADDRESS = 'deleteAddress'

//Mutations
export const SET_COUNTRIES = 'setCountries'
export const SET_CITIES = 'setCities'
export const SET_TOWNS = 'setTowns'
export const SET_ADDRESS_TYPES = 'setAddressTypes'
export const SET_USER_ADDRESS = 'settUserAddress'
export const PUSH_ADDRESS = 'pushAddress'
export const CHANGE_ADDRESS = 'changeAddress'

const state = {
  countries: [],
  cities: [],
  towns: [],
  addressTypes: [],
  userAddress: []
}

const getters = {
  countries: state => state.countries,
  cities: state => state.cities,
  towns: state => state.towns,
  addressTypes: state => state.addressTypes,
  userAddress: state => state.userAddress.sort((x, y) => x.company_address_id - y.company_address_id),
  userInvoiceAddress: state => state.userAddress.filter(x => x.address_type_id === 1),
  userShippingAddress: state => state.userAddress.filter(x => x.address_type_id !== 1)
}

const actions = {
  [GET_ALL_COUNTRIES]({ commit }) {
    return new Promise(resolve => {
      if (state.countries.length > 0) {
        resolve()
      } else {
        axiosIns
          .post('Address/GetAllCountry')
          .then(response => {
            if (response.data.status) {
              commit(SET_COUNTRIES, response.data.data)
              resolve()
            } else {
              commit(SET_NOTIFICATION, response.data)
            }
          })
          .catch(() => {
            commit(SET_NOTIFICATION)
          })
      }
    })
  },
  [GET_ALL_CITIES]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Address/GetAllCountryCities?country_id=${payload}`)
        .then(response => {
          if (response.data.status) {
            commit(SET_CITIES, response.data.data)
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_ALL_TOWNS]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Address/GetAllCityTowns?city_id=${payload}`)
        .then(response => {
          if (response.data.status) {
            commit(SET_TOWNS, response.data.data)
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_ALL_ADDRESS_TYPES]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post(`Address/GetAllAddressType`)
        .then(response => {
          if (response.data.status) {
            commit(SET_ADDRESS_TYPES, response.data.data)
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_ALL_USER_ADDRESS]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('Address/GetAllUserCompanyAddress')
        .then(response => {
          if (response.data.status) {
            commit(SET_USER_ADDRESS, response.data.data)
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_ADDRESS]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Address/GetByIdUserCompanyAddress?company_address_id=${payload}`)
        .then(response => {
          if (response.data.status) {
            resolve(response.data.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [CREATE_ADDRESS]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Address/AddCompanyAddress`, payload)
        .then(response => {
          commit(PUSH_ADDRESS, response.data.data)
          commit(SET_NOTIFICATION, response.data)
          resolve(response.data.data.company_address_id)
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [UPDATE_ADDRESS]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`Address/UpdateCompanyAddress`, payload)
        .then(response => {
          commit(CHANGE_ADDRESS, response.data.data)
          commit(SET_NOTIFICATION, response.data)
          resolve(response.data.data.company_address_id)
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [DELETE_ADDRESS]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(``)
        .then(response => {
          commit(SET_NOTIFICATION, response.data)
          resolve()
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  }
}

const mutations = {
  [SET_COUNTRIES](state, payload) {
    state.countries = []
    payload.forEach(item => {
      state.countries.push({
        id: item.country_id,
        label: item.country_name
      })
    })
  },
  [SET_CITIES](state, payload) {
    state.cities = []
    payload.forEach(item => {
      state.cities.push({
        id: item.city_id,
        label: item.city_name
      })
    })
  },
  [SET_TOWNS](state, payload) {
    state.towns = []
    payload.forEach(item => {
      state.towns.push({
        id: item.town_id,
        label: item.town_name
      })
    })
  },
  [SET_ADDRESS_TYPES](state, payload) {
    payload.forEach(item => {
      state.addressTypes.push({
        id: item.address_type_id,
        label: item.address_type
      })
    })
  },
  [SET_USER_ADDRESS](state, payload) {
    if (payload) {
      state.userAddress = payload
    }
  },
  [PUSH_ADDRESS](state, payload) {
    state.userAddress.push(payload)
  },
  [CHANGE_ADDRESS](state, payload) {
    let index = state.userAddress.findIndex(x => x.company_address_id === payload.company_address_id)
    state.userAddress.splice(index, 1)
    state.userAddress.push(payload)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
