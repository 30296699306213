import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import authService from './services/auth-service'
import moduleService from './services/module-service'
import reportService from './services/report-service'
import dashboardCrud from './services/dashboard-crud'
import dashboardService from './services/dashboard-service'
import userService from './services/user-service'
import dataBaseService from './services/database-service'
import reportCrud from './services/report-crud'
import processFilter from './services/process-filter'
import reportFilters from './services/report-filters'
import shopService from './services/shop-service'
import breadcrumbService from './services/breadcrumb-service'
import paymentService from './services/payment-service'
import companyService from './services/company-service'
import addressService from './services/address-service'
import checkoutService from './services/checkout-service'
import priceListService from './services/priceList-service'
import productService from './services/product-service'
import currencyService from './services/currency-service'
import homePageService from './services/homePage-service'
import orderService from './services/order-service'
import paymentLinkService from './services/paymentLink-service'
import ERPService from './services/erp-service'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    authService,
    moduleService,
    reportService,
    dashboardCrud,
    dashboardService,
    userService,
    dataBaseService,
    reportCrud,
    processFilter,
    reportFilters,
    shopService,
    breadcrumbService,
    paymentService,
    companyService,
    addressService,
    checkoutService,
    priceListService,
    productService,
    currencyService,
    homePageService,
    orderService,
    paymentLinkService,
    ERPService
  }
})
