//import moment from 'moment'
import { convertNumbertoString, convertTL } from '@/assets/js/functions/currency-functions'
import axiosIns from '@/libs/axios'
//import i18n from '@/libs/i18n'

import { SET_NOTIFICATION } from './module-service'

//moment().locale()

//Actions
export const GET_ALL_PRODUCTS = 'getAllProducts'
export const GET_PRODUCT = 'getProduct'
export const GET_ALL_PRODUCT_GROUP = 'getAllProductGroup'
export const GET_ALL_BRAND = 'getAllBrand'
export const CHANGE_SHOP_PAGE_EVENT = 'changeShopPageEvent'

//Mutations
export const SET_DEFAULT_SHOP = 'setDefaultShop'
export const SET_ALL_PRODUCTS = 'setAllProducts'
export const SET_ALL_PRODUCT_GROUP = 'setAllProductGroup'
export const SET_ALL_BRAND = 'setAllBrand'
export const SELECT_CATEGORY = 'selectCategory'
export const SELECT_BRAND = 'selectBrand'
export const TYPE_SEARCH = 'typeSearch'
export const TYPE_BRAND_SEARCH = 'typeBrandSearch'
export const TYPE_CATEGORY_SEARCH = 'typeCategorySearch'
export const CHANGE_SORT_TYPE = 'changeSortType'
export const CHANGE_SHOP_PAGE = 'changeShopPage'
export const CLEAR_SHOP_FILTERS = 'clearShopFilters'
export const PUSH_PRODUCT_IMAGE = 'pushProductImage'
export const SET_ALL_CURRENCY = 'setAllCurrency'
export const SET_CART_BUTTON_STATE = 'setCartButtonState'
export const CHANGE_PER_PAGE = 'changePerPage'
export const SET_ALL_PRODUCT_FOR_FAST_EDIT = 'setAllProductForFastEdit'

const state = {
  allProducts: [],
  images: [],
  categories: [],
  brands: [],
  sortTypes: [
    { text: 'Featured', value: 'shop.featured' },
    { text: 'Lowest', value: 'shop.price_asc' },
    { text: 'Highest', value: 'shop.price_desc' }
  ],
  filters: {
    search: '',
    category: [],
    categorySearch: '',
    brand: [],
    brandSearch: '',
    sortType: { text: 'Featured', value: 'shop.featured' },
    page: 1,
    perPage: 24,
    totalCount: 0
  },
  other_filters: {
    show_in_stock: false,
    show_new: false,
    show_discounted: false,
    show_active: true,
    show_passive: false
  }
}

const getters = {
  rawAllProducts: state => state.allProducts,
  filteredProducts: (state, rootGetters) => {
    let active = []
    let passive = []
    //Aktif Ürünler
    if (state.other_filters.show_active) {
      active = state.allProducts.filter(x => !x.is_passive)
    }
    //Pasif Ürünler
    if (state.other_filters.show_passive) {
      passive = state.allProducts.filter(x => x.is_passive)
    }

    let temp = [...active, ...passive]
    //Sadece Stoktaki Ürünler
    if (state.other_filters.show_in_stock) {
      temp = temp.filter(x => x.in_stock === 1)
    }
    //Sadece Yeni Ürünler
    if (state.other_filters.show_new) {
      temp = temp.filter(x => x.is_new_product === 1)
    }
    //Sadece İndirimli Ürünler
    if (state.other_filters.show_discounted) {
      temp = temp.filter(x => x.is_discount === 1)
    }
    //Eğer Ürün Grubu Seçilmişse
    if (state.filters.category.length > 0) {
      temp = temp.filter(x => state.filters.category.includes(x.product_group_id))
    }
    //Eğer Marka Seçilmişse
    if (state.filters.brand.length > 0) {
      temp = temp.filter(x => state.filters.brand.includes(x.brand_id))
    }
    //Eğer Arama Yapılmışsa
    if (state.filters.search !== '') {
      let search = state.filters.search.toLowerCase()
      temp = temp.filter(
        x =>
          x.brand_name?.toLowerCase().includes(search) ||
          x.product_name?.toLowerCase().includes(search) ||
          x.barcode?.toLowerCase().includes(search) ||
          x.orginal_code?.toLowerCase().includes(search) ||
          (x.product_description != null && x.product_description?.toLowerCase().includes(search)) ||
          x.product_code?.toLowerCase().includes(search)
      )
    }
    //Sıralama Tipine Göre
    switch (state.filters.sortType.value) {
      case 'shop.price_asc':
        let dont_price_asc = temp.filter(x => x.unit_price_tra <= 0)
        let have_price_asc = temp.filter(x => x.unit_price_tra > 0)
        have_price_asc = have_price_asc.sort((x, y) => convertTL(x.unit_price_tra, x.currency, rootGetters.currency) - convertTL(y.unit_price_tra, y.currency, rootGetters.currency))
        temp = have_price_asc.concat(dont_price_asc)
        break
      case 'shop.price_desc':
        let dont_price_desc = temp.filter(x => x.unit_price_tra <= 0)
        let have_price_desc = temp.filter(x => x.unit_price_tra > 0)
        have_price_desc = have_price_desc.sort((x, y) => convertTL(y.unit_price_tra, y.currency, rootGetters.currency) - convertTL(x.unit_price_tra, x.currency, rootGetters.currency))
        temp = have_price_desc.concat(dont_price_desc)
        break
      case 'shop.active':
        temp = temp.sort((x, y) => {
          let xB = x.is_passive ? 1 : 0
          let yB = y.is_passive ? 1 : 0
          return xB - yB
        })
        break
      case 'shop.passive':
        temp = temp.sort((x, y) => {
          let xB = x.is_passive ? 1 : 0
          let yB = y.is_passive ? 1 : 0
          return yB - xB
        })
        break
      default:
        temp = temp.sort((x, y) => {
          let xO = (+x.order_no ?? 0) * (+x.product_group_id ?? 0)
          let yO = (+y.order_no ?? 0) * (+y.product_group_id ?? 0)
          return xO > yO ? 1 : xO < yO ? -1 : 0
        })
        break
    }
    state.filters.totalCount = temp.length
    temp = temp.slice((state.filters.page - 1) * state.filters.perPage, state.filters.page * state.filters.perPage)
    temp.forEach(item => {
      item.quantity = '1'
      item.unit_price_tra_str = new Intl.NumberFormat(rootGetters.getLocale, {
        maximumFractionDigits: 2
      }).format(item.unit_price_tra)
    })
    //console.log('TÜM ÜRÜNLER',state.allProducts)
    //console.log('FİLTRELER',{ ...state.filters })
    //console.log('FİLTRELENMİŞ ÜRÜNLER',[...temp])
    return temp
  },
  filteredProductsCount: state => state.filters.totalCount,
  shopSearch: state => state.filters.search,
  selectedCategory: state => state.filters.category,
  categorySearch: state => state.filters.categorySearch,
  selectedBrand: state => state.filters.brand,
  brandSearch: state => state.filters.brandSearch,
  shopCategories: (state, rootGetters) => {
    if (state.filters.categorySearch !== '') {
      let temp = state.filters.categorySearch.toLocaleLowerCase(rootGetters.getLocale)

      state.categories.forEach(item => {
        item.show = false
        item.open = false
        item.childrens.forEach(child => {
          child.show = false
        })
      })

      state.categories
        .filter(x => x.childrens.findIndex(y => y.text.toLocaleLowerCase(rootGetters.getLocale).includes(temp)) !== -1)
        .forEach(item => {
          item.show = true
          item.open = true
          item.showedChild = 0
          item.childrens
            .filter(x => x.text.toLocaleLowerCase(rootGetters.getLocale).includes(temp))
            .forEach(child => {
              child.show = true
              item.showedChild++
            })
        })
    } else {
      state.categories.forEach(item => {
        item.show = true
        item.open = false
        item.showedChild = 0
        item.childrens.forEach(child => {
          child.show = true
          item.showedChild++
        })
      })
    }

    return state.categories
  },
  shopBrands: (state, rootGetters) => {
    state.brands = state.brands.sort((x, y) => x.text.localeCompare(y.text, rootGetters.getLocale))

    if (state.filters.brandSearch !== '') {
      let temp = state.filters.brandSearch.toLocaleLowerCase(rootGetters.getLocale)
      state.brands.forEach(item => {
        item.show = item.text.toLocaleLowerCase(rootGetters.getLocale).includes(temp)
      })
    } else {
      state.brands.forEach(item => {
        item.show = true
      })
    }

    let filteredBrands = []
    let temp = state.allProducts
    if (state.filters.category.length > 0) {
      temp = temp.filter(x => state.filters.category.includes(x.product_group_id))
    }
    temp.forEach(item => {
      if (!filteredBrands.includes(item.brand_id)) {
        filteredBrands.push(item.brand_id)
      }
    })

    state.brands.forEach(item => {
      if (!filteredBrands.includes(item.value)) {
        item.show = false
      }
    })
    return state.brands
  },
  shopSortTypes: state => state.sortTypes,
  selectedSortType: state => state.filters.sortType,
  shopPage: state => state.filters.page,
  shopPerPage: state => state.filters.perPage,
  productImages: state => state.images,
  shopFilters: state => state.filters,
  shopOtherFilters: state => state.other_filters
}

const actions = {
  [GET_ALL_PRODUCTS]({ commit }) {
    return new Promise(resolve => {
      commit(SET_DEFAULT_SHOP)
      axiosIns
        .post('Product/GetAllProduct')
        .then(response => {
          if (response.data.status) {
            commit(SET_ALL_PRODUCTS, response.data.data)
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_PRODUCT]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosIns
        .post(`Product/GetByIdProduct?product_id=${payload}`)
        .then(response => {
          if (response.data.status) {
            resolve(response.data.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
            reject()
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_ALL_PRODUCT_GROUP]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('Product/GetAllProductGroup')
        .then(response => {
          if (response.data.status) {
            commit(SET_ALL_PRODUCT_GROUP, response.data.data)
            resolve(state.categories)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_ALL_BRAND]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('Product/GetAllProductBrand')
        .then(response => {
          if (response.data.status) {
            commit(SET_ALL_BRAND, response.data.data)
            resolve()
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },

  [CHANGE_SHOP_PAGE_EVENT]({ dispatch, commit, state, getters }, payload) {
    return new Promise(resolve => {
      commit(CHANGE_SHOP_PAGE, payload)
      resolve()
    })
  }
}

const mutations = {
  [SET_DEFAULT_SHOP](state) {
    state.allProducts = []
    state.brands = []
    state.categories = []
    state.filters = {
      search: '',
      category: [],
      categorySearch: '',
      brand: [],
      brandSearch: '',
      sortType: { text: 'Featured', value: 'shop.featured' },
      page: 1,
      perPage: 24,
      totalCount: 0
    }
    state.other_filters = {
      show_in_stock: false,
      show_new: false,
      show_discounted: false,
      show_active: true,
      show_passive: false
    }
  },
  [SET_ALL_PRODUCTS](state, payload) {
    payload?.forEach(item => {
      let temp = item.compatible_brands?.split(',')
      let compatible_brands = []
      temp?.forEach(i => {
        i?.split('-')?.forEach(j => {
          compatible_brands.push(j)
        })
      })
      item.compatible_brands = compatible_brands
      item.cartButtonState = false
    })
    state.allProducts = payload
  },
  [SET_ALL_PRODUCT_GROUP](state, payload) {
    state.categories = []
    payload.forEach(item => {
      let check = state.categories.findIndex(x => x.id === item.parent_group_id)
      if (check === -1) {
        state.categories.push({
          id: item.parent_group_id,
          name: item.parent_product_group_name,
          childrens: [
            {
              text: item.product_group_name,
              value: item.product_group_id
            }
          ]
        })
      } else {
        state.categories[check].childrens.push({
          text: item.product_group_name,
          value: item.product_group_id
        })
      }
    })
  },
  [SET_ALL_BRAND](state, payload) {
    state.brands = []
    payload.forEach(item => {
      state.brands.push({
        text: item.brand_name,
        value: item.brand_id
      })
    })
  },
  [SELECT_CATEGORY](state, payload) {
    state.filters.category = payload
    state.filters.brand = []
  },
  [SELECT_BRAND](state, payload) {
    state.filters.brand = payload
  },
  [TYPE_SEARCH](state, payload) {
    state.filters.search = payload
  },
  [CHANGE_SORT_TYPE](state, payload) {
    state.filters.sortType = payload
  },
  [CHANGE_SHOP_PAGE](state, payload) {
    state.filters.page = payload
  },
  [TYPE_BRAND_SEARCH](state, payload) {
    state.filters.brandSearch = payload
  },
  [TYPE_CATEGORY_SEARCH](state, payload) {
    state.filters.categorySearch = payload
  },
  [CLEAR_SHOP_FILTERS](state) {
    state.filters.page = 1
    state.filters.category = []
    state.filters.brand = []

    state.brands.forEach(item => {
      item.show = true
    })
  },
  [PUSH_PRODUCT_IMAGE](state, payload) {
    if (payload.images !== null && payload.images !== undefined) {
      let images = JSON.parse(payload.images)
      if (Array.isArray(images)) {
        state.images.push({
          id: payload.product_id,
          images
        })
      } else {
        state.images.push({
          id: payload.product_id,
          images: []
        })
      }
    } else {
      state.images.push({
        id: payload.product_id,
        images: []
      })
    }
  },

  [SET_CART_BUTTON_STATE](state, payload) {
    let temp = state.allProducts.find(x => x.product_id === payload.product_id)
    if (temp) {
      temp.cartButtonState = payload.state
    }
  },

  [CHANGE_PER_PAGE](state, payload) {
    state.filters.perPage = payload
  },

  [SET_ALL_PRODUCT_FOR_FAST_EDIT](state, payload) {
    state.sortTypes = [
      { text: 'Featured', value: 'shop.featured' },
      { text: 'Lowest', value: 'shop.price_asc' },
      { text: 'Highest', value: 'shop.price_desc' },
      { text: 'Active', value: 'shop.active' },
      { text: 'Passive', value: 'shop.passive' }
    ]
    payload.forEach(item => {
      item.in_stock = 1
      item.temp_is_passive = item.is_passive
      item.unit_price_tra_str = convertNumbertoString(item.unit_price_tra, 0, 2)
    })
    state.allProducts = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
