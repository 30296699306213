import axiosIns from '@/libs/axios'
import { SET_NOTIFICATION } from './module-service'

// import moment from 'moment'
// moment().locale()

export const GET_ALL_DATABASE = 'getAllDatabase'
export const GET_ALL_TABLE_NAMES = 'getAllTableNames'

export const SET_ALL_TABLE_NAMES = 'setAllTableNames'

const state = {}

const getters = {}

const actions = {
  [GET_ALL_DATABASE]({ commit }) {
    return new Promise(resolve => {
      axiosIns
        .post('DataBase/GetAllDataBase')
        .then(response => {
          if (response.data.status) {
            resolve(response.data.data)
          } else {
            commit(SET_NOTIFICATION, response.data)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  },
  [GET_ALL_TABLE_NAMES]({ commit }, payload) {
    return new Promise(resolve => {
      axiosIns
        .post(`DataBase/GetAllDataBaseTables?database_id=${payload}`)
        .then(response => {
          if (response.data.data) {
            commit(SET_ALL_TABLE_NAMES, response.data.data)
            resolve(response.data.data.tables)
          }
        })
        .catch(() => {
          commit(SET_NOTIFICATION)
        })
    })
  }
}

const mutations = {
  [SET_ALL_TABLE_NAMES](state, response) {
    const tables = []
    response.forEach((table, index) => {
      const check = tables.findIndex(x => x.label === table.TABLE_NAME)
      if (check === -1) {
        tables.push({ id: index, label: table.TABLE_NAME, showColumn: false, children: [{ id: `${index}_`, label: table.COLUMN_NAME }] })
      } else {
        tables[check].children.push({ id: index, label: table.COLUMN_NAME })
      }
    })
    response.tables = tables
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
