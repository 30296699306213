export function replaceSQLVariable(filter)
{
  let valUse = "",
    tempVal;
  let condition = (filter.value != null && filter.value != "")
  if (filter.type != null)
  {
    if (filter.SQLReplaceType != null && filter.SQLReplaceType == 'quote')
    {
      valUse = "'";
    }
    if (filter.type == 0)
    {
      if (filter.option == "text" && condition)
      {
        tempVal = "'" + filter.value + "'";
      } else
      {
        if (condition)
        {
          tempVal = filter.value;
        }
        else
        {
          tempVal = "NULL";
        }
      }
    }
    else if (filter.type == 1)
    {
      if (filter.option == "true")
      {//Multi Select İse
        tempVal = "(";
        if (filter.value)
        {
          if (filter.value.length > 0)
          {
            filter.value.forEach((item,index) =>
            {
              let temp = (item != null && item != "") ? item : "NULL"
              if (item == "NULL")
              {
                tempVal += "NULL"
              }
              else
              {
                tempVal += valUse + temp + valUse
              }
              if (index != filter.value.length - 1)
                tempVal += ","
            })
          } else
          {
            tempVal += "NULL";
          }
        } else
        {
          tempVal += "NULL";
        }
        tempVal += ")";
      } else
      {
        if (filter.value != null && filter.value != "")
        {
          tempVal = valUse + filter.value + valUse;
        }
        else
        {
          tempVal = "NULL"
        }
      }
    }

    else
    {
      if (filter.option == "range")
      {
        tempVal = "";
        if (filter.value != null && filter.value != "")
        {
          let tempDates = filter.value.split("to");
          if (tempDates.length == 2)
          {
            tempVal = "BETWEEN ";
            tempDates.forEach((date,index) =>
            {
              tempDates[index] = "'" + date.trim() + "'";
            });
            tempVal += tempDates.join(" AND ");
          }
          else
          {
            tempVal = "NULL"
          }
        }
        else
        {
          tempVal = "NULL"
        }
      } else if (filter.option == "multiple")
      {
        if (filter.value != null && filter.value != "")
        {
          let tempDates = filter.value.split(",");
          tempVal = "(";
          tempDates.forEach((date,index) =>
          {
            tempVal += "'" + date.trim() + "'";
            if (index != tempDates.length - 1)
            {
              tempVal += ",";
            }
          });
          tempVal += ")";
        }
        else
        {
          tempVal = "(NULL)"
        }
      } else
      {
        if (condition)
        {
          tempVal = "'" + filter.value + "'";
        }
        else
        {
          tempVal = "NULL"
        }
      }
    }
  }
  return tempVal;
}