export default [
  {
    path: "/Report/MyList",
    name: "/Report/MyList",
    component: () => import("@/views/Report/MyList.vue"),
    meta: {
      title: "My Reports",
      pageTitle: "Report Management",
      breadcrumb: [
        {
          text: "My Reports",
          active: true,
        },
      ],
      needRegister: true,
      action: "/Report/MyList",
    },
  },
  {
    path: "/Report/List",
    name: "/Report/List",
    component: () => import("@/views/Report/List.vue"),
    meta: {
      title: "Reports",
      pageTitle: "Report Management",
      breadcrumb: [
        {
          text: "Reports",
          active: true,
        },
      ],
      needRegister: true,
      action: "/Report/List",
    },
  },
  {
    path: "/Report/Create",
    name: "/Report/Create",
    component: () => import("@/views/Report/Create.vue"),
    meta: {
      title: "New Report",
      pageTitle: "Report Management",
      breadcrumb: [
        {
          text: "Reports",
          to: { name: "/Report/List" },
        },
        {
          text: "New Report",
          active: true,
        },
      ],
      needRegister: true,
      action: "/Report/Create",
    },
  },
  {
    path: "/Report/Edit/:id",
    name: "/Report/Edit",
    component: () => import("@/views/Report/Edit.vue"),
    meta: {
      title: "EditReport",
      pageTitle: "Report Management",
      breadcrumb: [
        {
          text: "Reports",
          to: { name: "/Report/List" },
        },
        {
          text: "EditReport",
          active: true,
        },
      ],
      needRegister: true,
      action: "/Report/Edit",
    },
  },
  {
    path: "/Report/Display/:id",
    name: "/Report/Display",
    component: () => import("@/views/Report/Display.vue"),
    meta: {
      title: "Report Display",
      pageTitle: "Report Management",
      breadcrumb: [
        {
          text: "Reports",
          to: { name: "/Report/List" },
        },
        {
          text: "Display",
          active: true,
        },
      ],
      needRegister: true,
      action: "/Report/Display",
    },
  },
];
